
import moment from 'moment'
import mongoose from 'mongoose'
import { defaultValue, recurrenceEndType, recurrenceType, weekIndexKey, dateTimeFormat } from '../constant' //dayOfWeek, monthOfYear,
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const EventRecurrenceSchema = new Schema({
	type: { type: String, default: defaultValue.PLEASE_SELECT },
	startDate: { type: Date, default: moment() },

	endType: { type: String, default: recurrenceEndType.END_DATE },
	endDate: { type: Date, default: moment().add(1, "d") },
	numbered: { type: Number, default: "" },

	every: { type: Number, default: "" }, // !== absoluteYearly
	weekIndex: { type: String, default: weekIndexKey.FIRST }, // === relativeMonthly
	dayOnWeek: { type: String, default: moment().format("dddd").toLowerCase() }, // === weekly, relativeMonthly
	month: { type: String, default: moment().format("MMMM").toLowerCase() }, // === absoluteYearly
	day: { type: Number, default: "" }, // === absoluteMonthly, absoluteYearly
}, { _id: false })

setBaseSchema(EventRecurrenceSchema, "event_recurrence")

EventRecurrenceSchema.path("type").validate(async function (type) {
	return !!type && type !== defaultValue.PLEASE_SELECT
}, "validate.require")

EventRecurrenceSchema.path("numbered").validate(async function (numbered) {
	if (this.endType === recurrenceEndType.NUMBERED) {
		return !isNaN(numbered) && numbered > 0
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("every").validate(async function (every) {
	if (!!this.type && this.type !== defaultValue.PLEASE_SELECT && this.type !== recurrenceType.ABSOLUTE_YEARLY) {
		return !isNaN(every) && every > 0
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("weekIndex").validate(async function (weekIndex) {
	if (this.type === recurrenceType.RELATIVE_MONTHLY) {
		return !!weekIndex
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("dayOnWeek").validate(async function (dayOnWeek) {
	if (this.type === recurrenceType.WEEKLY || this.type === recurrenceType.RELATIVE_MONTHLY) {
		return !!dayOnWeek
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("month").validate(async function (month) {
	if (this.type === recurrenceType.ABSOLUTE_YEARLY) {
		return !!month
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("day").validate(async function (day) {
	if (this.type === recurrenceType.ABSOLUTE_MONTHLY || this.type === recurrenceType.ABSOLUTE_YEARLY) {
		return !isNaN(day) && day > 0
	}
	return true
}, "validate.require")

EventRecurrenceSchema.path("day").validate(async function (day) {
	if (this.type === recurrenceType.ABSOLUTE_MONTHLY || this.type === recurrenceType.ABSOLUTE_YEARLY) {
		return day > 31
	}
	return true
}, "validate.dayInvalid")


EventRecurrenceSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			let range = data.range
				, pattern = data.pattern

			if (!!range) {
				this.endType = range.type
				this.startDate = moment(range.startDate, dateTimeFormat.apiDate)
				if (!!range.endDate) {
					this.endDate = moment(range.endDate, dateTimeFormat.apiDate)
				}
				if (!isNaN(range.numberOfOccurrences) && range.numberOfOccurrences > 0) {
					this.numbered = range.numberOfOccurrences
				}
			}

			if (!!pattern) {
				this.type = pattern.type
				if (!isNaN(pattern.interval) && pattern.interval > 0) {
					this.every = pattern.interval
				}
				if (Array.isArray(pattern.daysOfWeek) && pattern.daysOfWeek.length > 0) {
					this.dayOnWeek = pattern.daysOfWeek[0]
				}
				// this.firstDayOfWeek = 
				if (!isNaN(pattern.dayOfMonth) && pattern.dayOfMonth > 0) {
					this.day = pattern.dayOfMonth
				}
				this.weekIndex = pattern.index
				if (!isNaN(pattern.month) && pattern.month > 0) {
					this.month = pattern.month
				}
			}
		}
	} catch (error) {

	}
}

EventRecurrenceSchema.methods.serializeAPI = function (user) {
	try {
		if (!user) {
			throw new Error("User not found.")
		}

		let range = {
			type: this.endType,
			recurrenceTimeZone: user.timeZone,
			startDate: moment(this.startDate).format(dateTimeFormat.apiDate),
		}
		if (this.endType === recurrenceEndType.END_DATE) {
			range.endDate = moment(this.endDate).format(dateTimeFormat.apiDate)
		} else if (this.endType === recurrenceEndType.NUMBERED) {
			range.numberOfOccurrences = this.numbered
		}


		let pattern = {
			type: this.type,
		}
		if (this.type === recurrenceType.DAILY) {
			pattern.interval = this.every
		} else if (this.type === recurrenceType.WEEKLY) {
			pattern.interval = this.every
			pattern.daysOfWeek = [this.dayOnWeek]
			pattern.firstDayOfWeek = moment(this.startDate).startOf("week").format("dddd").toLowerCase()
		} else if (this.type === recurrenceType.ABSOLUTE_MONTHLY) {
			pattern.interval = this.every
			pattern.dayOfMonth = this.day
		} else if (this.type === recurrenceType.RELATIVE_MONTHLY) {
			pattern.interval = this.every
			pattern.daysOfWeek = [this.dayOnWeek]
			pattern.index = this.weekIndex
		} else if (this.type === recurrenceType.ABSOLUTE_YEARLY) {
			pattern.interval = this.every
			pattern.dayOfMonth = this.day
			pattern.month = parseInt(moment(this.month, "MMMM").format("MM"), 10)
		}

		let result = {
			range: range,
			pattern: pattern,
		}
		return result
	} catch (error) {
		console.error(error)
		return null
	}
}


const EventRecurrenceModel = mongoose.model("event_recurrence", EventRecurrenceSchema, "event_recurrence")

export default EventRecurrenceModel