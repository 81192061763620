
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewScanQRCode from '../../views/global/ViewScanQRCode'

class ScanQRCodeController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
	}
	render() {
		const { isLoading } = this.state
		return (
			<React.Fragment>
				{super.render()}
				{isLoading && this.getLoadingPage()}
				<ViewScanQRCode
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(ScanQRCodeController)