
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ModalModel from '../../core/model/ModalModel'
import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import { cookiesKey, systemKey } from '../../constant'
import UserModel from '../../models/UserModel'
import FrontendIntegrationSettingModel from '../../models/FrontendIntegrationSettingModel'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import config from '../../config'
import { afterIntegration } from '../../business/AuthVerifyManager'
// import { CORE_API_ENDPOINT } from '../../lib'

class ViewUserIntegration extends MyPage {
    constructor(props) {
        super(props)
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false

        this.state.model = null
        this.state.setting = null

        this.state.modalCustom = null

        this.state.integrationCode = this.getQueryFromProps("code")
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { integrationCode } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                model: null,
            },
                action = () => {

                }
            let [resUser, resSetting] = await Promise.all([
                this.props.onGetUserIntegrationInfo(),
                this.props.onGetSetting(),
            ])
            if (!!resUser && resUser.result === true && resUser.data instanceof UserModel) {
                tempState.model = resUser.data
            }
            if (!!resSetting && resSetting.result === true && resSetting.data instanceof FrontendIntegrationSettingModel) {
                tempState.setting = resSetting.data
                let system = systemKey[resSetting.data.system.toUpperCase()]
                if (!!integrationCode && !!system) {
                    tempState.isLoading = true
                    action = () => {
                        this.props.history.replace(`/integration`)
                        this.onIntegration(system, integrationCode)
                    }
                }
            }
            this.setState(tempState, action)
        })
    }

    onIntegration = (key, data) => {
        if (key === systemKey.O365) {
            this.onIntegrationO365(data)
        } else if (key === systemKey.GOOGLE) {
            this.onIntegrationGoogle(data)
        }
    }

    onIntegrationO365 = (code) => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                integrationCode: ""
            },
                action = () => { }
            let res = await this.props.onIntegrationO365({ code: code })
            if (!!res && res.result === true && !!res.data) {
                let isRemember = false
                    , tempIsRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
                if (typeof tempIsRemember === "boolean") {
                    isRemember = tempIsRemember
                }
                afterIntegration(res.data, isRemember)
                action = () => {
                    this.getData()
                }
            }
            this.setState(tempState, action)
        })
    }

    onIntegrationGoogle = (code) => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                integrationCode: ""
            },
                action = () => { }
            let res = await this.props.onIntegrationO365({ code: code })
            if (!!res && res.result === true && !!res.data) {
                let isRemember = false
                    , tempIsRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
                if (typeof tempIsRemember === "boolean") {
                    isRemember = tempIsRemember
                }
                afterIntegration(res.data, isRemember)
                action = () => {
                    this.getData()
                }
            }
            this.setState(tempState, action)
        })
    }

    onConfirmUnintegration = (e, system) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmUnintegration"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onUnintegration(system)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onUnintegration = (system) => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.getData()
                }

            let res = await this.props.onUnintegration(system.toLowerCase())
            if (!!res && res.result === true && res.data === true) {
                this.bCookie.setData(cookiesKey.IS_INTEGRATION, false)
            }
            this.setState(tempState, action)
        })
    }

    onClickIntegration = (e, system, setting) => {
        if (!!setting) {
            if (system === systemKey.O365) {
                this.onClickIntegrationO365(setting)
            } else if (system === systemKey.EXCHANGE2010 || system === systemKey.EXCHANGE2013) {
                this.props.history.push("/user/integration/exchange")
            } else if (system === systemKey.GOOGLE) {
                this.onClickIntegrationGoogle(setting)
            }
        }
    }

    onClickIntegrationO365 = (setting) => {
        let redirect_uri = ""
            , client_id = ""
        if (!!setting.o365Data) {
            client_id = setting.o365Data.client_id
            if (!!setting.o365Data.web) {
                redirect_uri = setting.o365Data.web.integration
                // redirect_uri = "http://localhost:4000/integration"
            }
        }
        if (!!redirect_uri && !!client_id) {
            let authUrl = `https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&redirect_uri=${redirect_uri}&client_id=${client_id}`
            window.location.replace(authUrl)
        }
    }

    onClickIntegrationGoogle = (setting) => {
        let authUrl = ""
        if (!!setting.googleData && !!setting.googleData.web) {
            authUrl = setting.googleData.web.frontendIntegrationRedirectUrl
        }
        if (!!authUrl) {
            window.location.replace(authUrl)
        }
    }

    render() {
        const { t } = this.props
        const { model, setting, isLoading, modalCustom } = this.state

        let system = ""
            , userIntegration = null
            , isSystemIntegration = false
        if (!!setting) {
            if (!!setting.system && !!systemKey[setting.system]) {
                system = setting.system
            }
            isSystemIntegration = setting.isIntegration
        }
        if (!!system && !!model) {
            userIntegration = model.token.find(t => t.service === system)
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {modalCustom && this.getModalContent(modalCustom)}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewUserIntegration">
                        <Container text>
                            <div className="view-user-integration-header">
                                <h1>{t("user.userIntegration")}</h1>
                            </div>
                            {
                                !!system &&
                                <div className="view-user-integration-body">
                                    <div className="integration-wrapper">
                                        <div className="integration-header">
                                            <p>{t(`common.mailSystem.${system}`)}</p>
                                        </div>
                                        <div className="integration-body">
                                            <div className="ib-text">
                                                {
                                                    (!!model && model.isIntegration === true) ?
                                                        <BoxIconImageWithText
                                                            imageUrl={`${config.publicUrl}/assets/images/icon-email.png`}
                                                            text={!!userIntegration ? userIntegration.email : ""}
                                                        />
                                                        : <p className="no-integration">{t("user.noIntegration")}</p>
                                                }
                                            </div>
                                            <div className="ib-action">
                                                {
                                                    !!model &&
                                                    <React.Fragment>
                                                        {
                                                            model.isIntegration === true &&
                                                            <ViewButton
                                                                model={new ButtonModel({
                                                                    text: t("user.unintegration"),
                                                                    color: "red",
                                                                    onClick: (e) => this.onConfirmUnintegration(e, system)
                                                                })}
                                                            />
                                                        }
                                                        {
                                                            model.isIntegration === false &&
                                                            <ViewButton
                                                                model={new ButtonModel({
                                                                    text: t("user.integration"),
                                                                    color: "blue",
                                                                    disabled: !isSystemIntegration,
                                                                    onClick: (e) => this.onClickIntegration(e, system, setting)
                                                                })}
                                                            />
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* {
                                (!!model && !!setting) &&
                                < div className="view-user-integration-body">

                                </div>
                            } */}
                        </Container>
                    </div>
                </LayoutWithHeaderFooter >
            </React.Fragment >
        )
    }
}

ViewUserIntegration.defaultProps = {
    onGetUserIntegrationInfo: () => {
        console.error("Please provide Get User action.")
        return null
    },
    onGetSetting: () => {
        console.error("Please provide Get Setting action.")
        return null
    },
    onUnintegration: () => {
        console.error("Please provide Unintegration action.")
        return null
    },
    onIntegrationO365: () => {
        console.error("Please provide Integration O365 action.")
        return null
    },
    onIntegrationGoogle: () => {
        console.error("Please provide Integration Google action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewUserIntegration)