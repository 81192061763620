
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewChangePassword from '../../views/user/ViewChangePassword'
import UserManager from '../../business/UserManager'

class ChangePasswordController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
	}

	onChangePassword = (userId, body) => {
		return this.bUser.changePassword(userId, body)
	}

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewChangePassword
					onChangePassword={this.onChangePassword}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(ChangePasswordController)