
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewHome from '../../views/home/ViewHome'
import SettingManager from '../../business/SettingManager'
import RoomManager from '../../business/RoomManager'

class HomeController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
		this.bRoom = new RoomManager(this)
	}

	onGetSetting = () => {
		return this.bSetting.getFrontendSetting()
	}

	onGetRoomSuggestion = (body) => {
		return this.bRoom.getRoomSuggestion(body)
	}


	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewHome
					onGetSetting={this.onGetSetting}
					onGetRoomSuggestion={this.onGetRoomSuggestion}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(HomeController)