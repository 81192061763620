
import React from 'react'
// import { Container } from 'semantic-ui-react'
// import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import MyPage from '../../core/components/MyPage'

class BoxLoadingComponent extends MyPage {
    render() {
        try {
            const { iconClass, imageUrl, text } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <div className="module boxIconImageWithText">
                        {
                            !!iconClass &&
                            <div className="icon-wrapper">

                            </div>
                        }
                        {
                            !!imageUrl &&
                            <div className="image-wrapper">
                                <div className="image-box">
                                    <img src={imageUrl} alt="No" />
                                </div>
                                <div className="text-box">
                                    <div className="text-box-wrapper">
                                        <p>{text}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


BoxLoadingComponent.defaultProps = {
    iconClass: "",
    imageUrl: "",
    text: "",
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(BoxLoadingComponent)