
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import BuildingModel from './BuildingModel'
import FloorModel from './FloorModel'

const Schema = mongoose.Schema

const ZoneSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },

	availableAmenity: [{ type: Object }],
	availableFood: [{ type: Object }],

	buildingId: { type: String, default: "" },
	building: { type: Object, default: null },

	floorId: { type: String, default: "" },
	floor: { type: Object, default: null },

	imageId: { type: String, default: "" },
}, { _id: false })

setBaseSchema(ZoneSchema, "zone")

ZoneSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			this.code = data.code
			if (Array.isArray(data.availableAmenity)) {
				this.availableAmenity = data.availableAmenity.map(f => f)
			}
			if (Array.isArray(data.availableFood)) {
				this.availableFood = data.availableFood.map(f => f)
			}
			this.buildingId = data.buildingId
			if (!!data.building) {
				let buildingModel = new BuildingModel()
				buildingModel.setData(data.building)
				this.building = buildingModel
			}
			this.floorId = data.floorId
			if (!!data.floor) {
				let floorModel = new FloorModel()
				floorModel.setData(data.floor)
				this.floor = floorModel
			}
		}
	} catch (error) {

	}
}

ZoneSchema.methods.serializeOption = function () {
	try {
		return {
			key: this.id,
			value: this.id,
			text: this.name,
		}
	} catch (error) {
		return null
	}
}

const ZoneModel = mongoose.model("zone", ZoneSchema, "zone")

export default ZoneModel