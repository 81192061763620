import React from 'react'
// import { DatePicker, Calendar } from "antd"
import "antd/dist/antd.css"
import { Form, Icon, Popup } from 'semantic-ui-react'
// import '../../scss/inputDatePicker.scss'
import moment from 'moment'
import { translate } from 'react-switch-lang'
import ViewButton from './ViewButton'
import ButtonModel from '../../model/ButtonModel'
// import { dateTimeFormat } from '../../../../constant'

class InputDatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            value: moment(props.value),
            monthRender: moment(props.value),
            dates: this.onGenerateDate(moment(props.value)),
        }
    }

    onGenerateDate = (date) => {
        try {
            let dates = []
                , currentDate = moment(date).startOf("day")
                , startMonth = moment(currentDate.startOf("month"))
                , endMonth = moment(currentDate.endOf("month"))
            let startDayOfWeek = startMonth.day()
            let rowStart = []
                , startWeek = null
            for (let index = 0; index <= 6; index++) {
                let tempDate = null
                if (index <= startDayOfWeek) {
                    tempDate = moment(startMonth).subtract(startDayOfWeek - index, "day")
                } else {
                    tempDate = moment(startMonth).add(index - startDayOfWeek, "day")
                }
                rowStart.push(tempDate)
            }
            startWeek = rowStart[rowStart.length - 1]
            dates.push(rowStart)
            while (moment(startWeek).isBefore(endMonth, "day")) {
                let row = []
                for (let index = 1; index <= 7; index++) {
                    let tempDate = moment(startWeek).add(index, "day")
                    row.push(tempDate)
                }
                startWeek = row[row.length - 1]
                dates.push(row)
            }
            return dates
        } catch (error) {
            return []
        }
    }

    onChange = (date, dateString) => {
        const { name } = this.props
        this.setState({
            isOpen: false
        }, () => {
            this.props.onChange(null, { name: name, value: date })
        })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    handleOpen = () => {
        const { value } = this.props
        this.setState({
            isOpen: true,
            value: moment(value)
        })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    onPrevMonth = (value) => {
        let monthRender = this.state.monthRender
        monthRender.subtract(value, "month")
        this.setState({
            monthRender: monthRender,
            dates: this.onGenerateDate(monthRender),
        })
    }

    onNextMonth = (value) => {
        let monthRender = this.state.monthRender
        monthRender.add(value, "month")
        this.setState({
            monthRender: monthRender,
            dates: this.onGenerateDate(monthRender),
        })
    }

    addClass = (classStr, newClassStr) => {
        let tempClassStr = classStr
        if (!!classStr) {
            tempClassStr += ` ${newClassStr}`
        } else {
            tempClassStr = newClassStr
        }

        return tempClassStr
    }

    onRenderDay = (day, index) => {
        const { disableDate } = this.props
        const { value, monthRender } = this.state
        let classNameStr = "td-day"
        if (!day.isSame(monthRender, "month")) {
            classNameStr = this.addClass(classNameStr, "td-other-month")
        }
        if (day.isSame(moment(), "day")) {
            classNameStr = this.addClass(classNameStr, "td-today")
        }
        if (day.isSame(value, "day")) {
            classNameStr = this.addClass(classNameStr, "td-select")
        }
        if (typeof disableDate === "function") {
            let res = disableDate(day)
            if (res === true) {
                classNameStr = this.addClass(classNameStr, "td-disabled")
            }
        }
        return <td key={index} className={classNameStr} onClick={() => this.onSelectDate(day)}>
            <div>
                <p>{moment(day).format("DD")}</p>
            </div>
        </td>;
    }

    onSelectDate = (day) => {
        let oldDay = this.state.value
            , monthRender = this.state.monthRender
        if (!day.isSame(oldDay, "day")) {
            let tempState = {
                value: day
            }
            if (!day.isSame(monthRender, "month")) {
                tempState.dates = this.onGenerateDate(day)
                tempState.monthRender = day
            }
            this.setState(tempState)
        }
    }

    onSave = () => {
        this.setState({
            isOpen: false
        }, () => this.props.onChange(null, { name: this.props.name, value: this.state.value }))
    }

    onClose = () => {
        this.setState({
            isOpen: false
        })
    }

    render() {
        const { t, format, disabled } = this.props
        const { isOpen, dates, value } = this.state
        const weeks = [
            "date.dayOfWeek.short.sun",
            "date.dayOfWeek.short.mon",
            "date.dayOfWeek.short.tue",
            "date.dayOfWeek.short.wed",
            "date.dayOfWeek.short.thu",
            "date.dayOfWeek.short.fri",
            "date.dayOfWeek.short.sat"
        ];
        // console.log(this.onGenerateDate(moment()))
        return (
            <React.Fragment>
                <div className="module inputDatePicker">
                    {/* <div className="hide-picker">
                        <DatePicker
                            open={isOpen}

                            onChange={this.onChange}
                            onOpenChange={(e) => this.setState({ isOpen: false })}
                        // onPanelChange={(e, a, f) => console.log("8888", e, a, f)}
                        />
                    </div> */}
                    <Popup
                        className="popup-input-date-picker"
                        // flowing
                        on="click"
                        trigger={<div className={`input-display ${disabled === true ? "disabled" : ""}`} >
                            <Form.Input
                                fluid
                                icon={{ name: "calendar alternate outline", link: true }}
                                readOnly={true}
                                value={moment(this.props.value).format(format)}
                            />
                        </div>}
                        open={isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                    >
                        <div className="module inputDatePicker">
                            <Form onKeyDown={this.onKeyDownSubmitForm}>
                                <div className="input-date-picker-wrapper">
                                    <div className="box-control">
                                        <div className="item-icon" onClick={() => this.onPrevMonth(1)}>
                                            <Icon name="angle left" />
                                        </div>
                                        <div className="item-date">
                                            {value.format("MMMM YYYY")}
                                        </div>
                                        <div className="item-icon" onClick={() => this.onNextMonth(1)}>
                                            <Icon name="angle right" />
                                        </div>
                                    </div>
                                    <div className="box-table">
                                        <table>
                                            <thead>
                                                <tr className="tr-header">
                                                    {weeks.map((w, i) => <td key={i}>{t(w).toUpperCase()}</td>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dates.map((rows, w) =>
                                                    <tr key={w}>
                                                        {
                                                            rows.map((day, d) => {
                                                                return this.onRenderDay(day, d)
                                                            })
                                                        }
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="box-action">
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t("common.cancel"),
                                                color: "grey",
                                                onClick: this.onClose
                                            })}
                                        />
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t("common.save"),
                                                color: "blue",
                                                onClick: this.onSave
                                            })}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </Popup>

                    {/* <Calendar fullscreen={false} /> */}
                </div>
            </React.Fragment>
        )
    }
}

InputDatePicker.defaultProps = {
    name: "",
    value: moment(),
    onChange: () => console.error("Please provide On Change action."),
    format: "DD MMMM YYYY",
    disabled: false,
    // disableDate: (date) => {
    //     return moment(date).isBefore(moment(), "day")
    // }
    // minDate: moment(),
    // isActive: true,
    // isPage: true,
    // message: ""
}


export default translate(InputDatePicker)