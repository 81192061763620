
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Divider } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutBlankHeightMax from '../layout/LayoutBlank'
import ButtonModel from '../../core/model/ButtonModel'
import config from '../../config'

import LocalLoginForm from './LocalLoginForm'
import ViewButton from '../../core/view/common/ViewButton'
import FrontendIntegrationSettingModel from '../../models/FrontendIntegrationSettingModel'
import LocalLoginModel from '../../models/LocalLoginModel'
import { cookiesKey, systemKey } from '../../constant'
import ModalForgotPasswordController from '../../controllers/auth/ModalForgotPasswordController'
import ModalModel from '../../core/model/ModalModel'

class ViewLogin extends MyPage {
    constructor(props) {
        super(props)
        let model = new LocalLoginModel()
        if (process.env.NODE_ENV !== 'production') {
            model = new LocalLoginModel({
                username: "chutchai.p@bainisysstg.onmicrosoft.com",
                password: "Apple123"
                // username: "chutchai.p@bainisys2010.com",
                // password: "Apple123"
            })
        }
        this.state.isLoading = false
        this.state.setting = null
        this.state.model = model
        this.state.modelErrors = []
        this.state.isOpenLoginSystemForm = false

        this.state.isOpenModalForgotPassword = false

        this.state.authorizeCode = this.getQueryFromProps("code")
    }

    componentDidMount() {
        this.getData()
    }

    onToggleLoginForm = () => {
        this.setState({
            isOpenLoginSystemForm: !this.state.isOpenLoginSystemForm,
            model: new LocalLoginModel()
        })
    }

    getData = () => {
        const { authorizeCode } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                setting: null,
            },
                action = () => { }
            let res = await this.props.onGetSetting()
            if (!!res && res.data instanceof FrontendIntegrationSettingModel) {
                tempState.setting = res.data
                let system = systemKey[res.data.system]
                if (system === systemKey.EXCHANGE2010 || system === systemKey.EXCHANGE2013) {
                    tempState.isOpenLoginSystemForm = true
                }
                if (!!authorizeCode && !!system) {
                    action = () => {
                        this.onVerifySystem(system, authorizeCode)
                    }
                }
            }
            this.setState(tempState, action)
        })
    }

    onVerifySystem = (key, data) => {
        if (key === systemKey.O365) {
            this.props.onVerifyO365(data, (res) => {
                if (!res || res.result === false || !!res.error) {
                    this.setState({
                        authorizeCode: null,
                    })
                }
            })
        } else if (key === systemKey.GOOGLE) {
            this.props.onVerifyGoogle(data, (res) => {
                if (!res || res.result === false || !!res.error) {
                    this.setState({
                        authorizeCode: null,
                    })
                }
            })
        }
    }

    onLogin = () => {
        this.props.onVerifyLocal(this.state.model)
    }

    onClickLoginSystem = (key) => {
        if (key === systemKey.O365) {
            this.onLoginO365()
        } else if (key === systemKey.EXCHANGE2010) {
            this.onValidate(() => this.props.onVerifyExchange2010(this.state.model))
        } else if (key === systemKey.EXCHANGE2013) {
            this.onValidate(() => this.props.onVerifyExchange2013(this.state.model))
        } else if (key === systemKey.GOOGLE) {
            this.onLoginGoogle()
        }
    }

    onLoginO365 = () => {
        const { setting } = this.state
        let redirect_uri = ""
            , client_id = ""
        if (!!setting.o365Data) {
            client_id = setting.o365Data.client_id
            if (!!setting.o365Data.web) {
                redirect_uri = setting.o365Data.web.authen
                // redirect_uri = "http://localhost:4000/authorize"
            }
        }
        if (!!redirect_uri && !!client_id) {
            let authUrl = `https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&redirect_uri=${redirect_uri}&client_id=${client_id}`
            window.location.replace(authUrl)
        }
    }

    onLoginGoogle = () => {
        const { setting } = this.state
        let authUrl = ""
        if (!!setting.googleData && !!setting.googleData.web) {
            authUrl = setting.googleData.web.frontendAuthenRedirectUrl
        }
        if (!!authUrl) {
            window.location.replace(authUrl)
        }
    }

    handleChange = (e, { name, value }) => {
        try {
            let model = this.state.model
                , modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []

            let tempState = {}
                , action = () => {

                }

            model[name] = value
            if (name === "isRemember") {
                action = () => {
                    if (value === false) {
                        let datasCookie = this.bCookie.getDataAll()
                        datasCookie[cookiesKey.IS_REMEMBER] = false

                        this.bCookie.removeAll()

                        this.bCookie.setDatas(datasCookie)
                    } else {
                        this.bCookie.setData(cookiesKey.IS_REMEMBER, value)
                    }

                }
            }

            tempState.model = model

            let index = modelErrors.findIndex(e => e.path === name)
            if (index > -1) {
                modelErrors.splice(index, 1);
                tempState.modelErrors = modelErrors
            }
            this.setState(tempState, action)
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    onOpenModalForgotPassword = () => {
        this.setState({
            isOpenModalForgotPassword: true
        })
    }

    onSubmitModalForgotPassword = () => {
        const { t } = this.props
        this.setState({
            isOpenModalForgotPassword: false
        }, () => {
            this.openModal(new ModalModel({
                headerText: t("common.system"),
                content: t("message.sendEmailResetPasswordSuccess"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "grey",
                        onClick: this.closeModal
                    })
                ]
            }))
        })
    }

    onCloseModalForgotPassword = () => {
        this.setState({
            isOpenModalForgotPassword: false
        })
    }
    render() {
        const { t } = this.props
        const { isLoading, model, modelErrors, setting, authorizeCode, isOpenLoginSystemForm, isOpenModalForgotPassword } = this.state
        let isLoginSystem = false
            , isLoginLocal = false
            , system = null
            , isLoginSystemForm = false
            , isShowForgotPassword = false

        if (setting instanceof FrontendIntegrationSettingModel) {
            isLoginSystem = setting.isLoginSystem
            if (!!systemKey[setting.system]) {
                system = systemKey[setting.system]
            }
            isLoginSystem = (isLoginSystem && !!system)
            isLoginLocal = setting.isLoginLocal
            if (!setting.adData && (!!system && system !== systemKey.EXCHANGE2010 && system !== systemKey.EXCHANGE2013)) {
                isShowForgotPassword = true
            }
        }

        let textButtonLoginSystem = t("auth.login")
        if (system === systemKey.EXCHANGE2010 || system === systemKey.EXCHANGE2013) {
            isLoginSystemForm = true
        } else {
            textButtonLoginSystem = `${t("auth.signInWith")} ${t(`common.mailSystem.${system}`)}`
        }

        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {
                    isOpenModalForgotPassword &&
                    <ModalForgotPasswordController
                        onSubmit={this.onSubmitModalForgotPassword}
                        onClose={this.onCloseModalForgotPassword}
                    />
                }
                <LayoutBlankHeightMax>
                    <div className="module viewLogin">
                        <div className="view-login-wrapper" style={{ backgroundImage: `url(${config.publicUrl}/assets/images/bg_login.jpg)` }}>
                            <div className="view-login-bg-layer" >
                                <Container text>
                                    <div className="box-login">
                                        <div className="box-information">
                                            <div className="bf-logo">
                                                <img src={`${config.publicUrl}/assets/images/logo.png`} alt="System" />
                                            </div>
                                            <h1>{t("common.systemName")}</h1>
                                        </div>

                                        {
                                            !authorizeCode &&
                                            <React.Fragment>
                                                {
                                                    isLoginSystem &&
                                                    <React.Fragment>
                                                        {
                                                            !isOpenLoginSystemForm &&
                                                            <div className={`box-action-login ${!isLoginLocal ? "no-system" : ""}`}>
                                                                <ViewButton
                                                                    model={new ButtonModel({
                                                                        text: `${t("auth.signInWith")} ${t(`common.mailSystem.${system}`)}`,
                                                                        color: "blue",
                                                                        // onClick: this.onToggleLoginForm
                                                                        onClick: isLoginSystemForm ? this.onToggleLoginForm : () => this.onClickLoginSystem(system)
                                                                    })}
                                                                />
                                                            </div>
                                                        }
                                                        <div className={`box-mail-system ${isOpenLoginSystemForm ? "open" : ""}`}>
                                                            {
                                                                <div className="system-form">
                                                                    <h3>{`${t("auth.signInWith")} ${t(`common.mailSystem.${system}`)}`}</h3>
                                                                    <LocalLoginForm
                                                                        model={model}
                                                                        handleChange={this.handleChange}
                                                                        errors={modelErrors}
                                                                    />
                                                                </div>
                                                            }
                                                            <div className="system-action">
                                                                <ViewButton
                                                                    model={new ButtonModel({
                                                                        text: textButtonLoginSystem,
                                                                        color: "blue",
                                                                        onClick: () => this.onClickLoginSystem(system)
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            isLoginLocal &&
                                                            <div className="box-login-or">
                                                                <Divider horizontal>{t("common.or")}</Divider>
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    isOpenLoginSystemForm && isLoginLocal &&
                                                    <div className={`box-action-login ${!isLoginLocal ? "no-system" : ""}`}>
                                                        <ViewButton
                                                            model={new ButtonModel({
                                                                text: t("auth.signInLocal"),
                                                                color: "blue",
                                                                onClick: this.onToggleLoginForm
                                                            })}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    isLoginLocal &&
                                                    <div className={`box-local-login ${isOpenLoginSystemForm ? "" : "open"}`}>
                                                        <div className="local-form">
                                                            <LocalLoginForm
                                                                errors={modelErrors}
                                                                model={model}
                                                                handleChange={this.handleChange}
                                                                isForgotPassword={isShowForgotPassword}
                                                                onClickForgotPassword={this.onOpenModalForgotPassword}
                                                            />
                                                        </div>
                                                        <div className="local-action">
                                                            <ViewButton
                                                                model={new ButtonModel({
                                                                    text: t("auth.login"),
                                                                    color: "blue",
                                                                    onClick: () => this.onValidate(this.onLogin)
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </LayoutBlankHeightMax >
            </React.Fragment >
        )
    }
}

ViewLogin.defaultProps = {
    onGetSetting: () => {
        console.error("Please provide Get Setting action.")
        return null
    },
    onVerifyLocal: () => console.error("Please provide Verify Local action."),
    onVerifyO365: () => console.error("Please provide Verify O365 action."),
    onVerifyGoogle: () => console.error("Please provide Verify Google action."),
    onVerifyExchange2010: () => console.error("Please provide Verify Exchange 2010 action."),
    onVerifyExchange2013: () => console.error("Please provide Verify Exchange 2013 action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewLogin)