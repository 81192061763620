
import common from './en/common.json'
import auth from './en/auth.json'
import event from './en/event.json'
import user from './en/user.json'
import room from './en/room.json'
import order from './en/order.json'
import location from './en/location.json'
import message from './en/message.json'
import validate from './en/validate.json'
import date from './en/date.json'

const obj = {
    ...common,
    ...auth,
    ...event,
    ...user,
    ...room,
    ...order,
    ...location,
    ...message,
    ...validate,
    ...date,
}

export default obj