
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewIntegrationExchange from '../../views/user/ViewIntegrationExchange'
import UserManager from '../../business/UserManager'
import { afterIntegration } from '../../business/AuthVerifyManager'
import { cookiesKey } from '../../constant'

class IntegrationExchangeController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
	}

	onIntegrationExchange = (model) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bUser.integrationExchange(model.serializeAPI())
			if (!!res && res.result === true) {
				tempState.isLoading = true
				let isRemember = false
				if (typeof this.bCookie.getData(cookiesKey.IS_REMEMBER) === "boolean") {
					isRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
				}
				action = () => {
					afterIntegration(res.data, isRemember, () => this.props.history.goBack())
				}
			}
			this.setState(tempState, action)
		})
	}

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewIntegrationExchange
					onIntegrationExchange={this.onIntegrationExchange}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(IntegrationExchangeController)