
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const WebSchema = new Schema({
	client_id: { type: String, default: "" },
	client_secret: { type: String, default: "" },
	frontendAuthenRedirectUrl: { type: String, default: "" },
	frontendIntegrationRedirectUrl: { type: String, default: "" },
	kioskAuthenRedirectUrl: { type: String, default: "" },
	redirect_uris: [{ type: String }],
}, { _id: false })

// const Audience = new Schema({
// 	type: { type: String, default: "" },
// 	tenant_id: { type: String, default: "" },
// }, { _id: false })

// const AuthoritiesSchema = new Schema({
// 	_id: { type: String, default: "" },
// 	type: { type: String, default: "" },
// 	audience: { type: Audience, default: {} },
// }, { _id: false })

// const AndroidSchema = new Schema({
// 	client_id: { type: String, default: "" },
// 	authorization_user_agent: { type: String, default: "" },
// 	redirect_uri: { type: String, default: "" },
// 	signatureHash: { type: String, default: "" },
// 	packageName: { type: String, default: "" },
// 	authorities: [{ type: AuthoritiesSchema }],
// }, { _id: false })

// const IOSSchema = new Schema({
// 	kClientID: { type: String, default: "" },
// 	kRedirectUri: { type: String, default: "" },
// 	kAuthority: { type: String, default: "" },
// 	bundleID: { type: String, default: "" },
// }, { _id: false })

const GoogleDataSchema = new Schema({
	web: { type: WebSchema, default: {} },

	// android: { type: AndroidSchema, default: {} },
	// ios: { type: IOSSchema, default: {} },
}, { _id: false })

setBaseSchema(GoogleDataSchema, "google_data")

const GoogleDataModel = mongoose.model("google_data", GoogleDataSchema, "google_data")

export default GoogleDataModel