
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../../core/view/common/LabelInput'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import InputTimePicker from '../../core/view/common/InputTimePicker'
import CreateUpdateEventModel from '../../models/CreateUpdateEventModel'
import { conferenceTypeCode, defaultValue } from '../../constant'
import ViewButton from '../../core/view/common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ModalAttendeeController from '../../controllers/event/ModalAttendeeController'
import ViewErrorInput from '../common/ViewErrorInput'
import FrontendSettingModel from '../../models/FrontendSettingModel'
import InputHtml from '../../core/view/common/InputHtml'
import ModalRecurrence from './ModalRecurrence'
import moment from 'moment'

class CreateUpdateEventForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isOpenAddAttendee = false
        this.state.isOpenAddOptional = false
        this.state.isOpenModalRecurrence = false
    }

    onClickOpenModalAddAttendee = (e) => {
        e.preventDefault();
        this.setState({
            isOpenAddAttendee: true
        })
    }
    onSubmitModalAddAttendee = (attendees) => {
        this.setState({
            isOpenAddAttendee: false
        }, () => this.handleChange(null, { name: "attendees", value: attendees }))
    }
    onCloseModalAddAttendee = () => {
        this.setState({
            isOpenAddAttendee: false
        })
    }
    onRemoveAttendee = (e, index) => {
        e.preventDefault();
        let attendees = this.props.model.attendees
        if (index > -1) {
            attendees.splice(index, 1)
        }
        this.handleChange(null, { name: "attendees", value: attendees })
    }

    onClickOpenModalAddOptional = (e) => {
        e.preventDefault();
        this.setState({
            isOpenAddOptional: true
        })
    }
    onSubmitModalAddOptional = (optionals) => {
        this.setState({
            isOpenAddOptional: false
        }, () => this.handleChange(null, { name: "optionals", value: optionals }))
    }
    onCloseModalAddOptional = () => {
        this.setState({
            isOpenAddOptional: false
        })
    }
    onRemoveOptional = (e, index) => {
        e.preventDefault();
        let optionals = this.props.model.optionals
        if (index > -1) {
            optionals.splice(index, 1)
        }
        this.handleChange(null, { name: "optionals", value: optionals })
    }

    onClickOpenModalRecurrence = (e) => {
        e.preventDefault();
        this.setState({
            isOpenModalRecurrence: true
        })
    }
    onSubmitModalRecurrence = (modelRecurrence) => {
        this.setState({
            isOpenModalRecurrence: false
        }, () => this.handleChange(null, { name: "recurrence", value: modelRecurrence }))
    }
    onCloseModalRecurrence = () => {
        this.setState({
            isOpenModalRecurrence: false
        })
    }

    handleChangeDate = (e, { name, value }) => {
        const { startDateTime, endDateTime } = this.props.model
        let tempValue = value
        if (name === "startDateTime") {
            tempValue = moment(moment(value).format("YYYY-MM-DD") + moment(startDateTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
        } else if (name === "endDateTime") {
            tempValue = moment(moment(value).format("YYYY-MM-DD") + moment(endDateTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
        }
        this.handleChange(e, { name, value: tempValue })
    }

    handleChangeTime = (e, { name, value }) => {
        const { startDateTime, endDateTime } = this.props.model
        let tempValue = value
        if (name === "startDateTime") {
            tempValue = moment(moment(startDateTime).format("YYYY-MM-DD") + moment(value).format("HH:mm"), "YYYY-MM-DDHH:mm")
        } else if (name === "endDateTime") {
            tempValue = moment(moment(endDateTime).format("YYYY-MM-DD") + moment(value).format("HH:mm"), "YYYY-MM-DDHH:mm")
        }
        this.handleChange(e, { name, value: tempValue })
    }
    render() {
        const { t, setting, model, optionsEventType, optionsEventRoomFormat, usersMailSystem, userGroups, errors,
            optionsConference } = this.props
        const { isOpenAddAttendee, isOpenAddOptional, isOpenModalRecurrence } = this.state
        if (!(model instanceof CreateUpdateEventModel)) {
            return null
        }
        let tempOptionsEventRoomFormat = [
            {
                key: defaultValue.NONE_SELECT,
                value: defaultValue.NONE_SELECT,
                text: `-- ${t("common.noSelect")} --`
            }
        ]
        tempOptionsEventRoomFormat = tempOptionsEventRoomFormat.concat(optionsEventRoomFormat)

        let tempOptionsEventType = [
            {
                key: defaultValue.PLEASE_SELECT,
                value: defaultValue.PLEASE_SELECT,
                text: `-- ${t("common.pleaseSelect")} --`
            }
        ]
        tempOptionsEventType = tempOptionsEventType.concat(optionsEventType)

        let subjectError = this.getErrorInput("subject", errors)
            , eventTypeIdError = this.getErrorInput("eventTypeId", errors)
            , conferenceTypeError = this.getErrorInput("conferenceType", errors)
            , tmsPasswordError = this.getErrorInput("tmsPassword", errors)
            , endDateTimeError = this.getErrorInput("endDateTime", errors)

        let minuteStep = null
            , isEnableConferenceType = false
            , isEnableEventType = false
        if (setting instanceof FrontendSettingModel) {
            minuteStep = setting.slotDuration
            isEnableConferenceType = setting.isEnableConferenceType
            isEnableEventType = setting.isEnableEventType
        }

        // let errorSelectDateTime = null
        // let selectStart = moment(model.startDateTime)
        //     , selectEnd = moment(model.endDateTime)
        // if (selectEnd.isSameOrBefore(selectStart)) {
        //     errorSelectDateTime = t("message.endTimeGreaterThanStartTime")
        // }

        return (
            <React.Fragment>
                {super.render()}
                {
                    isOpenAddAttendee &&
                    <ModalAttendeeController
                        usersMailSystem={usersMailSystem}
                        userGroups={userGroups}
                        attendees={model.attendees}
                        textHeader={t("event.attendeeList")}
                        onSubmit={this.onSubmitModalAddAttendee}
                        onClose={this.onCloseModalAddAttendee}
                    />
                }
                {
                    isOpenAddOptional &&
                    <ModalAttendeeController
                        usersMailSystem={usersMailSystem}
                        userGroups={userGroups}
                        attendees={model.optionals}
                        textHeader={t("event.optionalList")}
                        onSubmit={this.onSubmitModalAddOptional}
                        onClose={this.onCloseModalAddOptional}
                    />
                }
                {
                    isOpenModalRecurrence &&
                    <ModalRecurrence
                        model={model.recurrence}
                        onSubmit={this.onSubmitModalRecurrence}
                        onClose={this.onCloseModalRecurrence}
                    />
                }
                <div className="module createUpdateEventForm">
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div className="box-subject">
                                        <LabelInput text={t("event.subject")} isRequire={true} />
                                        <Form.Checkbox
                                            name="isPrivate"
                                            label={t("event.hideSubject")}
                                            checked={model.isPrivate}
                                            onChange={this.handleChangeBoolean}
                                        />
                                    </div>
                                    <Form.Input
                                        fluid
                                        name="subject"
                                        value={model.subject}
                                        onChange={this.handleChange}
                                        error={subjectError.isError}
                                    />
                                    {
                                        subjectError.isError &&
                                        <ViewErrorInput error={subjectError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                    <InputHtml
                                        name="content"
                                        value={model.content}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Checkbox
                                        name="isAllDay"
                                        label={t("common.allDay")}
                                        checked={model.isAllDay}
                                        onChange={this.handleChangeBoolean}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                model.isAllDay ?
                                    <React.Fragment>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <LabelInput text={t("common.startDate")} isRequire={false} />
                                                <InputDatePicker
                                                    name="startDateTime"
                                                    value={model.startDateTime}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <LabelInput text={t("common.endDate")} isRequire={false} />
                                                <InputDatePicker
                                                    name="endDateTime"
                                                    value={model.endDateTime}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </Grid.Column>
                                            {
                                                endDateTimeError.isError &&
                                                <Grid.Column width={16} textAlign="center">
                                                    <ViewErrorInput error={endDateTimeError.content} />
                                                </Grid.Column>
                                            }
                                        </Grid.Row>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <Grid.Row>
                                            <Grid.Column width={9}>
                                                <LabelInput text={t("common.startDate")} isRequire={false} />
                                                <InputDatePicker
                                                    name="startDateTime"
                                                    value={model.startDateTime}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <LabelInput text={t("common.time")} isRequire={false} />
                                                <InputTimePicker
                                                    name="startDateTime"
                                                    value={model.startDateTime}
                                                    minuteStep={minuteStep}
                                                    onChange={this.handleChangeTime}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={9}>
                                                <LabelInput text={t("common.endDate")} isRequire={false} />
                                                <InputDatePicker
                                                    name="endDateTime"
                                                    value={model.endDateTime}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <LabelInput text={t("common.time")} isRequire={false} />
                                                <InputTimePicker
                                                    name="endDateTime"
                                                    value={model.endDateTime}
                                                    minuteStep={minuteStep}
                                                    onChange={this.handleChangeTime}
                                                />
                                            </Grid.Column>
                                            {
                                                endDateTimeError.isError &&
                                                <Grid.Column width={16} textAlign="center">
                                                    <ViewErrorInput error={endDateTimeError.content} />
                                                </Grid.Column>
                                            }
                                        </Grid.Row>
                                    </React.Fragment>
                            }

                            <Grid.Row verticalAlign="bottom">
                                <Grid.Column width={6}>
                                    <LabelInput text={t("event.attendees")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={10} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: model.attendees.length > 0 ? t("event.updateAttendees") : t("event.addAttendees"),
                                            color: "green",
                                            size: "tiny",
                                            onClick: this.onClickOpenModalAddAttendee
                                        })}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <div className="box-select-attendee">
                                        <div className="select-attendee-wrapper">
                                            {
                                                model.attendees.length > 0 ?
                                                    <div className="attendee-list">
                                                        {
                                                            model.attendees.map((user, i) => {
                                                                return <div key={i} className="item-select-attendee">
                                                                    <div className="isa-text">
                                                                        <p className="text-name">{user.fullName}</p>
                                                                        <p className="text-email">{user.email}</p>
                                                                    </div>
                                                                    <div className="isa-action">
                                                                        <ViewButton
                                                                            model={new ButtonModel({
                                                                                text: t("common.remove"),
                                                                                size: "mini",
                                                                                color: "red",
                                                                                onClick: (e) => this.onRemoveAttendee(e, i)
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    : <div className="please-select">
                                                        <p>{t("event.addAttendees")}</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="bottom">
                                <Grid.Column width={6}>
                                    <LabelInput text={t("event.optionals")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={10} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: model.optionals.length > 0 ? t("event.updateOptionals") : t("event.addOptionals"),
                                            color: "green",
                                            size: "tiny",
                                            onClick: this.onClickOpenModalAddOptional
                                        })}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <div className="box-select-attendee">
                                        <div className="select-attendee-wrapper">
                                            {
                                                model.optionals.length > 0 ?
                                                    <div className="attendee-list">
                                                        {
                                                            model.optionals.map((user, i) => {
                                                                return <div key={i} className="item-select-attendee">
                                                                    <div className="isa-text">
                                                                        <p className="text-name">{user.fullName}</p>
                                                                        <p className="text-email">{user.email}</p>
                                                                    </div>
                                                                    <div className="isa-action">
                                                                        <ViewButton
                                                                            model={new ButtonModel({
                                                                                text: t("common.remove"),
                                                                                size: "mini",
                                                                                color: "red",
                                                                                onClick: (e) => this.onRemoveOptional(e, i)
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    : <div className="please-select">
                                                        <p>{t("event.addOptionals")}</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            {
                                !model.isAllDay &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t("event.repeat")} isRequire={false} />
                                        <div className="input-recurrence">
                                            <Form.Input
                                                readOnly
                                                onClick={this.onClickOpenModalRecurrence}
                                                value={!!model.recurrenceTextValue ? t(model.recurrenceTextValue) : ""}
                                            />
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {
                                (!model.isAllDay && isEnableConferenceType && !model.recurrence) &&
                                <React.Fragment>
                                    <Grid.Row >
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("event.conferenceType")} isRequire={false} />
                                            <Form.Dropdown
                                                selection
                                                options={optionsConference}
                                                name="conferenceType"
                                                value={model.conferenceType}
                                                onChange={this.handleChange}
                                                error={conferenceTypeError.isError}
                                            />
                                            {
                                                conferenceTypeError.isError &&
                                                <ViewErrorInput error={conferenceTypeError.content} />
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    {
                                        model.conferenceType === conferenceTypeCode.CISCO_TMS &&
                                        <Grid.Row >
                                            <Grid.Column width={16}>
                                                <div className="box-conference-tms">
                                                    <div className="checkbox">
                                                        <Form.Checkbox
                                                            label={t("event.enablePinCode")}
                                                            name="tmsPrivacy"
                                                            checked={model.tmsPrivacy}
                                                            onChange={this.handleChangeBoolean}
                                                        />
                                                    </div>
                                                    <div className="input-pin">
                                                        {/* <LabelInput text={t("event.conferenceType")} isRequire={false} /> */}
                                                        <Form.Input
                                                            fluid
                                                            placeholder={t("event.hintPINTnsPassword")}
                                                            name="tmsPassword"
                                                            value={model.tmsPassword}
                                                            onChange={this.handleChange}
                                                            error={tmsPasswordError.isError}
                                                            disabled={!model.tmsPrivacy}
                                                        />
                                                        {
                                                            tmsPasswordError.isError &&
                                                            <ViewErrorInput error={tmsPasswordError.content} />
                                                        }
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                </React.Fragment>
                            }
                            {
                                isEnableEventType &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t("event.eventType")} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            options={tempOptionsEventType}
                                            name="eventTypeId"
                                            value={model.eventTypeId}
                                            onChange={this.handleChange}
                                            error={eventTypeIdError.isError}
                                        />
                                        {
                                            eventTypeIdError.isError &&
                                            <ViewErrorInput error={eventTypeIdError.content} />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("room.roomFormat")} isRequire={false} />
                                    <Form.Dropdown
                                        selection
                                        options={tempOptionsEventRoomFormat}
                                        name="eventRoomFormatId"
                                        value={model.eventRoomFormatId}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment >
        )
    }
}

CreateUpdateEventForm.defaultProps = {
    setting: new FrontendSettingModel(),
    model: new CreateUpdateEventModel(),
    errors: [],
    optionsEventType: [],
    optionsEventRoomFormat: [],
    usersMailSystem: [],
    userGroups: [],
    optionsConference: [],
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(CreateUpdateEventForm)