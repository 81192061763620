
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import ChangePasswordForm from './ChangePasswordForm'
import ChangePasswordModel from '../../models/ChangePasswordModel'
import { cookiesKey } from '../../constant'

class ViewChangePassword extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.model = new ChangePasswordModel()
        this.state.modelErrors = []
    }

    onChangePassword = () => {
        let { user, model } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => { }
            let res = await this.props.onChangePassword(user.id, model.serializeAPI())
            if (!!res && res.result === true && res.data === true) {
                tempState.isLoading = true
                action = () => {
                    this.bCookie.removeAll()
                    this.props.history.replace("/")
                }
            }
            this.setState(tempState, action)
        })
    }

    onBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { t } = this.props
        const { model, modelErrors, isLoading } = this.state
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewChangePassword">
                        <Container text>
                            <div className="change-password-header">
                                <h1>{t("user.changePassword")}</h1>
                            </div>
                            <div className="change-password-body">
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ChangePasswordForm
                                                    model={model}
                                                    handleChange={this.handleChange}
                                                    errors={modelErrors}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Column width={16} textAlign="center">
                                            <div className="box-action">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.change"),
                                                        color: "blue",
                                                        disabled: isLoading,
                                                        onClick: () => this.onValidate(this.onChangePassword)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.cancel"),
                                                        color: "grey",
                                                        disabled: isLoading,
                                                        onClick: this.onBack
                                                    })}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewChangePassword.defaultProps = {
    onChangePassword: () => {
        console.error("Please provide Change Password action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewChangePassword)