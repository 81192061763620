
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import { cookiesKey, dateTimeFormat } from '../../constant'
import UserModel from '../../models/UserModel'
import { CORE_API_ENDPOINT } from '../../lib'
import moment from 'moment'

class ViewViewEvent extends MyPage {
    constructor(props) {
        super(props)
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                model: null,
            },
                action = () => {

                }
            let res = await this.props.onGetUserInfo(user.id)
            if (!!res && res.result === true && res.data instanceof UserModel) {
                tempState.model = res.data
            }
            this.setState(tempState, action)
        })
    }

    onClickBack = () => {
        this.props.history.goBack()
    }

    onClickChangePassword = () => {
        this.props.history.push(`/user/changepassword`)
    }

    onClickChangeUserInformation = () => {
        this.props.history.push(`/user/changeuserinformation`)
    }

    render() {
        const { t } = this.props
        const { user, model, isLoading } = this.state

        let maxAdvanceBookingDay = ""
            , maximumBookingMinutes = ""
        // , maxBookingPerDay = ""
        if (!!model) {
            maxAdvanceBookingDay = model.maxAdvanceBookingDay
            maximumBookingMinutes = model.maximumBookingMinutes
            // maxBookingPerDay = model.maxBookingPerDay
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewUser">
                        <Container text>
                            <div className="view-user-header">
                                <h1>{t("user.userInformation")}</h1>
                            </div>

                            {
                                !!model &&
                                <div className="view-user-body">
                                    <div className="box-user-detail">
                                        <div className="user-image">
                                            <div className="user-image-wrapper">
                                                <img src={`${CORE_API_ENDPOINT}/image/user/${user.id}?time=${moment().format(dateTimeFormat.apiFullDateTime)}`} alt="User" />
                                            </div>
                                        </div>
                                        <div className="user-detail">
                                            <div className="user-detail-wrapper">
                                                <h2>{model.fullName}</h2>
                                                <p>{model.email}</p>
                                            </div>
                                        </div>
                                        <div className="user-action">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t("user.changePassword"),
                                                    color: "blue",
                                                    size: "small",
                                                    onClick: this.onClickChangePassword
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t("user.editProfile"),
                                                    color: "blue",
                                                    size: "small",
                                                    onClick: this.onClickChangeUserInformation
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="box-user-information">
                                        <div className="user-information-wrapper">
                                            <div className="information-header">
                                                <p>{t("user.userInformation")}</p>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.employeeCode")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{model.employeeCode}</p>
                                                </div>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.pinCode")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{model.pinCode}</p>
                                                </div>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.rfid")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{model.rfid}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-meeting-information">
                                        <div className="meeting-information-wrapper">
                                            <div className="information-header">
                                                <p>{t("user.meetingRoomInformation")}</p>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.role")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{model.roleNames.join(", ")}</p>
                                                </div>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.maximumAdvancedBooking")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{maxAdvanceBookingDay}</p>
                                                </div>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("user.maximumDurationPerEvent")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <p>{maximumBookingMinutes}</p>
                                                </div>
                                            </div>
                                            <div className="box-detail">
                                                <div className="detail-header">
                                                    <p>{t("room.availableRooms")}</p>
                                                    <p>:</p>
                                                </div>
                                                <div className="detail-content">
                                                    <div className="box-available-rooms">
                                                        {
                                                            Array.isArray(model.avaiableRoomsNameSummary) &&
                                                            model.avaiableRoomsNameSummary.map((text, i) => {
                                                                return <p key={i}>- {text}</p>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        model.syncInfo &&
                                        <div className="box-o365-information">
                                            <div className="o365-information-wrapper">
                                                <div className="information-header">
                                                    <p>{t("user.o365Information")}</p>
                                                </div>
                                                <div className="box-detail">
                                                    <div className="detail-header">
                                                        <p>{t("common.name")}</p>
                                                        <p>:</p>
                                                    </div>
                                                    <div className="detail-content">
                                                        <p>{model.syncInfo.displayName}</p>
                                                    </div>
                                                </div>
                                                <div className="box-detail">
                                                    <div className="detail-header">
                                                        <p>{t("common.email")}</p>
                                                        <p>:</p>
                                                    </div>
                                                    <div className="detail-content">
                                                        <p>{model.syncInfo.mail}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        model.syncADInfo &&
                                        <div className="box-ad-information">
                                            <div className="ad-information-wrapper">
                                                <div className="information-header">
                                                    <p>{t("user.o365Information")}</p>
                                                </div>
                                                <div className="box-detail">
                                                    <div className="detail-header">
                                                        <p>{t("common.name")}</p>
                                                        <p>:</p>
                                                    </div>
                                                    <div className="detail-content">
                                                        <p>{model.syncADInfo.givenName}</p>
                                                    </div>
                                                </div>
                                                <div className="box-detail">
                                                    <div className="detail-header">
                                                        <p>{t("common.email")}</p>
                                                        <p>:</p>
                                                    </div>
                                                    <div className="detail-content">
                                                        <p>{model.syncADInfo.mail}</p>
                                                    </div>
                                                </div>
                                                <div className="box-detail">
                                                    <div className="detail-header">
                                                        <p>{t("common.tel")}</p>
                                                        <p>:</p>
                                                    </div>
                                                    <div className="detail-content">
                                                        <p>{model.syncADInfo.mobile}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewViewEvent.defaultProps = {
    onGetUserInfo: () => {
        console.error("Please provide Get Event action.")
        return null
    }
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewViewEvent)