
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewTerms from '../../views/global/ViewTerms'

import SettingManager from '../../business/SettingManager'

class TermsController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
	}

	onGetTerms = () => {
		return this.bSetting.getTerms()
	}

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewTerms
					onGetTerms={this.onGetTerms}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(TermsController)