
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import { cookiesKey } from '../../constant'
import { CORE_API_ENDPOINT } from '../../lib'

class ViewUserQRCode extends MyPage {
    constructor(props) {
        super(props)
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false
    }

    render() {
        const { t } = this.props
        
        const { user, isLoading } = this.state

        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewUserQRCode">
                        <Container text>
                            <div className="view-user-qr-code-header">
                                <h1>{t("common.myQRCode")}</h1>
                            </div>
                            <div className="view-user-qr-code-body">
                                {
                                    !!user &&
                                    <div className="qr-code">
                                        <img src={`${CORE_API_ENDPOINT}/image/userqr/${user.id}`} alt="My QR Code" />
                                    </div>
                                }
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewUserQRCode.defaultProps = {
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewUserQRCode)