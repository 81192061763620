
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'
import { QrReader } from 'react-qr-reader'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import { cookiesKey } from '../../constant'
import base64 from 'base-64'

var isReceive = true
class ViewScanQRCode extends MyPage {
    constructor(props) {
        super(props)
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false
    }

    onResult = (result, error) => {
        if (isReceive && !!result && typeof result.text === "string") {
            isReceive = false
            this.setState({
                isLoading: true
            }, () => {
                try {
                    let texts = result.text.split('/roominfo/')
                    if (texts.length > 1) {
                        this.props.history.replace(`/room/view?roomEmail=${encodeURIComponent(base64.decode(texts[1]))}`)
                    } else {
                        throw new Error("QR not found")
                    }
                } catch (error) {
                    isReceive = true
                    this.setState({
                        isLoading: false
                    })
                }
            })
        }
    }

    render() {
        const { t } = this.props
        const { isLoading } = this.state

        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewScanQRCode">
                        <Container text>
                            <div className="view-scan-qr-code-header">
                                <h1>{t("common.scanQRCode")}</h1>
                            </div>
                            <div className="view-scan-qr-code-body">
                                <div className="qr-code-camera">
                                    <QrReader
                                        scanDelay={1000}
                                        onResult={this.onResult}
                                        videoContainerStyle={{
                                            height: "100%"
                                        }}
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewScanQRCode.defaultProps = {
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewScanQRCode)