
import React from 'react'
import MyPage from '../../core/components/MyPage'
// import { Icon } from 'semantic-ui-react'
import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import SearchLocationModel from '../../models/SearchLocationModel'
import { CORE_API_ENDPOINT } from '../../lib'
import { defaultValue } from '../../constant'
import ViewButton from '../../core/view/common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
// import Footer from './Footer'
// import Header from './Header'

class ItemCriteriaSearchRoom extends MyPage {
    render() {
        try {
            const { t, model, onDelete, onEdit } = this.props
            if (!model instanceof SearchLocationModel) {
                return null
            }
            // let facilities = model.facilities

            // let facilities = [
            //     {
            //         name: "WiFi",
            //         image: `${config.publicUrl}/assets/images/dev/facility_wifi.png`
            //     },
            //     {
            //         name: "TV",
            //         image: `${config.publicUrl}/assets/images/dev/facility_tv.png`
            //     },
            //     {
            //         name: "Phone",
            //         image: `${config.publicUrl}/assets/images/dev/facility_phone.png`
            //     },
            // ]
            let locationUrl = `${config.publicUrl}/assets/images/icon-mark.png`
                , classLocationImage = ""
            if (!!model.zoneId && model.zoneId !== defaultValue.NONE_SELECT) {
                locationUrl = `${CORE_API_ENDPOINT}/image/zone/${model.zoneId}`
            } else if (!!model.floorId && model.floorId !== defaultValue.NONE_SELECT) {
                locationUrl = `${CORE_API_ENDPOINT}/image/floor/${model.floorId}`
            } else if (!!model.buildingId && model.buildingId !== defaultValue.NONE_SELECT) {
                locationUrl = `${CORE_API_ENDPOINT}/image/building/${model.buildingId}`
            } else {
                classLocationImage = "no-location"
            }
            return (
                <div className="module itemCriteriaSearchRoom">
                    <div className="item-body">
                        <div className="ir-action">
                            <div className="action-box">
                                {/* <div className="item-action">
                                    <Icon name="edit outline" />
                                </div>
                                <div className="item-action">
                                    <Icon name="close" />
                                </div> */}
                                <ViewButton model={new ButtonModel({
                                    text: t("common.edit"),
                                    color: "blue",
                                    size: "tiny",
                                    onClick: () => onEdit(model)
                                })} />
                                <ViewButton model={new ButtonModel({
                                    text: t("common.delete"),
                                    color: "grey",
                                    size: "tiny",
                                    onClick: () => onDelete(model)
                                })} />
                            </div>
                        </div>
                        <div className="ir-image">
                            <div className={`box-image ${classLocationImage}`}>
                                <img src={locationUrl} alt="Location" />
                            </div>
                        </div>
                        <div className="ir-detail">
                            <p><b>{t("common.location")}: </b> {model.locationName}</p>
                            <p><b>{t("common.capacity")}: </b> {model.capacity ? model.capacity : "-"}</p>
                            <div className="facilities-box">
                                <p><b>{t("common.facilities")}: </b> {model.facilities.length === 0 ? "-" : ""}</p>
                                {
                                    model.facilities.map((f, i) => {
                                        return <div key={i} className="item-facility">
                                            <div className="facility-box">
                                                <img src={`${CORE_API_ENDPOINT}/image/amenity/${f.id}`} alt={f.name} />
                                                <p>{f.name}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ItemCriteriaSearchRoom.defaultProps = {
    model: null,
    onDelete: () => console.error("Please provide Delete action."),
    onEdit: () => console.error("Please provide Edit action"),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ItemCriteriaSearchRoom)