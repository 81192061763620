
import PrivateRoute from '../policy/PrivateRoute'
// import PublicRoute from '../policy/PublicRoute'

import MyReservationController from '../controllers/event/MyReservationController'
import UpdateEventController from '../controllers/event/UpdateEventController'
import CreateEventController from '../controllers/event/CreateEventController'
import ViewEventController from '../controllers/event/ViewEventController'

let routeConfigs = [
    { route: PrivateRoute, path: "/booking", component: MyReservationController },
    { route: PrivateRoute, path: "/update", component: UpdateEventController },
    { route: PrivateRoute, path: "/view", component: ViewEventController },
    { route: PrivateRoute, path: "/create", component: CreateEventController },
]

const RouteConfig = {
    module: "event",
    routeConfigs,
}

export default RouteConfig