
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const AvailableRoomSchema = new Schema({
	id: { type: String, default: "" },
	name: { type: String, default: "" },
}, { _id: false })

const RoleSchema = new Schema({
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	description: { type: String, default: "" },

	maxAdvanceBookingDay: { type: Number, default: 0 },
	maximumBookingMinutes: { type: Number, default: 0 },
	maxBookingPerDay: { type: Number, default: 0 },

	availableRooms: [{ type: AvailableRoomSchema }],
	avaiableRoomsId: [{ type: String }],
	avaiableRoomsName: [{ type: String }],
	// web: { type: WebSchema, default: {} },
	// android: { type: AndroidSchema, default: {} },
	// ios: { type: IOSSchema, default: {} },
}, { _id: false })

setBaseSchema(RoleSchema, "role")

RoleSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.code = data.code
			this.name = data.name
			this.description = data.description

			if (!isNaN(data.maxAdvanceBookingDay)) {
				this.maxAdvanceBookingDay = data.maxAdvanceBookingDay
			}
			if (!isNaN(data.maximumBookingMinutes)) {
				this.maximumBookingMinutes = data.maximumBookingMinutes
			}
			if (!isNaN(data.maxBookingPerDay)) {
				this.maxBookingPerDay = data.maxBookingPerDay
			}
			if (Array.isArray(data.availableRooms)) {
				this.availableRooms = data.avaiableRoomsId.filter(r => typeof r === "object" && !Array.isArray(r))
			}
			if (Array.isArray(data.avaiableRoomsId)) {
				this.avaiableRoomsId = data.avaiableRoomsId.filter(id => !!id)
			}
			if (Array.isArray(data.avaiableRoomsName)) {
				this.avaiableRoomsName = data.avaiableRoomsName.filter(name => !!name)
			}
		}
	} catch (error) {

	}
}

export default RoleSchema