
import moment from 'moment'
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import FileModel from '../core/model/FileModel'
import RoleModel from './RoleModel'

const Schema = mongoose.Schema

const AvailableRoomSchema = new Schema({
	id: { type: String, default: "" },
	name: { type: String, default: "" },
}, { _id: false })

const O365SyncInfoSchema = new Schema({
	id: { type: String, default: "" },
	displayName: { type: String, default: "" },
	givenName: { type: String, default: "" },
	surname: { type: String, default: "" },
	mail: { type: String, default: "" },
	mailNickname: { type: String, default: "" },
}, { _id: false })

const ADSyncInfoSchema = new Schema({
	id: { type: String, default: "" },
	givenName: { type: String, default: "" },
	surname: { type: String, default: "" },
	mail: { type: String, default: "" },
	mobile: { type: String, default: "" },
}, { _id: false })

const RoleSchema = new Schema({
	roleId: { type: String, default: "" },
	isDefault: { type: Boolean, default: false },
	role: { type: Object },
}, { _id: false })

const TokenSchema = new Schema({
	id: { type: String, default: "" },
	email: { type: String, default: "" },
	service: { type: String, default: "" },
	data: { type: Object },
}, { _id: false })

const UserSchema = new Schema({
	id: { type: String, default: "" },
	employeeCode: { type: String, default: "" },
	username: { type: String, default: "" },
	email: { type: String, default: "" },
	firstName: { type: String, default: "" },
	lastName: { type: String, default: "" },
	mobile: { type: String, default: "" },
	isAdmin: { type: Boolean, default: false },
	isIntegration: { type: Boolean, default: false },
	rfId: { type: String, default: "" },
	pinCode: { type: String, default: "" },
	userGroupsId: [{ type: String }],
	favoriteRoom: [{ type: String }],
	isActive: { type: Boolean, default: false },
	timeZone: { type: String, default: "Asia/Bangkok" },
	isFirstLogin: { type: Boolean, default: false },
	lastLoginTime: { type: Date, default: moment() },
	token: [{ type: TokenSchema }],
	isAutoLock: { type: Boolean, default: false },
	status: { type: String, default: "" },
	roleList: [{ type: RoleSchema }],
	roleNames: [{ type: String }],
	availableRooms: [{ type: AvailableRoomSchema }],
	avaiableRoomsId: [{ type: String }],
	avaiableRoomsName: [{ type: String }],
	avaiableRoomsNameSummary: [{ type: String }],

	maxAdvanceBookingDay: { type: Number, default: 0 },
	maximumBookingMinutes: { type: Number, default: 0 },
	maxBookingPerDay: { type: Number, default: 0 },

	imageId: { type: String, default: "" },

	syncInfo: { type: O365SyncInfoSchema, default: null },
	syncADInfo: { type: ADSyncInfoSchema, default: null },

	//Update User
	imageFile: { type: Object, default: null },
}, { _id: false })

setBaseSchema(UserSchema, "user")

UserSchema.virtual('fullName').get(function () {
	return this.firstName + " " + this.lastName
})

UserSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data.id
			this.employeeCode = data.employeeCode
			this.username = data.userName
			this.email = data.email
			this.firstName = data.firstName
			this.lastName = data.lastName
			this.mobile = data.mobile
			if (typeof data.isAdmin === "boolean") {
				this.isAdmin = data.isAdmin
			}
			if (typeof data.isIntegration === "boolean") {
				this.isIntegration = data.isIntegration
			}
			this.rfId = data.rfId
			this.pinCode = data.pinCode
			if (Array.isArray(data.userGroupsId)) {
				this.userGroupsId = data.userGroupsId
			}
			if (Array.isArray(data.favoriteRoom)) {
				this.favoriteRoom = data.favoriteRoom
			}
			if (typeof data.isActive === "boolean") {
				this.isActive = data.isActive
			}
			this.timeZone = data.timeZone
			if (typeof data.isFirstLogin === "boolean") {
				this.isFirstLogin = data.isFirstLogin
			}
			this.lastLoginTime = moment(data.lastLoginTime)
			if (Array.isArray(data.token)) {
				this.token = data.token.map(t => {
					let service = ""
						, data = null
					if (t.service) {
						service = t.service.toUpperCase()
					}
					if (t.data) {
						data = t.data
					}
					return {
						id: t._id,
						email: t.email,
						service: service,
						data: data
					}
				})
			}
			if (typeof data.isAutoLock === "boolean") {
				this.isAutoLock = data.isAutoLock
			}
			this.status = data.status
			if (Array.isArray(data.roleList)) {
				this.roleList = data.roleList.map(role => {
					let isDefault = false
					if (typeof role.isDefault === "boolean") {
						isDefault = role.isDefault
					}
					let roleModel = new RoleModel()
					roleModel.setData(role.role)
					return {
						roleId: role.roleId,
						isDefault: isDefault,
						role: roleModel,
					}
				})
			}
			if (Array.isArray(data.roleName)) {
				this.roleNames = data.roleName
			}
			if (Array.isArray(data.availableRooms)) {
				this.availableRooms = data.availableRooms
			}
			if (Array.isArray(data.avaiableRoomsId)) {
				this.avaiableRoomsId = data.avaiableRoomsId
			}
			if (Array.isArray(data.avaiableRoomsName)) {
				this.avaiableRoomsName = data.avaiableRoomsName
			}
			if (Array.isArray(data.avaiableRoomsNameSummary)) {
				this.avaiableRoomsNameSummary = data.avaiableRoomsNameSummary
			}
			if (!(isNaN(data.maxAdvanceBookingDay))) {
				this.maxAdvanceBookingDay = data.maxAdvanceBookingDay
			}
			if (!(isNaN(data.maximumBookingMinutes))) {
				this.maximumBookingMinutes = data.maximumBookingMinutes
			}
			if (!(isNaN(data.maxBookingPerDay))) {
				this.maxBookingPerDay = data.maxBookingPerDay
			}
			this.imageId = data.imageId
			if (!!data.syncInfo) {
				this.syncInfo = data.syncInfo
			}
			if (!!data.syncADInfo) {
				this.syncADInfo = data.syncADInfo
			}
		}
	} catch (error) {

	}
}

UserSchema.methods.setDataInUserGroup = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.email = data.email
			this.firstName = data.firstName
			this.lastName = data.lastName
			this.mobile = data.mobilePhone
			
		}
	} catch (error) {

	}
}

UserSchema.methods.serializeKookie = function () {
	try {
		let result = {
			id: this.id,
			email: this.email,
			employeeCode: this.employeeCode,
			firstName: this.firstName,
			lastName: this.lastName,
			fullName: this.fullName,
			status: this.status,
			timeZone: this.timeZone,
		}

		return result
	} catch (error) {
		return null
	}
}

UserSchema.methods.serializeUpdateUser = function () {
	try {
		let result = {
			employeeCode: this.employeeCode,
			firstName: this.firstName,
			lastName: this.lastName,
		}

		if (this.imageFile instanceof FileModel) {
			result = {
				...result,
				...this.imageFile.serializeAPI()
			}
		}

		return result
	} catch (error) {
		return null
	}
}

const UserModel = mongoose.model("user", UserSchema, "user")

export default UserModel