
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewCreateEvent from '../../views/event/ViewCreateEvent'
import RoomManager from '../../business/RoomManager'
import SettingManager from '../../business/SettingManager'
import EventManager from '../../business/EventManager'
import UserManager from '../../business/UserManager'
import UserGroupManager from '../../business/UserGroupManager'

class CreateEventController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
		this.bRoom = new RoomManager(this)
		this.bEvent = new EventManager(this)
		this.bUser = new UserManager(this)
		this.bUserGroup = new UserGroupManager(this)
	}

	onGetSetting = () => {
		return this.bSetting.getFrontendSetting()
	}

	onGetConferenceList = () => {
		return this.bSetting.getConferenceList()
	}

	onGetRoomInfo = (email) => {
		return this.bRoom.getRoomInfo(email)
	}

	onGetEventType = () => {
		return this.bEvent.getEventType()
	}

	onGetEventRoomFormat = () => {
		return this.bEvent.getEventRoomFormat()
	}

	onCreateEvent = (userEmail, body) => {
		return this.bEvent.createEvent(userEmail, body)
	}

	onGetUserMailSystem = (textSearch) => {
		return this.bUser.getUserMailSystem(textSearch)
	}

	onGetUserGroupList = (textSearch) => {
		return this.bUserGroup.getUserGroupList(textSearch)
	}
	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewCreateEvent
					onGetRoomInfo={this.onGetRoomInfo}
					onGetSetting={this.onGetSetting}
					onGetConferenceList={this.onGetConferenceList}
					onGetEventType={this.onGetEventType}
					onGetEventRoomFormat={this.onGetEventRoomFormat}
					onCreateEvent={this.onCreateEvent}
					onGetUserMailSystem={this.onGetUserMailSystem}
					onGetUserGroupList={this.onGetUserGroupList}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(CreateEventController)