
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const FacilitySchema = new Schema({
	id: { type: String, default: "" },
	name: { type: String, default: "" },
	amount: { type: Number, default: 0 },
	imageId: { type: String, default: "" },
	isSystem: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(FacilitySchema, "facility")

FacilitySchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			if (!isNaN(data.amount)) {
				this.amount = data.amount
			}
			this.imageId = data.imageId
			if (typeof data.isSystem === "boolean") {
				this.isSystem = data.isSystem
			}
		}
	} catch (error) {

	}
}


const FacilityModel = mongoose.model("facility", FacilitySchema, "facility")

export default FacilityModel