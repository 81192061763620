
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const BuildingSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },

	availableAmenity: [{ type: Object }],
	availableFood: [{ type: Object }],

	imageId: { type: String, default: "" },
}, { _id: false })

setBaseSchema(BuildingSchema, "building")

BuildingSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			this.code = data.code
			if (Array.isArray(data.availableAmenity)) {
				this.availableAmenity = data.availableAmenity.map(f => f)
			}
			if (Array.isArray(data.availableFood)) {
				this.availableFood = data.availableFood.map(f => f)
			}
		}
	} catch (error) {

	}
}

BuildingSchema.methods.serializeOption = function () {
	try {
		return {
			key: this.id,
			value: this.id,
			text: this.name,
		}
	} catch (error) {
		return null
	}
}

const BuildingModel = mongoose.model("building", BuildingSchema, "building")

export default BuildingModel