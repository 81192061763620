
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Grid } from 'semantic-ui-react'
// import Slider from 'react-slick'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import ItemRoom from '../room/ItemRoom'
import CreateUpdateEventForm from './CreateUpdateEventForm'
import moment from 'moment'
import RoomModel from '../../models/RoomModel'
import CreateUpdateEventModel from '../../models/CreateUpdateEventModel'
import FrontendSettingModel from '../../models/FrontendSettingModel'
import { conferenceTypeCode, cookiesKey, defaultValue, eventTypeCreate } from '../../constant'
import ModalCreateEventSuccess from './ModalCreateUpdateEventSuccess'

class ViewCreateEvent extends MyPage {
    constructor(props) {
        super(props)
        let queryRoomEmails = []
            , startDateTime = moment(this.getQueryFromProps("start"))
            , endDateTime = moment(moment(this.getQueryFromProps("end")))
        if (this.getQueryFromProps("roomEmails")) {
            queryRoomEmails = this.getQueryFromProps("roomEmails").split(",")
        }
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false
        this.state.startDateTime = startDateTime
        this.state.endDateTime = endDateTime
        this.state.queryRoomEmails = queryRoomEmails

        this.state.setting = null
        this.state.conferences = []
        this.state.rooms = []
        this.state.eventTypes = []
        this.state.eventRoomFormats = []
        this.state.model = new CreateUpdateEventModel({
            startDateTime,
            endDateTime,
            eventRoomFormatId: defaultValue.NONE_SELECT
        })
        this.state.modelErrors = []
        this.state.isOpenModalCreateSuccess = false
        this.state.responseEvent = null

        this.state.usersMailSystem = []
        this.state.userGroups = []
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { queryRoomEmails, model } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                setting: null,
                conferences: [],
                model: model,
                rooms: [],
                eventTypes: [],
                eventRoomFormats: [],
                usersMailSystem: [],
                userGroups: [],
            },
                action = () => {

                }
            let [
                resSetting,
                resConference,
                resEventType,
                resRoomFormat,
                resUserMailSystem,
                resUserGroupList,
            ] = await Promise.all([
                this.props.onGetSetting(),
                this.props.onGetConferenceList(),
                this.props.onGetEventType(),
                this.props.onGetEventRoomFormat(),
                this.props.onGetUserMailSystem(""),
                this.props.onGetUserGroupList(),
            ])
            var responseRooms = await Promise.all(queryRoomEmails.map(email => {
                return this.props.onGetRoomInfo(email)
            }))

            if (!!resSetting && resSetting.data instanceof FrontendSettingModel) {
                tempState.setting = resSetting.data
                tempState.model.isEnableEventType = resSetting.data.isEnableEventType
            }
            if (!!resConference && Array.isArray(resConference.datas)) {
                tempState.conferences = resConference.datas
                if (resConference.datas.length > 0) {
                    let defaultConference = resConference.datas.find(c => c.value === conferenceTypeCode.NONE)
                    if (!!defaultConference) {
                        tempState.model.conferenceType = defaultConference.value
                    }
                }
            }
            if (!!resEventType && Array.isArray(resEventType.datas)) {
                tempState.eventTypes = resEventType.datas
            }
            if (!!resRoomFormat && Array.isArray(resRoomFormat.datas)) {
                tempState.eventRoomFormats = resRoomFormat.datas
            }

            if (!!resUserMailSystem && !!resUserMailSystem.result === true && Array.isArray(resUserMailSystem.datas)) {
                tempState.usersMailSystem = resUserMailSystem.datas
            }
            if (!!resUserGroupList && !!resUserGroupList.result === true && Array.isArray(resUserGroupList.datas)) {
                tempState.userGroups = resUserGroupList.datas
            }

            let rooms = []
            if (Array.isArray(responseRooms)) {
                responseRooms.forEach(resRoom => {
                    if (!!resRoom && resRoom.data instanceof RoomModel) {
                        rooms.push(resRoom.data)
                    }
                });
            }
            tempState.rooms = rooms
            let createEventModel = new CreateUpdateEventModel(model)
            createEventModel.rooms = rooms
            if (tempState.eventTypes.length > 0) {
                let findMeeting = tempState.eventTypes.find(e => e.code === eventTypeCreate.MEETING)
                if (!!findMeeting) {
                    createEventModel.eventTypeId = findMeeting.id
                } else {
                    createEventModel.eventTypeId = tempState.eventTypes[0].id
                }
            }
            tempState.model = createEventModel
            this.setState(tempState, action)
        })
    }

    onCreateEvent = () => {
        let { user, model, setting } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                responseEvent: null,
            },
                action = () => { }
            let res = await this.props.onCreateEvent(user.email, model.serializeAPI(user, setting))
            if (!!res && !!res.data) {
                tempState.responseEvent = res.data
                tempState.isOpenModalCreateSuccess = true
            }
            this.setState(tempState, action)
        })
    }

    onClickView = () => {
        const { responseEvent } = this.state
        this.props.history.replace(`/event/view?eventId=${encodeURIComponent(responseEvent.eventId)}`)
    }

    onClickMyReservation = () => {
        this.props.history.replace(`/event/booking`)
    }

    render() {
        const { t } = this.props
        const { rooms, model, eventTypes, eventRoomFormats, isOpenModalCreateSuccess, responseEvent, isLoading,
            usersMailSystem, userGroups, modelErrors, setting, conferences } = this.state

        let optionsEventType = eventTypes.map(d => d.serializeOption())
            , optionsEventRoomFormat = eventRoomFormats.map(d => d.serializeOption())
            , optionsConference = conferences.map(d => d.serializeOption())
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {
                    isOpenModalCreateSuccess &&
                    <ModalCreateEventSuccess
                        model={responseEvent}
                        onClickView={this.onClickView}
                        onClickMyReservation={this.onClickMyReservation}
                    />
                }
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewCreateEvent">
                        <Container text>
                            <div className="create-event-header">
                                <h1>{t("event.bookingDetails")}</h1>
                            </div>
                            <div className="create-event-body">
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <div className="location-box">
                                                    <p><b>{t("common.location")}</b></p>
                                                    <div className="room-list">
                                                        {/* <Slider {...settings}> */}
                                                        {
                                                            rooms.map((room, i) => {
                                                                return <div className="item-room" key={i}>
                                                                    <ItemRoom
                                                                        type={"horizontal"}
                                                                        model={room}
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                        {/* </Slider> */}
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <CreateUpdateEventForm
                                                    usersMailSystem={usersMailSystem}
                                                    userGroups={userGroups}
                                                    optionsEventType={optionsEventType}
                                                    optionsEventRoomFormat={optionsEventRoomFormat}
                                                    optionsConference={optionsConference}
                                                    model={model}
                                                    errors={modelErrors}
                                                    setting={setting}
                                                    handleChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Column width={16} textAlign="center">
                                            <div className="box-action">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.ok"),
                                                        color: "blue",
                                                        onClick: (e) => this.onValidate(this.onCreateEvent)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.cancel"),
                                                        color: "grey",
                                                    })}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewCreateEvent.defaultProps = {
    onGetSetting: () => {
        console.error("Please provide Get Setting action.")
        return null
    },
    onGetConferenceList: () => {
        console.error("Please provide Get Conference action.")
        return null
    },
    onGetRoomInfo: () => {
        console.error("Please provide Get Room Info action.")
        return null
    },
    onGetEventType: () => {
        console.error("Please provide Get Event Type action.")
        return null
    },
    onGetEventRoomFormat: () => {
        console.error("Please provide Get Room Format action.")
        return null
    },
    onCreateEvent: () => {
        console.error("Please provide Create action.")
        return null
    },
    onGetUserMailSystem: () => {
        console.error("Please provide Get User Mail System action.")
        return null
    },
    onGetUserGroupList: () => {
        console.error("Please provide Get User Group action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewCreateEvent)