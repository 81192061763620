
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Divider, Form, Grid, Icon, Sticky } from 'semantic-ui-react'


import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

// import ModalModel from '../../core/model/ModalModel'
import ButtonModel from '../../core/model/ButtonModel'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import ViewButton from '../../core/view/common/ViewButton'
import LabelInput from '../../core/view/common/LabelInput'
import ItemCriteriaSearchRoom from './ItemCriteriaSearchRoom'
import moment from 'moment'
import utils from '../../core/utility/utils'
import FrontendSettingModel from '../../models/FrontendSettingModel'
import InputTimePicker from '../../core/view/common/InputTimePicker'
import { dateTimeFormat } from '../../constant'
import ModalAddSearchLocation from './ModalAddSearchLocation'
import SuggestionModel from '../../models/SuggestionModel'
import BoxLoadingComponent from '../common/BoxLoadingComponent'
import ItemRoom from './ItemRoom'
import ModalRoomSelected from './ModalRoomSelected'
import ViewErrorInput from '../common/ViewErrorInput'

const renderType = {
    SEARCH: "SEARCH",
    RESULT: "RESULT",
}

class ViewSearchRoom extends MyPage {
    constructor(props) {
        super(props)
        this.state.viewRender = renderType.SEARCH
        this.state.isLoading = false
        this.state.isLoadingRoom = false
        this.state.setting = null

        this.state.startDateTime = moment()
        this.state.endDateTime = moment()

        this.state.buildings = []
        this.state.zones = []
        this.state.floors = []
        this.state.facilities = []

        this.state.isOpenModalAddLocation = false
        this.state.updateLocationModel = null

        this.state.locations = []
        this.state.searchResult = null

        this.state.selectRooms = []
        this.state.isOpenModalRoomSelected = false
    }

    contextRef = React.createRef()

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                setting: null,
            },
                action = () => {

                }

            let [resSetting, resSuggestInformation] = await Promise.all([
                this.props.onGetSetting(),
                this.props.onGetSuggestInformation(),
            ])
            // let resSetting = await this.props.onGetSetting()
            if (!!resSetting && resSetting.data instanceof FrontendSettingModel) {
                tempState.setting = resSetting.data
                tempState.startDateTime = utils.getStartDateTimeByStep(moment(), resSetting.data.slotDuration)
                tempState.endDateTime = moment(tempState.startDateTime).add(resSetting.data.slotDuration, "m")
            }
            if (!!resSuggestInformation && !!resSuggestInformation.result === true && !!resSuggestInformation.data) {
                const { buildings, zones, floors, facilities } = resSuggestInformation.data
                if (Array.isArray(buildings)) {
                    tempState.buildings = buildings
                }
                if (Array.isArray(zones)) {
                    tempState.zones = zones
                }
                if (Array.isArray(floors)) {
                    tempState.floors = floors
                }
                if (Array.isArray(facilities)) {
                    tempState.facilities = facilities
                }
            }
            this.setState(tempState, action)
        })
    }

    onSearch = () => {
        const { startDateTime, endDateTime, locations } = this.state
        this.setState({
            viewRender: renderType.RESULT,
            isLoadingRoom: true,
            searchResult: null,
        }, async () => {
            let tempState = {
                isLoadingRoom: false,
                searchResult: null,
            }
            let body = {
                start: this.convertDateTime(startDateTime).format(dateTimeFormat.apiFullDateTime),
                end: this.convertDateTime(endDateTime).format(dateTimeFormat.apiFullDateTime),
            }
            if (locations.length > 0) {
                body.location = locations.map((model, index) => {
                    return model.serializeAPI(index + 1)
                })
            }
            let res = await this.props.onGetRoomSuggestion(body)
            if (!!res && res.data instanceof SuggestionModel) {
                tempState.searchResult = res.data
            }
            this.setState(tempState)
        })
    }

    handleChangeDate = (e, { name, value }) => {
        const { startDateTime, endDateTime } = this.state
        let tempState = {}
        if (name === "startDateTime") {
            tempState[name] = moment(moment(value).format("YYYY-MM-DD") + moment(startDateTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
        } else if (name === "endDateTime") {
            tempState[name] = moment(moment(value).format("YYYY-MM-DD") + moment(endDateTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
        }
        this.setState(tempState)
    }

    handleChangeTime = (e, { name, value }) => {
        const { startDateTime, endDateTime } = this.state
        let tempState = {}
        if (name === "startDateTime") {
            tempState[name] = moment(moment(startDateTime).format("YYYY-MM-DD") + moment(value).format("HH:mm"), "YYYY-MM-DDHH:mm")
        } else if (name === "endDateTime") {
            tempState[name] = moment(moment(endDateTime).format("YYYY-MM-DD") + moment(value).format("HH:mm"), "YYYY-MM-DDHH:mm")
        }
        this.setState(tempState)
    }

    onOpenModalAddLocation = (model) => {
        this.setState({
            isOpenModalAddLocation: true,
            updateLocationModel: model,
        })
    }
    onSubmitModalAddLocation = (model) => {
        const { updateLocationModel, locations } = this.state
        let tempLocations = locations.map(l => l)
        let indexUpdate = locations.indexOf(updateLocationModel)
        if (indexUpdate > -1) {
            tempLocations[indexUpdate] = model
        } else {
            tempLocations.push(model)
        }
        this.setState({
            locations: tempLocations,
            isOpenModalAddLocation: false,
            updateLocationModel: null,
        })
    }
    onCloseModalAddLocation = () => {
        this.setState({
            isOpenModalAddLocation: false,
            updateLocationModel: null,
        })
    }
    onDeleteLocation = (model) => {
        let locations = this.state.locations
        let index = locations.indexOf(model)
        if (index > -1) {
            locations.splice(index, 1)
        }
        this.setState({
            locations: locations
        })
    }

    onClickEditSearchCriteria = () => {
        this.setState({
            viewRender: renderType.SEARCH,
            searchResult: null,
            selectRooms: [],
        })
    }

    onSelectRoom = (room) => {
        let selectRooms = this.state.selectRooms
            , indexSelect = selectRooms.indexOf(room)
        let tempState = {}
            , action = () => { }
        if (indexSelect > -1) {
            selectRooms.splice(indexSelect, 1)
        } else {
            selectRooms.push(room)
        }
        if (selectRooms.length === 0) {
            action = this.onCloseModalSelectedRooms
        }
        tempState.selectRooms = selectRooms
        this.setState(tempState, action)
    }
    onRemoveRoomAll = () => {
        this.setState({
            selectRooms: []
        })
    }

    onOpenModalSelectedRooms = () => {
        this.setState({
            isOpenModalRoomSelected: true,
        })
    }
    onCloseModalSelectedRooms = () => {
        this.setState({
            isOpenModalRoomSelected: false
        })
    }

    onClickBook = () => {
        const { startDateTime, endDateTime, selectRooms } = this.state
        let roomEmails = selectRooms.map(room => encodeURIComponent(room.email))
            , start = moment(startDateTime).format(dateTimeFormat.apiFullDateTime)
            , end = moment(endDateTime).format(dateTimeFormat.apiFullDateTime)
        let url = `/event/create?roomEmails=${roomEmails.join(",")}&start=${start}&end=${end}`
        this.props.history.push(url)
    }
    render() {
        const { t } = this.props
        const {
            viewRender, isLoading, isLoadingRoom, setting,
            startDateTime, endDateTime,
            isOpenModalAddLocation, updateLocationModel, buildings, zones, floors, facilities, locations,
            searchResult, selectRooms, isOpenModalRoomSelected
        } = this.state

        let errorSelectDateTime = null
        let selectStart = moment(startDateTime)
            , selectEnd = moment(endDateTime)
        if (selectEnd.isSameOrBefore(selectStart)) {
            errorSelectDateTime = t("message.endTimeGreaterThanStartTime")
        }

        let minuteStep = null
        if (!!setting) {
            minuteStep = setting.slotDuration
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {
                    isOpenModalRoomSelected &&
                    <ModalRoomSelected
                        rooms={selectRooms}
                        onRemove={this.onSelectRoom}
                        onRemoveAll={this.onRemoveRoomAll}
                        onClose={this.onCloseModalSelectedRooms}
                    />
                }
                {
                    isOpenModalAddLocation &&
                    <ModalAddSearchLocation
                        buildings={buildings}
                        zones={zones}
                        floors={floors}
                        facilities={facilities}
                        model={updateLocationModel}
                        onClose={this.onCloseModalAddLocation}
                        onSubmit={this.onSubmitModalAddLocation}
                    />
                }
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewSearchRoom">
                        {
                            viewRender === renderType.SEARCH &&
                            <div className="view-search-wrapper">
                                <Container text>
                                    <div className="vsw-header">
                                        <h1>{t("common.search")}</h1>
                                    </div>
                                    <Form>
                                        <Grid >
                                            {/* <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox label={t("common.allDay")} />
                                        </Grid.Column>
                                    </Grid.Row> */}
                                            <Grid.Row>
                                                <Grid.Column width={9}>
                                                    <LabelInput text={t("common.startDate")} isRequire={false} />
                                                    <InputDatePicker
                                                        name="startDateTime"
                                                        value={startDateTime}
                                                        onChange={this.handleChangeDate}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={7}>
                                                    <LabelInput text={t("common.time")} isRequire={false} />
                                                    <InputTimePicker
                                                        name="startDateTime"
                                                        value={startDateTime}
                                                        minuteStep={minuteStep}
                                                        onChange={this.handleChangeTime}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={9}>
                                                    <LabelInput text={t("common.endDate")} isRequire={false} />
                                                    <InputDatePicker
                                                        name="endDateTime"
                                                        value={endDateTime}
                                                        onChange={this.handleChangeDate}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={7}>
                                                    <LabelInput text={t("common.time")} isRequire={false} />
                                                    <InputTimePicker
                                                        name="endDateTime"
                                                        value={endDateTime}
                                                        minuteStep={minuteStep}
                                                        onChange={this.handleChangeTime}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {
                                                !!errorSelectDateTime && minuteStep > 0 &&
                                                <Grid.Row>
                                                    <Grid.Column textAlign="center">
                                                        <ViewErrorInput error={errorSelectDateTime} />
                                                    </Grid.Column>
                                                </Grid.Row>

                                            }
                                            <Grid.Row>
                                                <Grid.Column textAlign="center">
                                                    <ViewButton model={new ButtonModel({
                                                        color: "blue",
                                                        text: t("common.search"),
                                                        disabled: !!errorSelectDateTime && minuteStep > 0,
                                                        onClick: this.onSearch
                                                    })} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Divider />
                                        <Grid >
                                            <Grid.Row verticalAlign="middle">
                                                <Grid.Column width={8}>
                                                    <p><b>{t("common.location")}</b></p>
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right">
                                                    {/* <ViewButton model={new ButtonModel({
                                                color: "blue",
                                                text: t("room.clickToSpecifyCondition")
                                            })} /> */}
                                                    <div className="box-add-location">
                                                        <p onClick={this.onOpenModalAddLocation}>{t("room.clickToSpecifyCondition")}</p>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <div className="list-location">
                                            {
                                                locations.map((location, i) => {
                                                    return <ItemCriteriaSearchRoom
                                                        key={i}
                                                        model={location}
                                                        onDelete={this.onDeleteLocation}
                                                        onEdit={this.onOpenModalAddLocation}
                                                    />
                                                })
                                            }
                                        </div>
                                    </Form>

                                </Container>
                            </div>
                        }
                        {
                            viewRender === renderType.RESULT &&
                            <div className="view-result-wrapper" ref={this.contextRef}>
                                <Container>
                                    <div className="result-tab-header">
                                        <div className="tab-text-header">
                                            <h3>{t("common.searchResult")}</h3>
                                        </div>
                                        <div className="tab-date-time">
                                            <div className="tab-date-time-wrapper">
                                                <div className="dt-text">
                                                    {
                                                        startDateTime.isSame(endDateTime, "d") ?
                                                            <React.Fragment>
                                                                <p>{moment(startDateTime).format(dateTimeFormat.displayDate)} {moment(startDateTime).format(dateTimeFormat.displayTime)} - {moment(endDateTime).format(dateTimeFormat.displayTime)}</p>
                                                            </React.Fragment>
                                                            : <React.Fragment>
                                                                <p>{moment(startDateTime).format(dateTimeFormat.displayFullDateTime)}</p>
                                                                <p>{moment(endDateTime).format(dateTimeFormat.displayFullDateTime)}</p>
                                                            </React.Fragment>
                                                    }
                                                </div>
                                                <div className={`dt-action ${isLoadingRoom ? "disabled" : ""}`}>
                                                    <div className={`dt-action-wrapper`} onClick={this.onClickEditSearchCriteria}>
                                                        <p>{t("common.edit")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-cart">
                                        <Sticky
                                            context={this.contextRef}
                                            active={selectRooms.length > 0}
                                            className="sticky-tab-cart"
                                        >
                                            <div className="tab-cart-wrapper">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t("room.yourSelectedRooms")}: ${selectRooms.length}`,
                                                        iconClass: "angle down",
                                                        color: "teal",
                                                        disabled: selectRooms.length === 0,
                                                        externalProps: {
                                                            labelPosition: "right"
                                                        },
                                                        onClick: this.onOpenModalSelectedRooms,
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("event.book"),
                                                        color: "blue",
                                                        disabled: selectRooms.length === 0,
                                                        onClick: this.onClickBook,
                                                    })}
                                                />

                                            </div>
                                        </Sticky>
                                    </div>
                                    <div className="result-divider">
                                        <Divider />
                                    </div>
                                    <div className="result-room-list">
                                        {
                                            isLoadingRoom ?
                                                <div>
                                                    <BoxLoadingComponent />
                                                </div>
                                                : <div className="result-room-list-wrapper">
                                                    {
                                                        (!!searchResult && Array.isArray(searchResult.result)) &&
                                                        searchResult.result.map((location, i) => {
                                                            return <div key={i} className="item-location">
                                                                <div className="item-location-header">
                                                                    <div className="header-text">
                                                                        <p>{`${i + 1}. ${location.groupName}`}</p>
                                                                    </div>
                                                                    <div className="header-action">
                                                                        <Icon className="angle down" />
                                                                    </div>
                                                                </div>
                                                                <div className="item-room-list">
                                                                    {
                                                                        location.roomResults.map((room, iR) => {
                                                                            let indexSelect = selectRooms.indexOf(room)
                                                                                , isSelect = indexSelect > -1
                                                                            return <ItemRoom
                                                                                key={iR}
                                                                                model={room}
                                                                                buttons={[
                                                                                    new ButtonModel({
                                                                                        color: isSelect ? "red" : "blue",
                                                                                        text: isSelect ? t("common.remove") : t("common.select"),
                                                                                        // disabled: isSelect,
                                                                                        onClick: () => this.onSelectRoom(room)
                                                                                    })
                                                                                ]}
                                                                            />
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                        }
                                    </div>
                                </Container>
                            </div>
                        }
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewSearchRoom.defaultProps = {
    onGetSetting: () => {
        console.error("Please provide Get Setting action")
        return null
    },
    onGetSuggestInformation: () => {
        console.error("Please provide Get Suggest Information action")
        return null
    },
    onGetRoomSuggestion: () => {
        console.error("Please provide Get Room Suggest action")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewSearchRoom)