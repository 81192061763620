import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ModalAttendee from '../../views/event/ModalAttendee'
import UserManager from '../../business/UserManager'
import UserGroupManager from '../../business/UserGroupManager'

class ModalAttendeeController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
		this.bUserGroup = new UserGroupManager(this)
	}

	onGetUserMailSystem = (textSearch) => {
		return this.bUser.getUserMailSystem(textSearch)
	}

	onGetUserGroupList = (textSearch) => {
		return this.bUserGroup.getUserGroupList(textSearch)
	}
	render() {
		const { textHeader, onSubmit, onClose, usersMailSystem, userGroups, attendees } = this.props
		return (
			<React.Fragment>
				{super.render()}
				<ModalAttendee
					textHeader={textHeader}
					usersMailSystem={usersMailSystem}
					userGroups={userGroups}
					attendees={attendees}
					onSubmit={onSubmit}
					onClose={onClose}
					onGetUserMailSystem={this.onGetUserMailSystem}
					onGetUserGroupList={this.onGetUserGroupList}
				/>
			</React.Fragment>
		)
	}
}

ModalAttendeeController.defaultProps = {
	textHeader: "textHeader",
	usersMailSystem: [],
	userGroups: [],
	attendees: [],
	onSubmit: () => console.error("Please provide Submit action."),
	onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(ModalAttendeeController)