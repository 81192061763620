
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import BuildingModel from './BuildingModel'

const Schema = mongoose.Schema

const FloorSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },

	availableAmenity: [{ type: Object }],
	availableFood: [{ type: Object }],

	buildingId: { type: String, default: "" },
	building: { type: Object, default: null },

	imageId: { type: String, default: "" },
}, { _id: false })

setBaseSchema(FloorSchema, "floor")

FloorSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			this.code = data.code
			this.buildingId = data.buildingId
			if (Array.isArray(data.availableAmenity)) {
				this.availableAmenity = data.availableAmenity.map(f => f)
			}
			if (Array.isArray(data.availableFood)) {
				this.availableFood = data.availableFood.map(f => f)
			}
			if (!!data.building) {
				let buildingModel = new BuildingModel()
				buildingModel.setData(data.building)
				this.building = buildingModel
			}
		}
	} catch (error) {

	}
}

FloorSchema.methods.serializeOption = function () {
	try {
		return {
			key: this.id,
			value: this.id,
			text: this.name,
		}
	} catch (error) {
		return null
	}
}

const FloorModel = mongoose.model("floor", FloorSchema, "floor")

export default FloorModel