
import moment from 'moment'
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import RoomModel from './RoomModel'
import RoomFormatModel from './RoomFormatModel'
import EventTypeModel from './EventTypeModel'
import { attendeeResponseStatus } from '../constant'
import EventRecurrenceModel from './EventRecurrenceModel'

const Schema = mongoose.Schema

const BodySchema = new Schema({
	contentType: { type: String, default: "" },
	content: { type: String, default: "" },
}, { _id: false })

const RoomEventSchema = new Schema({
	type: { type: String, default: "" },
	statusResponse: { type: String, default: "" },
	statusTime: { type: Date, default: null },
	emailAddressName: { type: String, default: "" },
	emailAddressAddress: { type: String, default: "" },
	room: { type: Object, default: null }
}, { _id: false })

const AttendeeSchema = new Schema({
	type: { type: String, default: "" },
	statusResponse: { type: String, default: "" },
	statusTime: { type: Date, default: null },
	emailAddressName: { type: String, default: "" },
	emailAddressAddress: { type: String, default: "" },
}, { _id: false })

const EventSchema = new Schema({
	seriesMasterId: { type: String, default: "" },
	eventId: { type: String, default: "" },
	iCalUId: { type: String, default: "" },
	subject: { type: String, default: "" },
	startDateTime: { type: Date, default: moment() },
	endDateTime: { type: Date, default: moment() },
	timeZone: { type: String, default: "Asia/Bangkok" },
	duration: { type: Number, default: 0 },
	rooms: [{ type: RoomEventSchema }],
	organizerName: { type: String, default: "" },
	organizerAddress: { type: String, default: "" },
	location: { type: String, default: "" },
	body: { type: BodySchema, default: {} },
	isAllDay: { type: Boolean, default: false },
	sensitivity: { type: String, default: "" },

	isShowCheckinButton: { type: Boolean, default: false },
	isShowCheckoutButton: { type: Boolean, default: false },
	isShowCancelButton: { type: Boolean, default: false },

	canCheckin: { type: Boolean, default: false },
	canCheckout: { type: Boolean, default: false },
	canCancel: { type: Boolean, default: false },

	isCancelled: { type: Boolean, default: false },
	isCheckin: { type: Boolean, default: false },

	type: { type: String, default: "" },
	user: { type: String, default: "" },
	eventType: { type: String, default: "" },

	conferenceType: { type: String, default: "" },
	conferenceTypeName: { type: String, default: "" },
	tmsPrivacy: { type: Boolean, default: false },
	tmsPassword: { type: String, default: "" },

	eventRoomFormatId: { type: String, default: "" },
	eventRoomFormat: { type: Object },

	eventTypeId: { type: String, default: "" },
	eventTypeInfo: { type: Object },

	attendees: [{ type: AttendeeSchema }],

	recurrence: { type: Object, default: null },
}, { _id: false })

setBaseSchema(EventSchema, "event")

EventSchema.virtual('isRecurrence').get(function () {
	let isRecurrence = false
	if (!!this.type && this.type.toLowerCase() !== "singleinstance") {
		isRecurrence = true
	}
	return isRecurrence
})

EventSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.seriesMasterId = data.seriesMasterId
			this.eventId = data.eventId
			this.iCalUId = data.iCalUId
			this.subject = data.subject
			this.startDateTime = moment(data.startDateTime)
			this.endDateTime = moment(data.endDateTime)
			this.timeZone = data.timeZone
			if (!isNaN(data.duration)) {
				this.duration = data.duration
			}
			if (Array.isArray(data.rooms)) {
				let rooms = []
				data.rooms.forEach(roomResponse => {
					let roomModel = new RoomModel()
					roomModel.setData(roomResponse)

					let statusResponse = ""
						, statusTime = null
						, emailAddressName = ""
						, emailAddressAddress = ""
					if (!!roomResponse.status) {
						statusResponse = !!roomResponse.status.response ? roomResponse.status.response.toUpperCase() : ""
						statusTime = roomResponse.status.time
					}
					if (!!roomResponse.emailAddress) {
						emailAddressName = roomResponse.emailAddress.name
						emailAddressAddress = roomResponse.emailAddress.address
					}
					let model = {
						statusResponse,
						statusTime,
						emailAddressName,
						emailAddressAddress,
						type: roomResponse.type,
						room: roomModel
					}
					rooms.push(model)
				})
				this.rooms = rooms
			}
			this.organizerName = data.organizerName
			this.organizerAddress = data.organizerAddress
			this.location = data.location
			let body = {
				contentType: "",
				content: ""
			}
			if (!!data.body) {
				body.content = data.body.content
				body.contentType = data.body.contentType
			}
			this.body = body
			if (typeof data.isAllDay === "boolean") {
				this.isAllDay = data.isAllDay
			}
			this.sensitivity = data.sensitivity
			if (typeof data.isShowCheckinButton === "boolean") {
				this.isShowCheckinButton = data.isShowCheckinButton
			}
			if (typeof data.isShowCheckoutButton === "boolean") {
				this.isShowCheckoutButton = data.isShowCheckoutButton
			}
			if (typeof data.isShowCancelButton === "boolean") {
				this.isShowCancelButton = data.isShowCancelButton
			}
			if (typeof data.canCheckin === "boolean") {
				this.canCheckin = data.canCheckin
			}
			if (typeof data.canCheckout === "boolean") {
				this.canCheckout = data.canCheckout
			}
			if (typeof data.canCancel === "boolean") {
				this.canCancel = data.canCancel
			}
			if (typeof data.isCancelled === "boolean") {
				this.isCancelled = data.isCancelled
			}
			if (typeof data.isCheckin === "boolean") {
				this.isCheckin = data.isCheckin
			}
			this.type = data.type
			this.user = data.user
			if (!!data.eventType) {
				this.eventType = data.eventType.toUpperCase()
			}

			if (!!data.conferenceType) {
				this.conferenceType = data.conferenceType
			}
			if (!!data.conferenceTypeName) {
				this.conferenceTypeName = data.conferenceTypeName
			}
			if (typeof data.tmsPrivacy === "boolean") {
				this.tmsPrivacy = data.tmsPrivacy
			}
			this.tmsPassword = data.tmsPassword

			if (!!data.eventRoomFormatId) {
				if (typeof data.eventRoomFormatId === "string") {
					this.eventRoomFormatId = data.eventRoomFormatId
				} else {
					let model = new RoomFormatModel()
					model.setData(data.eventRoomFormatId)
					this.eventRoomFormatId = model.id
					this.eventRoomFormat = model
				}
			}
			if (!!data.eventTypeId) {
				if (typeof data.eventTypeId === "string") {
					this.eventTypeId = data.eventTypeId
				} else {
					let model = new EventTypeModel()
					model.setData(data.eventTypeId)
					this.eventTypeId = model.id
					this.eventTypeInfo = model
				}
			}

			if (Array.isArray(data.attendees)) {
				let attendees = []
				data.attendees.forEach(att => {
					const { type, status, emailAddress } = att
					let obj = {
						type: type,
						statusResponse: "",
						statusTime: null,
						emailAddressName: "",
						emailAddressAddress: "",
					}
					if (!!status) {
						obj.statusResponse = status.response.toUpperCase()
						obj.statusTime = moment(status.time)
					}
					if (!!emailAddress) {
						obj.emailAddressName = emailAddress.name
						obj.emailAddressAddress = emailAddress.address
					}
					attendees.push(obj)
				});
				this.attendees = attendees
			}

			if (!!data.recurrence) {
				let model = new EventRecurrenceModel()
				model.setData(data.recurrence)
				this.recurrence = model
			}
		}
	} catch (error) {

	}
}

EventSchema.methods.isOrganizer = function (email) {
	try {
		if (!email || typeof email != "string") {
			throw new Error("Email not found")
		}
		return this.organizerAddress.toLowerCase() === email.toLowerCase()
	} catch (error) {
		return false
	}
}

EventSchema.methods.getTimeInComing = function (dateTime) {
	try {
		let result = {
			day: 0,
			hour: 0,
			minute: 0,
			isNow: false,
			isEnd: false,
		}
		let startDateTime = moment(this.startDateTime)
			, endDateTime = moment(this.endDateTime)
			, currentDateTime = moment(dateTime)

		if (endDateTime.isSameOrBefore(currentDateTime)) {
			result.isEnd = true
		} else if (startDateTime.isSameOrBefore(currentDateTime)) {
			result.isNow = true
		} else {
			result.day = startDateTime.diff(moment(currentDateTime).startOf("d"), "d")
			let tempStartDateTime = moment(this.startDateTime)
			if (this.isAllDay) {
				tempStartDateTime = tempStartDateTime.startOf("d")
			}
			const duration = moment(tempStartDateTime).diff(currentDateTime, "m")
			result.hour = Math.floor(duration / 60)
			result.minute = duration % 60
		}

		return result
	} catch (error) {
		return null
	}
}

EventSchema.methods.serializeCheckIn = function (userEmail, room) {
	try {
		let result = {
			iCalUId: this.iCalUId,
			email: userEmail,
			room_email: room.email,
			room_name: room.name
		}
		return result
	} catch (error) {
		return null
	}
}

EventSchema.methods.serializeCheckInAll = function (userEmail) {
	try {
		let result = {
			iCalUId: this.iCalUId,
			email: userEmail,
			room_email: userEmail,
			room_name: userEmail
		}
		return result
	} catch (error) {
		return null
	}
}

EventSchema.methods.getAttendeesByStatus = function (status) {
	try {
		let results = this.attendees
		if (!!status) {
			if (status === attendeeResponseStatus.TENTATIVE || status === attendeeResponseStatus.TENTATIVELYACCEPTED) {
				results = this.attendees.filter(att => att.statusResponse === attendeeResponseStatus.TENTATIVE || att.statusResponse === attendeeResponseStatus.TENTATIVELYACCEPTED)
			} else {
				results = this.attendees.filter(att => att.statusResponse === status)
			}
		}
		return results
	} catch (error) {
		return []
	}
}

const EventModel = mongoose.model("event", EventSchema, "event")

export default EventModel