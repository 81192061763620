
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { Container } from 'semantic-ui-react'
import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
// import Footer from './Footer'
// import Header from './Header'

class ViewHeader extends MyPage {
    onClickMenu = (path) => {
        this.props.history.push(path)
    }
    render() {
        try {
            const { t } = this.props
            return (
                <div className="module viewFooter">
                    <Container >
                        <div className={`vh-body`}>
                            <div className="menu">
                                <div className="a-item" onClick={() => this.onClickMenu(`/`)}>
                                    <div className="menu-item">
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-booking.png`} alt="Booking" />
                                        </div>
                                        <p>{t("event.booking")}</p>
                                    </div>
                                </div>
                                <div className="a-item">
                                    <div className="menu-item" onClick={() => this.onClickMenu(`/room/search`)}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-search.png`} alt="Room list" />
                                        </div>
                                        <p>{t("common.search")}</p>
                                    </div>
                                </div>
                                <div className="a-item">
                                    <div className="menu-item" onClick={() => this.onClickMenu(`/room/list`)}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-roomlist.png`} alt="Room list" />
                                        </div>
                                        <p>{t("room.roomList")}</p>
                                    </div>
                                </div>
                                <div className="a-item">
                                    <div className="menu-item" onClick={() => this.onClickMenu(`/event/booking`)}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-myreservation.png`} alt="My reservation" />
                                        </div>
                                        <p>{t("event.myBookings")}</p>
                                    </div>
                                </div>
                                {/* <a href="/menu">
                                    <div className="menu-item">
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-menu.png`} alt="Menu" />
                                        </div>
                                        <p>{t("menu.menu")}</p>
                                    </div>
                                </a> */}
                            </div>
                        </div>
                    </Container>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ViewHeader.defaultProps = {
    header: null
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewHeader)