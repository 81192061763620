import common from './th/common.json'
import auth from './th/auth.json'
import event from './th/event.json'
import user from './th/user.json'
import room from './th/room.json'
import order from './th/order.json'
import location from './th/location.json'
import message from './th/message.json'
import validate from './th/validate.json'
import date from './th/date.json'

const obj = {
    ...common,
    ...auth,
    ...event,
    ...user,
    ...room,
    ...order,
    ...location,
    ...message,
    ...validate,
    ...date,
}

export default obj