
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { Popup } from 'semantic-ui-react'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import config from '../../config'
import { cookiesKey } from '../../constant'
import { CORE_API_ENDPOINT } from '../../lib'
import ViewButton from '../../core/view/common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'

class MenuPopup extends MyPage {
    onLogout = () => {
        this.bCookie.removeAll()
        this.props.history.replace("/")
    }
    onClickMenu = (path) => {
        this.props.history.push(path)
    }
    render() {
        try {
            const { t, trigger } = this.props
            let user = this.bCookie.getData(cookiesKey.USER)
            let fullName = "-"
                , email = "-"
                , userId = ""
            if (!!user) {
                fullName = user.fullName
                email = user.email
                userId = user.id
            }
            if (!trigger) {
                return null
            }
            return (
                <React.Fragment>
                    <Popup
                        className="popup-blank"
                        position='bottom right'
                        on='click'
                        pinned
                        trigger={trigger}
                        content={<div className={`module menuPopup`}>
                            <div className="menu-popup-wrapper">
                                <div className="menu-header">
                                    <h2>{t("common.menu")}</h2>
                                </div>

                                <div className="menu-body">
                                    <div className="menu-user">
                                        <div className="user-box" onClick={(e) => this.onClickMenu(`/user/information`)}>
                                            <img src={`${CORE_API_ENDPOINT}/image/user/${userId}`} alt="User" />
                                            <div className="user-info">
                                                <p className="text-header">{fullName}</p>
                                                <p>{email}</p>
                                                <div className="user-action">
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("user.profile"),
                                                            color: "blue",
                                                            size: "small",
                                                            onClick: (e) => this.onClickMenu(`/user/information`)
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="menu-list">
                                        <div className="menu-list-box">

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/home.png`} alt={t("common.home")} />
                                                    <p>{t("common.home")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/user/myqrcode")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/qr-code.png`} alt={t("common.myQRCode")} />
                                                    <p>{t("common.myQRCode")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/scanqrcode")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/scan-qr-code.png`} alt={t("common.scanQRCode")} />
                                                    <p>{t("common.scanQRCode")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/terms")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/terms.png`} alt={t("common.termsAndConditions")} />
                                                    <p>{t("common.termsAndConditions")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/faq")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/faq.png`} alt={t("common.faq")} />
                                                    <p>{t("common.faq")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/contact")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/contact.png`} alt={t("common.contactUs")} />
                                                    <p>{t("common.contactUs")}</p>
                                                </div>
                                            </div>

                                            <div className="item-menu">
                                                <div className="item-box" onClick={() => this.onClickMenu("/user/integration")}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/integration.png`} alt={t("user.userIntegration")} />
                                                    <p>{t("user.userIntegration")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="menu-logout">
                                        <div className="menu-logout-box">
                                            <div className="item-menu">
                                                <div className="item-box" onClick={this.onLogout}>
                                                    <img src={`${config.publicUrl}/assets/images/menu/exit.png`} alt={t("common.logout")} />
                                                    <p>{t("common.logout")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


MenuPopup.defaultProps = {
    trigger: null
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(MenuPopup)