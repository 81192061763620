
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import UserManager from '../../business/UserManager'
import ModalModel from '../../core/model/ModalModel'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../../core/view/common/LabelInput'
import { Form } from 'semantic-ui-react'
import ViewErrorInput from '../../views/common/ViewErrorInput'
import validate from '../../core/utility/validate'

class ModalForgotPasswordController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.state.email = ""
		this.state.errors = [] // path,message

		this.bUser = new UserManager(this)
	}

	handleChange = (e, { name, value }) => {
		this.setState({
			email: value
		})
	}

	onValidate = () => {
		const { email } = this.state
		let errors = []
		if (!validate.isEmail(email)) {
			errors.push({
				path: "email",
				message: "validate.emailInvalid"
			})
		}
		this.setState({
			errors: errors
		}, () => {
			if (errors.length === 0) {
				this.onSubmit(email)
			}
		})
	}

	onSubmit = (email) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false
			},
				action = () => { }
			let res = await this.bUser.resetPassword(email, `${window.location.origin}/resetpassword`)
			if (!!res && res.result === true) {
				action = this.props.onSubmit
			}
			this.setState(tempState, action)
		})
	}

	render() {
		const { t, onClose } = this.props
		const { isLoading, email, errors } = this.state

		let emailError = this.getErrorInput("email", errors)
		return (
			<React.Fragment>
				{super.render()}
				{isLoading && this.getLoadingPage()}
				{this.getModalContent(new ModalModel({
					headerText: t("user.forgotPassword"),
					content: <div className="module viewModalForgotPassword">
						<div className="forgot-password-wrapper">
							<Form>
								<LabelInput text={t("common.email")} />
								<Form.Input
									name="email"
									value={email}
									onChange={this.handleChange}
									placeholder={t("message.pleaseEnterYourEmail")}
								/>
								{
									emailError.isError &&
									<ViewErrorInput error={emailError.content} />
								}
							</Form>
						</div>
					</div>,
					buttons: [
						new ButtonModel({
							text: t("common.ok"),
							color: "blue",
							disabled: !email,
							onClick: this.onValidate
						}),
						new ButtonModel({
							text: t("common.close"),
							color: "grey",
							onClick: onClose
						})
					]
				}))}
			</React.Fragment>
		)
	}
}

ModalForgotPasswordController.defaultProps = {
	onSubmit: () => console.error("Please provide Submit action."),
	onClose: () => console.error("Please provide Close action.")
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(ModalForgotPasswordController)