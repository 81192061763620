import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

class ViewLoading extends React.Component {

    render() {
        const { isActive, isPage, message, size, color } = this.props
        if (!isActive) {
            return null
        }
        let tempColor = "tertiary"
        if (!!color) {
            tempColor = color
        }
        return (
            <React.Fragment>
                <div className="module viewLoading">
                    <div className={`view-loading-wrapper ${tempColor}`}>
                        {
                            isPage ?
                                <Dimmer active page>
                                    <Loader className="loading-page" size={size}>{message}</Loader>
                                </Dimmer>
                                :
                                <Loader size={size} active inline>{message}</Loader>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ViewLoading.defaultProps = {
    isActive: true,
    isPage: true,
    message: "",
    size: "big",
    color: "",
}


export default ViewLoading