
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const EventTypeSchema = new Schema({
	id: { type: String, default: "" },
	name: { type: String, default: "" },
	code: { type: String, default: "" },
	description: { type: String, default: "" },
	isDelete: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(EventTypeSchema, "event_type")

EventTypeSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			this.code = data.code
			this.description = data.description
			if (typeof data.isDelete === "boolean") {
				this.isDelete = data.isDelete
			}
		}
	} catch (error) {

	}
}

EventTypeSchema.methods.serializeOption = function () {
	try {
		let result = {
			key: this.id,
			text: this.name,
			value: this.id,
		}

		return result
	} catch (error) {
		return null
	}
}


const EventTypeModel = mongoose.model("event_type", EventTypeSchema, "event_type")

export default EventTypeModel