
import moment from 'moment'
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import RoomModel from './RoomModel'

const Schema = mongoose.Schema

const TimeSchema = new Schema({
	start: { type: Date, default: moment() },
	end: { type: Date, default: moment() },
}, { _id: false })


const CriteriaSchema = new Schema({
	buildingId: { type: String, default: "" },
	floorId: { type: String, default: "" },
	zoneId: { type: String, default: "" },
	capacity: { type: Number, default: null },
	facilities: [{ type: String }],
}, { _id: false })

const LocationSchema = new Schema({
	groupName: { type: String, default: "" },
	keyword: { type: Number, default: null },
	roomResult: { type: Boolean, default: false },
	criteria: { type: CriteriaSchema, default: {} },
	roomResults: [{ type: Object }],
	suggestedRooms: [{ type: Object }],
}, { _id: false })

const SuggestionSchema = new Schema({
	time: { type: TimeSchema, default: {} },
	result: [{ type: LocationSchema }],
}, { _id: false })

setBaseSchema(SuggestionSchema, "suggestion")

SuggestionSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			let time = data.time
				, result = Array.isArray(data.result) ? data.result : []
			let tempTime = {
				start: moment(),
				end: moment()
			}
			if (!!time) {
				tempTime.start = moment(time.start)
				tempTime.end = moment(time.end)
			}
			this.time = tempTime

			let tempResult = []
			if (result.length > 0) {
				result.forEach(r => {
					let tempLocation = {
						groupName: r.groupName,
						keyword: r.keyword,
						roomResult: false,
						criteria: {
							...r.criteria
						},
						roomResults: [],
						suggestedRooms: [],
					}
					if (typeof r.roomResult === "boolean") {
						tempLocation.roomResult = r.roomResult
					}
					if (Array.isArray(r.roomResults)) {
						tempLocation.roomResults = r.roomResults.map(room => {
							let roomModel = new RoomModel()
							roomModel.setDataSuggest(room)
							return roomModel
						})
					}
					if (Array.isArray(r.suggestedRooms)) {
						tempLocation.suggestedRooms = r.suggestedRooms.map(room => {
							let roomModel = new RoomModel()
							roomModel.setDataSuggest(room)
							return roomModel
						})
					}
					tempResult.push(tempLocation)
				});
			}
			this.result = tempResult
		}
	} catch (error) {

	}
}

SuggestionSchema.methods.getRoomResultByKeyword = function (keyword) {
	try {
		let result = []
		let findResult = this.result.find(r => r.keyword === keyword)
		if (!!findResult) {
			if (findResult.roomResults.length > 0) {
				result = findResult.roomResults
			}
		}
		return result
	} catch (error) {
		return []
	}
}


const SuggestionModel = mongoose.model("suggestion", SuggestionSchema, "suggestion")

export default SuggestionModel