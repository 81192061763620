
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class AuthManager extends MyManager {
	async verifyLocal(body) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/verify`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async verifyO365(body, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/web/o365/verify`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

	async verifyGoogle(body, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/web/google/verify`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

	async verifyExchange2010(body, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/web/ex2010/verify`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

	async verifyExchange2013(body, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/web/ex2013/verify`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

}