// SCG
// export const CORE_API_ENDPOINT = 'https://10.0.30.11:7022/core'
// export const O365_API_ENDPOINT = 'https://10.0.30.11:7022/o365'
// export const CORE_API_ENDPOINT = 'https://front25o365.rmbainisys.com/core'
// export const O365_API_ENDPOINT = 'https://front25o365.rmbainisys.com/o365'

//Vajira
// export const CORE_API_ENDPOINT = 'https://booking.vajira.ac.th/core'
// export const O365_API_ENDPOINT = 'https://booking.vajira.ac.th/o365'

// export const CORE_API_ENDPOINT = 'http://10.0.30.10:8011/core'
// export const O365_API_ENDPOINT = 'http://10.0.30.10:8011/o365'


// export const CORE_API_ENDPOINT = 'https://frontbns.rmbainisys.com/core'
// export const O365_API_ENDPOINT = 'https://frontbns.rmbainisys.com/o365'

// Build prod
export const CORE_API_ENDPOINT = '/core'
export const O365_API_ENDPOINT = '/o365'

export const MIN_EXPIRE_TOKEN = 5 //min