
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import FrontendIntegrationSettingModel from '../models/FrontendIntegrationSettingModel'
import FrontendSettingModel from '../models/FrontendSettingModel'
import BuildingModel from '../models/BuildingModel'
import FacilityModel from '../models/FacilityModel'
import FloorModel from '../models/FloorModel'
import ZoneModel from '../models/ZoneModel'
import TermsModel from '../models/TermsModel'
import ContactUsModel from '../models/ContactUsModel'
import FAQModel from '../models/FAQModel'
import ConferenceModel from '../models/ConferenceModel'

export default class SettingManager extends MyManager {
	async getO365Setting() {
		try {
			const options = {
				useToken: false,
				isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/o365/frontend/info`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new FrontendIntegrationSettingModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}
	
	async getFrontendSetting() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/setting/front`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new FrontendSettingModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getSuggestInformation() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/setting/suggestinformation`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let tempData = {
					buildings: [],
					floors: [],
					zones: [],
					facilities: []
				}
				const { building, floor, zone, facilities } = res.data
				if (Array.isArray(building)) {
					let buildings = building.map(b => {
						let model = new BuildingModel()
						model.setData(b)
						return model
					})
					tempData.buildings = buildings
				}
				if (Array.isArray(floor)) {
					let floors = floor.map(f => {
						let model = new FloorModel()
						model.setData(f)
						return model
					})
					tempData.floors = floors
				}
				if (Array.isArray(zone)) {
					let zones = zone.map(z => {
						let model = new ZoneModel()
						model.setData(z)
						return model
					})
					tempData.zones = zones
				}
				if (Array.isArray(facilities)) {
					let facilitiesList = facilities.map(f => {
						let model = new FacilityModel()
						model.setData(f)
						return model
					})
					tempData.facilities = facilitiesList
				}
				res.data = tempData
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getTerms() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rminfo/terms`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new TermsModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getContactUs() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rminfo/contactus`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new ContactUsModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getFAQ() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rminfo/faq`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new FAQModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getConferenceList() {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/setting/conference/list`,
			}

			let res = await this.requestAction(options)
			if (!!res && !!res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(c => {
					let model = new ConferenceModel()
					model.setData(c)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}
}