
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewChangeUserInformation from '../../views/user/ViewChangeUserInformation'
import UserManager from '../../business/UserManager'

class ChangeUserInformationController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
	}

	onGetUserInfo = (id) => {
		return this.bUser.getUserInfo(id)
	}

	onUpdateUser = (userId, body) => {
		return this.bUser.updateUser(userId, body)
	}

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewChangeUserInformation
					onGetUserInfo={this.onGetUserInfo}
					onUpdateUser={this.onUpdateUser}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(ChangeUserInformationController)