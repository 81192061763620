
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../../core/view/common/LabelInput'
import ChangePasswordModel from '../../models/ChangePasswordModel'
import ViewErrorInput from '../common/ViewErrorInput'

class ChangePasswordForm extends MyForm {


    render() {
        const { t, model, errors } = this.props
        let oldPasswordError = this.getErrorInput("oldPassword", errors)
            , newPasswordError = this.getErrorInput("newPassword", errors)
            , confirmNewPasswordError = this.getErrorInput("confirmNewPassword", errors)
        return (
            <React.Fragment>
                {super.render()}
                <div className="module changePasswordForm">
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.currentPassword")} isRequire={true} />
                                    <Form.Input
                                        type="password"
                                        name={"oldPassword"}
                                        value={model.oldPassword}
                                        fluid
                                        onChange={this.handleChange}
                                        error={oldPasswordError.isError}
                                    />
                                    {
                                        oldPasswordError.isError &&
                                        <ViewErrorInput error={oldPasswordError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.newPassword")} isRequire={true} />
                                    <Form.Input
                                        type="password"
                                        name={"newPassword"}
                                        value={model.newPassword}
                                        fluid
                                        onChange={this.handleChange}
                                        error={newPasswordError.isError}
                                    />
                                    {
                                        newPasswordError.isError &&
                                        <ViewErrorInput error={newPasswordError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.confirmNewPassword")} isRequire={true} />
                                    <Form.Input
                                        type="password"
                                        name={"confirmNewPassword"}
                                        value={model.confirmNewPassword}
                                        fluid
                                        onChange={this.handleChange}
                                        error={confirmNewPasswordError.isError}
                                    />
                                    {
                                        confirmNewPasswordError.isError &&
                                        <ViewErrorInput error={confirmNewPasswordError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}

ChangePasswordForm.defaultProps = {
    model: new ChangePasswordModel(),
    errors: [],
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ChangePasswordForm)