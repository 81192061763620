
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewRoomInfo from '../../views/room/ViewRoomInfo'
import SettingManager from '../../business/SettingManager'
import RoomManager from '../../business/RoomManager'
import UserManager from '../../business/UserManager'
import EventManager from '../../business/EventManager'

class SearchRoomController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
		this.bRoom = new RoomManager(this)
		this.bUser = new UserManager(this)
		this.bEvent = new EventManager(this)
	}

	onGetSetting = () => {
		return this.bSetting.getFrontendSetting()
	}

	onGetRoomStatus = (email) => {
		return this.bRoom.getRoomStatus(email)
	}

	onGetUserInfo = (id) => {
		return this.bUser.getUserInfo(id)
	}

	onGetCalendarSlot = (roomEmail, dateStr) => {
		return this.bEvent.getCalendarSlot(roomEmail, dateStr)
	}

	onGetCanBooking = (roomId) => {
		return this.bRoom.canBooking(roomId)
	}

	onCheckIn = (body) => {
		return this.bEvent.checkIn(body)
	}

	onCheckOut = (userEmail, roomEmail, iCalUId) => {
		return this.bEvent.checkOut(userEmail, roomEmail, iCalUId)
	}
	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewRoomInfo
					onGetSetting={this.onGetSetting}
					onGetRoomStatus={this.onGetRoomStatus}
					onGetUserInfo={this.onGetUserInfo}
					onGetCalendarSlot={this.onGetCalendarSlot}
					onGetCanBooking={this.onGetCanBooking}

					onCheckIn={this.onCheckIn}
					onCheckOut={this.onCheckOut}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(SearchRoomController)