import mongoose from 'mongoose'
import { setBaseSchema } from '../../schema/Schema'

const Schema = mongoose.Schema

const FileSchema = new Schema({
    fileName: { type: String, default: null },
    size: { type: Number, default: null },
    extension: { type: String, default: null },
    mimeType: { type: String, default: null },
    content: { type: String, default: null },
}, { _id: false })

setBaseSchema(FileSchema, "file")

FileSchema.methods.serializeAPI = function () {
    try {
        let result = {
            fileName: this.fileName,
            size: this.size,
            extension: this.extension,
            type: this.mimeType,
            content: this.content,
        }

        return result
    } catch (error) {
        return null
    }
}

export default FileSchema