
import React from 'react'
import { translate } from 'react-switch-lang'
import utils from '../../utility/utils'

class ContentErrorModal extends React.Component {

    render() {
        const { t, errors } = this.props
        let datas = []
            // , code = ""
        if (Array.isArray(errors)) {
            let token = utils.makeGroupedObject(errors, "code")
            for (let code in token) {
                datas.push({
                    code,
                    messages: token[code].map(t => t.message)
                })
            }
        }

        if (datas.length === 0) {
            return null
        }

        return (
            <div className="module contentErrorModal">
                <div className="box-content">
                    {
                        datas.length > 1 ?
                            datas.map((e, i) => {
                                return <div key={i}>
                                    <p><b>- </b>{e.messages.join(", ")}</p>
                                </div>
                            })
                            : <div>
                                <p>{datas[0].messages.join(", ")}</p>
                            </div>
                    }
                </div>
                <div className="box-code">
                    <p>{t("common.code")}: {datas.map(d => d.code).join(", ")}</p>
                </div>
            </div>
        )
    }
}

export default translate(ContentErrorModal)