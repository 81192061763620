const errorMessage = {
    // FRONT FF
    // Common YY00XX YY=00
    "FF000001": "validate.require",

    // Home YY00XX YY=01
    // User YY00XX YY=02 00=User, 01=Integration 
    // Room YY00XX YY=03 00=Room List, 01=Room Info, 03=Search Room
    // Event YY00XX YY=04 00=Create&Update, 01=Event Info, 03=My Booking

    // API
    "00002211": "message.usernameOrPasswordIncorrect"
}

export default errorMessage