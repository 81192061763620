const validate = {
    isEmail(email) {
        try {
            if (!email) {
                throw new Error("Email not found")
            }
            var pattern = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/);
            return pattern.test(email)
        } catch (error) {
            return false
        }
    },
}

export default validate