
import PrivateRoute from '../policy/PrivateRoute'
import PublicRoute from '../policy/PublicRoute'

import HomeController from '../controllers/home/HomeController'
import MenuController from '../controllers/home/MenuController'

// import LoginController from '../controllers/auth/LoginController'

// import TermsController from '../controllers/global/TermsController'
// import FAQController from '../controllers/global/FAQController'
// import ContactController from '../controllers/global/ContactController'

// import SearchRoomController from '../controllers/room/SearchRoomController'
// import RoomListController from '../controllers/room/RoomListController'

// import MyReservationController from '../controllers/event/MyReservationController'
// import CreateEventController from '../controllers/event/CreateEventController'

let routeConfigs = [
    // { route: PublicRoute, path: "/event/booking", component: MyReservationController },
    // { route: PublicRoute, path: "/event/create", component: CreateEventController },

    // { route: PublicRoute, path: "/searchroom", component: SearchRoomController },
    // { route: PublicRoute, path: "/roomlist", component: RoomListController },

    // { route: PublicRoute, path: "/contact", component: ContactController },
    // { route: PublicRoute, path: "/faq", component: FAQController },
    // { route: PublicRoute, path: "/terms", component: TermsController },

    { route: PublicRoute, path: "/menu", component: MenuController },

    // { route: PublicRoute, path: "/authorize", component: LoginController },
    // { route: PublicRoute, path: "/login", component: LoginController },

    { route: PrivateRoute, path: "/", component: HomeController },
]

const RouteConfig = {
    module: "",
    routeConfigs,
}

export default RouteConfig