

// import React from 'react'
import MyComponent from './MyComponent'

export default class MyPage extends MyComponent {

    onValidate = async (callback) => {
        try {
            let model = this.state.model
            if (await model.validateError()) {
                this.setState({
                    modelErrors: []
                }, callback)
            } else {
                this.setState({
                    modelErrors: model.getErrors()
                })
            }
        } catch (error) {
            console.error("On Validate Error: " + error.message)
            return false
        }
    }

    handleChange = (e, { name, value }) => {
        try {
            let model = this.state.model
                , modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []

            let tempState = {}

            // if (model && name in model) {
            //     model[name] = value
            //     tempState.model = model
            // } else if (name in this.state) {
            //     tempState[name] = value
            // }
            if (model) {
                let names = name.split(".")
                if (names.length === 1) {
                    model[name] = value
                } else if (names.length > 1) {
                    this.changeDataModel(names, model, value)
                }
                tempState.model = model
            } else if (name in this.state) {
                tempState[name] = value
            }

            let index = modelErrors.findIndex(e => e.path === name)
            if (index > -1) {
                modelErrors.splice(index, 1);
                tempState.modelErrors = modelErrors
            }
            this.setState(tempState)

        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

}