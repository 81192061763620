
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ButtonModel from '../../core/model/ButtonModel'
import { Form, Grid } from 'semantic-ui-react'
import ModalModel from '../../core/model/ModalModel'
import LabelInput from '../../core/view/common/LabelInput'
import { defaultValue, recurrenceType, recurrenceText, recurrenceEndType, dayOfWeek, weekIndexKey, monthOfYear } from '../../constant'
import EventRecurrenceModel from '../../models/EventRecurrenceModel'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import validateInput from '../../core/utility/validateInput'
import ViewErrorInput from '../common/ViewErrorInput'

class ModalRecurrence extends MyPage {
    constructor(props) {
        super(props)
        let model = new EventRecurrenceModel()
        if (!!props.model && props.model instanceof EventRecurrenceModel) {
            model = new EventRecurrenceModel(props.model)
        }
        this.state.model = model
        this.state.modelErrors = []
        this.state.optionsPattern = this.getOptionPattern()
        this.state.optionsDayOnWeek = this.getOptionDayOnWeek()
        this.state.optionsWeekIndex = this.getOptionWeekIndex()
        this.state.optionsMonth = this.getOptionMonth()
    }

    getOptionPattern = () => {
        const { t } = this.props
        let options = [
            {
                key: defaultValue.PLEASE_SELECT,
                value: defaultValue.PLEASE_SELECT,
                text: `-- ${t("common.pleaseSelect")} --`
            }
        ]
        for (const key in recurrenceType) {
            let dataText = recurrenceText[recurrenceType[key]]
            options.push({
                key: recurrenceType[key],
                value: recurrenceType[key],
                text: !!dataText ? t(dataText.text) : ""
            })
        }

        return options
    }

    getOptionDayOnWeek = () => {
        const { t } = this.props
        let options = []
        for (const key in dayOfWeek) {
            options.push({
                key: dayOfWeek[key],
                value: dayOfWeek[key],
                text: t(`date.dayOfWeek.full.${dayOfWeek[key]}`)
            })
        }

        return options
    }

    getOptionWeekIndex = () => {
        const { t } = this.props
        let options = []
        for (const key in weekIndexKey) {
            options.push({
                key: weekIndexKey[key],
                value: weekIndexKey[key],
                text: t(`event.weekIndex.${weekIndexKey[key]}`)
            })
        }

        return options
    }

    getOptionMonth = () => {
        const { t } = this.props
        let options = []
        for (const key in monthOfYear) {
            options.push({
                key: monthOfYear[key],
                value: monthOfYear[key],
                text: t(`date.monthOfYear.full.${monthOfYear[key]}`)
            })
        }

        return options
    }

    handleChangeEndType = (e, { name, value, checked }) => {
        if (!!name && !!value && checked === true) {
            this.handleChange(e, { name: name, value: value })
        }
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.model)
    }
    render() {
        try {
            const { t, onClose } = this.props
            const { model, modelErrors, optionsPattern, optionsDayOnWeek, optionsWeekIndex, optionsMonth } = this.state
            let textEvery = ""
            if (!!model) {
                if (!!model.type && model.type !== defaultValue.PLEASE_SELECT) {
                    if (model.type === recurrenceType.DAILY) {
                        textEvery = t("event.everyday")
                    } else if (model.type === recurrenceType.WEEKLY) {
                        textEvery = t("event.everyWeek")
                    } else if (model.type === recurrenceType.ABSOLUTE_MONTHLY) {
                        textEvery = t("event.everyMonth")
                    } else if (model.type === recurrenceType.RELATIVE_MONTHLY) {
                        textEvery = t("event.everyMonth")
                    } else if (model.type === recurrenceType.ABSOLUTE_YEARLY) {

                    }
                }
            }

            let typeError = this.getErrorInput("type", modelErrors)
                , endDateError = this.getErrorInput("endDate", modelErrors)
                , numberedError = this.getErrorInput("numbered", modelErrors)
                , dayError = this.getErrorInput("day", modelErrors)
                , everyError = this.getErrorInput("every", modelErrors)
                , weekIndexError = this.getErrorInput("weekIndex", modelErrors)
                , dayOnWeekError = this.getErrorInput("dayOnWeek", modelErrors)
                , monthError = this.getErrorInput("month", modelErrors)
            return (
                <React.Fragment>
                    {this.getModalContent(new ModalModel({
                        externalProps: {
                            scrolling: false
                        },
                        headerText: t("event.repeat"),
                        size: "tiny",
                        content: <div className="module modalRecurrence">
                            <div className="modal-recurrence">
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("event.pattern")} />
                                                <Form.Dropdown
                                                    selection
                                                    options={optionsPattern}
                                                    name="type"
                                                    value={model.type}
                                                    onChange={this.handleChange}
                                                    error={typeError.isError}
                                                />
                                                {
                                                    typeError.isError &&
                                                    <ViewErrorInput error={typeError.content} />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        {
                                            (!!model.type && model.type !== defaultValue.PLEASE_SELECT) &&
                                            <React.Fragment>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t("common.from")} />
                                                        <InputDatePicker
                                                            name="startDate"
                                                            value={model.startDate}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Form.Checkbox
                                                            type="radio"
                                                            name="endType"
                                                            value={recurrenceEndType.END_DATE}
                                                            checked={model.endType === recurrenceEndType.END_DATE}
                                                            label={t("common.to")}
                                                            onChange={this.handleChangeEndType}
                                                        />
                                                        <InputDatePicker
                                                            name="endDate"
                                                            value={model.endDate}
                                                            onChange={this.handleChange}
                                                            disabled={model.endType !== recurrenceEndType.END_DATE}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Form.Checkbox
                                                            type="radio"
                                                            name="endType"
                                                            value={recurrenceEndType.NUMBERED}
                                                            checked={model.endType === recurrenceEndType.NUMBERED}
                                                            label={t("event.numberOccurrences")}
                                                            onChange={this.handleChangeEndType}
                                                        />
                                                        <Form.Input
                                                            selection
                                                            name="numbered"
                                                            value={model.numbered}
                                                            onChange={this.handleChange}
                                                            onKeyDown={validateInput.onKeyNumber}
                                                            disabled={model.endType !== recurrenceEndType.NUMBERED}
                                                        />
                                                    </Grid.Column>
                                                    {
                                                        endDateError.isError &&
                                                        <Grid.Column width={16} textAlign="left">
                                                            < ViewErrorInput error={endDateError.content} />
                                                        </Grid.Column>
                                                    }
                                                    {
                                                        numberedError.isError &&
                                                        <Grid.Column width={16} textAlign="right">
                                                            < ViewErrorInput error={numberedError.content} />
                                                        </Grid.Column>
                                                    }
                                                </Grid.Row>
                                                {
                                                    (model.type === recurrenceType.ABSOLUTE_MONTHLY || model.type === recurrenceType.ABSOLUTE_YEARLY) &&
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <LabelInput text={t("common.day")} />
                                                            <Form.Input
                                                                selection
                                                                name="day"
                                                                value={model.day}
                                                                onChange={this.handleChange}
                                                                onKeyDown={validateInput.onKeyNumber}
                                                                error={dayError.isError}
                                                            />
                                                            {
                                                                dayError.isError &&
                                                                <ViewErrorInput error={dayError.content} />
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                }
                                                {
                                                    (model.type !== recurrenceType.ABSOLUTE_YEARLY) &&
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <LabelInput text={textEvery} />
                                                            <Form.Input
                                                                selection
                                                                name="every"
                                                                value={model.every}
                                                                onChange={this.handleChange}
                                                                onKeyDown={validateInput.onKeyNumber}
                                                                error={everyError.isError}
                                                            />
                                                            {
                                                                everyError.isError &&
                                                                <ViewErrorInput error={everyError.content} />
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                }
                                                {
                                                    (model.type === recurrenceType.RELATIVE_MONTHLY) &&
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <LabelInput text={t("date.week")} />
                                                            <Form.Dropdown
                                                                selectOnBlur={false}
                                                                selection
                                                                options={optionsWeekIndex}
                                                                name="weekIndex"
                                                                value={model.weekIndex}
                                                                onChange={this.handleChange}
                                                                error={weekIndexError.isError}
                                                            />
                                                            {
                                                                weekIndexError.isError &&
                                                                <ViewErrorInput error={weekIndexError.content} />
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                }
                                                {
                                                    (model.type === recurrenceType.WEEKLY || model.type === recurrenceType.RELATIVE_MONTHLY) &&
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <LabelInput text={t("event.onDay")} />
                                                            <Form.Dropdown
                                                                selectOnBlur={false}
                                                                selection
                                                                options={optionsDayOnWeek}
                                                                name="dayOnWeek"
                                                                value={model.dayOnWeek}
                                                                onChange={this.handleChange}
                                                                error={dayOnWeekError.isError}
                                                            />
                                                            {
                                                                dayOnWeekError.isError &&
                                                                <ViewErrorInput error={dayOnWeekError.content} />
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                }
                                                {
                                                    (model.type === recurrenceType.ABSOLUTE_YEARLY) &&
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <LabelInput text={t("date.month")} />
                                                            <Form.Dropdown
                                                                selectOnBlur={false}
                                                                selection
                                                                options={optionsMonth}
                                                                name="month"
                                                                value={model.month}
                                                                onChange={this.handleChange}
                                                                error={monthError.isError}
                                                            />
                                                            {
                                                                monthError.isError &&
                                                                <ViewErrorInput error={monthError.content} />
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                }
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Form>
                            </div>
                        </div>,
                        buttons: [
                            new ButtonModel({
                                text: t("common.ok"),
                                color: "blue",
                                onClick: (e) => this.onValidate(this.onSubmit),
                                // disabled: !isSubmit
                            }),
                            new ButtonModel({
                                text: t("common.cancel"),
                                color: "grey",
                                onClick: onClose
                            })
                        ]
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ModalRecurrence.defaultProps = {
    model: null,
    // buildings: [],
    // floors: [],
    // zones: [],
    // facilities: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ModalRecurrence)