
import PrivateRoute from '../policy/PrivateRoute'
// import PublicRoute from '../policy/PublicRoute'

import UserIntegrationController from '../controllers/user/UserIntegrationController'

import ScanQRCodeController from '../controllers/global/ScanQRCodeController'
import TermsController from '../controllers/global/TermsController'
import FAQController from '../controllers/global/FAQController'
import ContactController from '../controllers/global/ContactController'

let routeConfigs = [
    { route: PrivateRoute, path: "/google/integration", component: UserIntegrationController },
    { route: PrivateRoute, path: "/integration", component: UserIntegrationController },

    { route: PrivateRoute, path: "/scanqrcode", component: ScanQRCodeController },

    { route: PrivateRoute, path: "/contact", component: ContactController },
    { route: PrivateRoute, path: "/faq", component: FAQController },
    { route: PrivateRoute, path: "/terms", component: TermsController },
]

const RouteConfig = {
    module: "",
    routeConfigs,
}

export default RouteConfig