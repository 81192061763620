
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { Popup } from 'semantic-ui-react'
import { translate, setLanguage, getLanguage } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
// import config from '../../config'
import { languageKey } from '../../constant'
// import { CORE_API_ENDPOINT } from '../../lib'

class LanguagePopup extends MyPage {

    onClickMenu = (langKey) => {
        setLanguage(langKey)
    }
    render() {
        try {
            const { t, trigger } = this.props
            let langKey = getLanguage()
            if (!trigger) {
                return null
            }
            return (
                <React.Fragment>
                    <Popup
                        className="popup-blank"
                        position='bottom right'
                        // on='click'
                        // pinned
                        flowing
                        hoverable
                        trigger={trigger}
                        content={<div className={`module languagePopup`}>
                            <div className="language-popup-wrapper">
                                <div className="menu-header">
                                    <h2>{t("common.language")}</h2>
                                </div>

                                <div className="menu-body">
                                    <div className="menu-list">
                                        <div className="menu-list-box">

                                            <div className={`item-menu ${langKey === languageKey.en ? "active" : ""}`}>
                                                <div className="item-box" onClick={() => this.onClickMenu(languageKey.en)}>
                                                    <p>English</p>
                                                </div>
                                            </div>

                                            <div className={`item-menu ${langKey === languageKey.th ? "active" : ""}`}>
                                                <div className="item-box" onClick={() => this.onClickMenu(languageKey.th)}>
                                                    <p>ภาษาไทย</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


LanguagePopup.defaultProps = {
    trigger: null
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(LanguagePopup)