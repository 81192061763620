
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../../core/view/common/LabelInput'
import LocalLoginModel from '../../models/LocalLoginModel'
import ViewErrorInput from '../common/ViewErrorInput'

class LocalLoginForm extends MyForm {
    render() {
        const { t, model, errors, isForgotPassword, onClickForgotPassword } = this.props

        let usernameError = this.getErrorInput("username", errors)
            , passwordError = this.getErrorInput("password", errors)
        return (
            <React.Fragment>
                {super.render()}
                <div className="module localLoginForm">
                    <Form>
                        <Grid>
                            <Grid.Column width={16}>
                                <LabelInput text={t("user.username")} isRequire={true} />
                                <Form.Input
                                    name={"username"}
                                    value={model.username}
                                    fluid
                                    onChange={this.handleChange}
                                    error={usernameError.isError}
                                />
                                {
                                    usernameError.isError &&
                                    <ViewErrorInput error={usernameError.content} />
                                }
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <LabelInput text={t("user.password")} isRequire={true} />
                                <Form.Input
                                    type="password"
                                    name={"password"}
                                    value={model.password}
                                    fluid
                                    onChange={this.handleChange}
                                    error={passwordError.isError}
                                />
                                {
                                    passwordError.isError &&
                                    <ViewErrorInput error={passwordError.content} />
                                }
                            </Grid.Column>
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column width={8}>
                                    <Form.Checkbox
                                        name={"isRemember"}
                                        value={model.isRemember}
                                        label={t("auth.remember")}
                                        onChange={this.handleChangeBoolean}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    {
                                        isForgotPassword === true &&
                                        <p className="text-forgot-password" onClick={onClickForgotPassword}>{t("user.forgotPassword")}</p>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}

LocalLoginForm.defaultProps = {
    model: new LocalLoginModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),

    isForgotPassword: false,
    onClickForgotPassword: () => console.error("Please provide Forgot Password action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(LocalLoginForm)