
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewMenu from '../../views/home/ViewMenu'

class MenuController extends MyController {

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewMenu />
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(MenuController)