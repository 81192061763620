const cookiesKey = {
	COOKIE_NAME: "rms_frontend",
	TARGET_URL: "TARGET_URL",
	IS_REMEMBER: "IS_REMEMBER",

	LANGUAGE: "language",

	TOKEN: "TOKEN",
	REFRESH_TOKEN: "REFRESH_TOKEN",
	REFRESH_TOKEN_EXPIRES_DATE: "REFRESH_TOKEN_EXPIRES_DATE",
	TOKEN_EXPIRES_DATE: "TOKEN_EXPIRES_DATE",
	TOKEN_EXPIRES_ON: "TOKEN_EXPIRES_ON",

	IS_INTEGRATION: "IS_INTEGRATION",
	SYSTEM_KEY: "SYSTEM_KEY",
	ROLE: "ROLE",
	USER: "USER",
}

const requestStatus = {
	REQUEST: "REQUEST",
	REQUEST_SUCCESS: "REQUEST_SUCCESS",
	REQUEST_FAIL: "REQUEST_FAILURE",
	NO_ACTION: "NO_ACTION",
}

const defaultValue = {
	PLEASE_SELECT: "PLEASE_SELECT",
	SELECT_ALL: "SELECT_ALL",
	NONE_SELECT: "NONE_SELECT"
}

const dateTimeFormat = {
	displayFullDateTime: "MMMM DD YYYY HH:mm",
	displayDate: "DD MMMM YYYY",
	displayTime: "HH:mm",

	apiFullDateTime: "YYYY-MM-DDTHH:mm:ss",
	apiDate: "YYYY-MM-DD",
	apiTime: "HH:mm",
}

const languageKey = {
	en: "en",
	th: "th"
}

const downloadFileType = {
	EXCEL: {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		filenameExtension: "xlsx"
	},
	PDF: {
		type: "application/pdf",
		filenameExtension: "pdf"
	}
}

const modalType = {
	INFO: "INFO",
	WARNING: "WARNING",
	ERROR: "ERROR",
}

const systemKey = {
	O365: "O365",
	GOOGLE: "GOOGLE",
	EXCHANGE2010: "EXCHANGE2010",
	EXCHANGE2013: "EXCHANGE2013"
}

const eventTypeCreate = {
	MEETING: "MEETING",
	TRAINING: "TRAINING",
}

const eventType = {
	EVENT: "EVENT",
	ROOMDISABLE: "ROOMDISABLE",
}

const editEventType = {
	RECURRENCE: "RECURRENCE",
	SINGLE: "SINGLE"
}

const attendeeType = {
	RESOURCE: "resource",
	REQUIRE: "required",
	OPTIONAL: "optional",
}

const attendeeResponseStatus = {
	NONE: "NONE",
	ACCEPTED: "ACCEPTED",
	TENTATIVE: "TENTATIVE",
	TENTATIVELYACCEPTED: "TENTATIVELYACCEPTED",
	DECLINED: "DECLINED"
}

const recurrenceType = {
	DAILY: "daily",
	WEEKLY: "weekly",
	ABSOLUTE_MONTHLY: "absoluteMonthly",
	RELATIVE_MONTHLY: "relativeMonthly",
	ABSOLUTE_YEARLY: "absoluteYearly",
}

const recurrenceText = {
	[recurrenceType.DAILY]: {
		text: "event.recurrence.daily",
		unit: "",
	},
	[recurrenceType.WEEKLY]: {
		text: "event.recurrence.weekly",
		unit: "",
	},
	[recurrenceType.ABSOLUTE_MONTHLY]: {
		text: "event.recurrence.sameDayMonth",
		unit: "",
	},
	[recurrenceType.RELATIVE_MONTHLY]: {
		text: "event.recurrence.sameWeekMonth",
		unit: "",
	},
	[recurrenceType.ABSOLUTE_YEARLY]: {
		text: "event.recurrence.sameDayYear",
		unit: "",
	},
}

const recurrenceEndType = {
	END_DATE: "endDate",
	NUMBERED: "numbered",
}

const weekIndexKey = {
	FIRST: "first",
	SECOND: "second",
	THIRD: "third",
	FOURTH: "fourth",
	LAST: "last",
}

const dayOfWeek = {
	SUNDAY: "sunday",
	MONDAY: "monday",
	TUESDAY: "tuesday",
	WEDNESDAY: "wednesday",
	THURSDAY: "thursday",
	FRIDAY: "friday",
	SATURDAY: "saturday",
}

const monthOfYear = {
	JANUARY: "january",
	FEBRUARY: "february",
	MARCH: "march",
	APRIL: "april",
	MAY: "may",
	JUNE: "june",
	JULY: "july",
	AUGUST: "august",
	SEPTEMBER: "september",
	OCTOBER: "october",
	NOVEMBER: "november",
	DECEMBER: "december",
}

const roomTypeCode = {
	VC: "VC",
	NON_VC: "NON_VC"
}

const conferenceTypeCode = {
	NONE: "NONE",
	MICROSOFT_TEAM: "MICROSOFT_TEAM",
	CISCO_TMS: "CISCO_TMS",
}

const colorCode = {
	SECONDARY: "secondary"
}
export {
	requestStatus,
	cookiesKey,
	dateTimeFormat,
	defaultValue,
	languageKey,
	downloadFileType,
	modalType,

	colorCode,

	systemKey,
	eventType,
	eventTypeCreate,
	editEventType,

	recurrenceType,
	recurrenceText,
	recurrenceEndType,
	weekIndexKey,
	dayOfWeek,
	monthOfYear,

	attendeeType,
	attendeeResponseStatus,

	roomTypeCode,
	conferenceTypeCode,
}