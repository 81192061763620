
import React from 'react'
import { t, translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import MyPage from '../../core/components/MyPage'

class ViewErrorInput extends MyPage {
    render() {
        const { error } = this.props
        if (!error) {
            return null
        }
        return (
            <React.Fragment>
                {super.render()}
                <div className={`module viewErrorInput`}>
                    <p>{t(error)}</p>
                </div>
            </React.Fragment>
        )
    }
}


ViewErrorInput.defaultProps = {
    error: "",
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewErrorInput)