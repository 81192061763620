
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ConferenceSchema = new Schema({
	text: { type: String, default: "" },
	value: { type: String, default: "" },
}, { _id: false })

setBaseSchema(ConferenceSchema, "conference")

ConferenceSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.text = data.text
			this.value = data.value
		}
	} catch (error) {

	}
}

ConferenceSchema.methods.serializeOption = function () {
	try {
		return {
			value: this.value,
			text: this.text,
			key: this.value,
		}
	} catch (error) {
		return null
	}
}

const ConferenceModel = mongoose.model("conference", ConferenceSchema, "conference")

export default ConferenceModel