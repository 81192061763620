
import { CORE_API_ENDPOINT, O365_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import SuggestionModel from '../models/SuggestionModel'
import RoomModel from '../models/RoomModel'
import RoomStatusModel from '../models/RoomStatusModel'

export default class SettingManager extends MyManager {
	async getRoomSuggestion(body) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/meeting/suggestion`,
				data: body
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new SuggestionModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getRoomList(textSearch) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/roominfo/search?text=${textSearch}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data && Array.isArray(res.data.RoomInfo)) {
				res.datas = res.data.RoomInfo.map(room => {
					let model = new RoomModel()
					model.setData(room)
					return model
				})
				delete res.data
			} else {
				res.datas = []
				delete res.data
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getRoomInfo(email) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/roominfo/room/${encodeURIComponent(email)}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data && !!res.data.RoomInfo) {
				let model = new RoomModel()
				model.setData(res.data.RoomInfo)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getRoomStatus(email) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/roominfo/withuser/status/${encodeURIComponent(email)}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new RoomStatusModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async canBooking(roomId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/roominfo/canBooking/${roomId}`,
			}

			let res = await this.requestAction(options)
			
			return res
		} catch (error) {
			return null
		}
	}
}