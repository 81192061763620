
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ButtonModel from '../../core/model/ButtonModel'
import { Form, Grid } from 'semantic-ui-react'
import ModalModel from '../../core/model/ModalModel'
import LabelInput from '../../core/view/common/LabelInput'
import { defaultValue } from '../../constant'
import SearchLocationModel from '../../models/SearchLocationModel'
import { CORE_API_ENDPOINT } from '../../lib'
import utils from '../../core/utility/utils'
import validateInput from '../../core/utility/validateInput'

class ModalAddSearchLocation extends MyPage {
    constructor(props) {
        super(props)
        let model = new SearchLocationModel()
        if (!!props.model && props.model instanceof SearchLocationModel) {
            model = new SearchLocationModel(props.model)
        }
        this.state.model = model
    }

    handleChangeFacility = (e, { name, value, checked }, facilities) => {
        let model = this.state.model
            , facilityIds = model.facilityIds.map(id => id)
            , index = facilityIds.findIndex(id => id === value)
        if (name === "select") {
            if (checked === true) {
                if (index === -1) {
                    facilityIds.push(value)
                }
            } else {
                if (index > -1) {
                    facilityIds.splice(index, 1)
                }
            }
        } else if (name === "selectAll") {
            if (checked === true) {
                facilityIds = facilities.map(f => f.id)
            } else {
                facilityIds = []
            }
        }
        model.facilityIds = facilityIds
        this.setState({
            model: model
        })
    }

    onSubmit = () => {
        const { buildings, floors, zones, facilities } = this.props
        let model = this.state.model
        model.building = buildings.find(b => b.id === model.buildingId)
        model.floor = floors.find(f => f.id === model.floorId)
        model.zone = zones.find(z => z.id === model.zoneId)
        model.facilities = facilities.filter(f => {
            let indexSelect = model.facilityIds.findIndex(id => id === f.id)
            return indexSelect > -1
        })
        this.props.onSubmit(model)
    }

    filterFloor = (floors, buildingId) => {
        try {
            let temps = []
            if (Array.isArray(floors) && !!buildingId && buildingId !== defaultValue.NONE_SELECT) {
                temps = floors.filter(f => !!f.buildingId && f.buildingId === buildingId)
            }
            return temps
        } catch (error) {
            return []
        }
    }

    filterZone = (zones, floorId) => {
        try {
            let temps = []
            if (Array.isArray(zones) && !!floorId && floorId !== defaultValue.NONE_SELECT) {
                temps = zones.filter(f => !!f.floorId && f.floorId === floorId)
            }
            return temps
        } catch (error) {
            return []
        }
    }

    validateSubmit = (model) => {
        let isValid = false
        if (!!model.buildingId && model.buildingId !== defaultValue.NONE_SELECT) {
            isValid = true
        }
        if (!!model.floorId && model.floorId !== defaultValue.NONE_SELECT) {
            isValid = true
        }
        if (!!model.zoneId && model.zoneId !== defaultValue.NONE_SELECT) {
            isValid = true
        }
        if (!!model.capacity) {
            isValid = true
        }
        if (model.facilityIds.length > 0) {
            isValid = true
        }
        return isValid
    }
    render() {
        try {
            const { t, onClose, buildings, floors, zones, facilities } = this.props
            const { model } = this.state
            let optionsBuilding = [
                {
                    key: defaultValue.NONE_SELECT,
                    value: defaultValue.NONE_SELECT,
                    text: `-- ${t("location.selectBuilding")} --`
                }
            ],
                optionsFloor = [
                    {
                        key: defaultValue.NONE_SELECT,
                        value: defaultValue.NONE_SELECT,
                        text: `-- ${t("location.selectFloor")} --`
                    }
                ],
                optionsZone = [
                    {
                        key: defaultValue.NONE_SELECT,
                        value: defaultValue.NONE_SELECT,
                        text: `-- ${t("location.selectZone")} --`
                    }
                ]

            optionsBuilding = optionsBuilding.concat(buildings.map(b => b.serializeOption()))
            optionsFloor = optionsFloor.concat(this.filterFloor(floors, model.buildingId).map(b => b.serializeOption()))
            optionsZone = optionsZone.concat(this.filterZone(zones, model.floorId).map(b => b.serializeOption()))

            let isCheckSelectFacilityAll = (facilities.length > 0 && utils.arrayDiff(facilities.map(f => f.id), model.facilityIds).length === 0)

            let isSubmit = this.validateSubmit(model)
            return (
                <React.Fragment>
                    {this.getModalContent(new ModalModel({
                        headerText: t("common.location"),
                        size: "tiny",
                        content: <div className="module modalAddSearchLocation">
                            <div className="modal-add-search-location">
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("location.building")} />
                                                <Form.Dropdown
                                                    selection
                                                    options={optionsBuilding}
                                                    name="buildingId"
                                                    value={model.buildingId}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("location.building")} />
                                                <Form.Dropdown
                                                    selection
                                                    options={optionsFloor}
                                                    name="floorId"
                                                    value={model.floorId}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("location.building")} />
                                                <Form.Dropdown
                                                    selection
                                                    options={optionsZone}
                                                    name="zoneId"
                                                    value={model.zoneId}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("location.numberOfAttendee")} />
                                                <Form.Input
                                                    selection
                                                    name="capacity"
                                                    value={model.capacity}
                                                    onChange={this.handleChange}
                                                    onKeyDown={validateInput.onKeyNumber}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("common.facilities")} />
                                                <div className="box-facility">
                                                    {
                                                        (Array.isArray(facilities) && facilities.length > 0) &&
                                                        <div className="box-facility-wrapper">
                                                            <div className="item-facility"
                                                                onClick={(e) => this.handleChangeFacility(e, { name: "selectAll", checked: !isCheckSelectFacilityAll }, facilities)}
                                                            >
                                                                <div className="if-checkbox">
                                                                    <Form.Checkbox
                                                                        name="selectAll"
                                                                        value="selectAll"
                                                                        checked={isCheckSelectFacilityAll}
                                                                    />
                                                                </div>
                                                                <div className="if-text">
                                                                    <div className="if-text-wrapper">
                                                                        <p>{t("common.selectAll")}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                facilities.map((f, i) => {
                                                                    let indexSelect = model.facilityIds.findIndex(id => id === f.id)
                                                                        , isChecked = (indexSelect > -1)
                                                                    return <div key={i} className="item-facility" onClick={(e) => this.handleChangeFacility(e, { name: "select", value: f.id, checked: !isChecked })}>
                                                                        <div className="if-checkbox" >
                                                                            <Form.Checkbox
                                                                                name="select"
                                                                                value={f.id}
                                                                                checked={isChecked}
                                                                            />
                                                                        </div>
                                                                        <div className="if-image">
                                                                            <div className="if-image-wrapper">
                                                                                <img src={`${CORE_API_ENDPOINT}/image/amenity/${f.id}`} alt="Facility" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="if-text">
                                                                            <div className="if-text-wrapper">
                                                                                <p>{f.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            </div>
                        </div>,
                        buttons: [
                            new ButtonModel({
                                text: t("common.ok"),
                                color: "blue",
                                onClick: this.onSubmit,
                                disabled: !isSubmit
                            }),
                            new ButtonModel({
                                text: t("common.cancel"),
                                color: "grey",
                                onClick: onClose
                            })
                        ]
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ModalAddSearchLocation.defaultProps = {
    model: null,
    buildings: [],
    floors: [],
    zones: [],
    facilities: [],
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ModalAddSearchLocation)