
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Grid } from 'semantic-ui-react'
import { CORE_API_ENDPOINT } from '../../lib'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import ChangeUserInformationForm from './ChangeUserInformationForm'
import UserModel from '../../models/UserModel'
import { cookiesKey } from '../../constant'
import { afterUpdateUser } from '../../business/AuthVerifyManager'

class ViewChangeUserInformation extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.model = null
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                model: null,
            },
                action = () => {

                }
            let res = await this.props.onGetUserInfo(user.id)
            if (!!res && res.result === true && res.data instanceof UserModel) {
                tempState.model = new UserModel(res.data)
            }
            this.setState(tempState, action)
        })
    }

    onUpdateUser = () => {
        let { user, model } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => { }
            let res = await this.props.onUpdateUser(user.id, model.serializeUpdateUser())
            if (!!res && res.result === true) {
                tempState.isLoading = true
                action = () => {
                    let isRemember = false
                    if (typeof this.bCookie.getData(cookiesKey.IS_REMEMBER) === "boolean") {
                        isRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
                    }
                    afterUpdateUser(res.data, isRemember)
                    this.props.history.replace(`/user/information`)
                }
            }
            this.setState(tempState, action)
        })
    }

    onBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { t } = this.props
        const { model, isLoading } = this.state
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewChangeUserInformation">
                        <Container text>
                            <div className="change-user-information-header">
                                <h1>{t("user.editProfile")}</h1>
                            </div>
                            <div className="change-user-information-body">
                                <Form>
                                    <Grid>
                                        {
                                            !!model &&
                                            <React.Fragment>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="user-image">
                                                            <div className="user-image-wrapper">
                                                                {
                                                                    !!model && model.imageFile ?
                                                                        <img src={model.imageFile.content} alt="User" />
                                                                        : <img src={`${CORE_API_ENDPOINT}/image/user/${model.id}`} alt="User" />
                                                                }

                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ChangeUserInformationForm
                                                            model={model}
                                                            handleChange={this.handleChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </React.Fragment>
                                        }
                                        <Grid.Column width={16} textAlign="center">
                                            <div className="box-action">
                                                {
                                                    !!model &&
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("common.update"),
                                                            color: "blue",
                                                            disabled: isLoading,
                                                            onClick: this.onUpdateUser
                                                        })}
                                                    />
                                                }
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.cancel"),
                                                        color: "grey",
                                                        disabled: isLoading,
                                                        onClick: this.onBack
                                                    })}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewChangeUserInformation.defaultProps = {
    onGetUserInfo: () => {
        console.error("Please provide Get Event action.")
        return null
    },
    onUpdateUser: () => {
        console.error("Please provide Change Password action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewChangeUserInformation)