
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { t, translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'


import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

// import { version } from '../../../../package.json'
// import { Icon } from 'semantic-ui-react'
// import ModalModel from '../../core/model/ModalModel'
import ButtonModel from '../../core/model/ButtonModel'
// import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import config from '../../config'
import ItemRoom from '../room/ItemRoom'
import FrontendSettingModel from '../../models/FrontendSettingModel'
import BoxLoadingComponent from '../common/BoxLoadingComponent'
import utils from '../../core/utility/utils'
import moment from 'moment'
import { colorCode, dateTimeFormat } from '../../constant'
import SuggestionModel from '../../models/SuggestionModel'

const slotSize = 4

class ViewHome extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.setting = null
        this.state.startDateTime = moment()
        this.state.slots = []
        this.state.slotActive = null

        this.state.isLoadingRoom = false

        this.state.roomSuggestion = null
    }

    componentDidMount() {
        this.getDaTa()
    }

    getDaTa = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                setting: null,
            },
                action = () => {

                }

            let res = await this.props.onGetSetting()
            if (!!res && res.data instanceof FrontendSettingModel) {
                tempState.setting = res.data
                tempState.startDateTime = utils.getStartDateTimeByStep(moment(), res.data.slotDuration)
                let slots = []
                for (let index = 1; index <= slotSize; index++) {
                    slots.push(res.data.slotDuration * index)
                }
                if (slots.length > 0) {
                    tempState.slotActive = slots[0]
                    action = () => {
                        this.onGetRoomSuggestion(tempState.startDateTime, slots[0])
                    }
                }
                tempState.slots = slots
            }
            this.setState(tempState, action)
        })
    }

    onGetRoomSuggestion = (startDateTime, duration) => {
        this.setState({
            isLoadingRoom: true,
            roomSuggestion: null,
        }, async () => {
            let tempState = {
                isLoadingRoom: false,
                roomSuggestion: null,
            }
            let start = moment(startDateTime)
                , end = moment(startDateTime).add(duration, "m")
            let res = await this.props.onGetRoomSuggestion({
                start: this.convertDateTime(start).format(dateTimeFormat.apiFullDateTime),
                end: this.convertDateTime(end).format(dateTimeFormat.apiFullDateTime),
            })
            if (!!res && res.data instanceof SuggestionModel) {
                tempState.roomSuggestion = res.data
            }
            this.setState(tempState)
        })
    }

    onSelectSlot = (slot) => {
        this.setState({
            slotActive: slot
        }, () => {
            this.onGetRoomSuggestion(this.state.startDateTime, slot)
        })
    }

    onClickBook = (room) => {
        const { startDateTime, slotActive } = this.state
        let start = moment(startDateTime)
        let url = `/event/create?roomEmails=${encodeURIComponent(room.email)}&start=${start.format(dateTimeFormat.apiFullDateTime)}&end=${moment(start).add(slotActive, "m").format(dateTimeFormat.apiFullDateTime)}`
        this.props.history.push(url)
    }

    render() {
        const { isLoading, isLoadingRoom, startDateTime, slots, slotActive, roomSuggestion } = this.state
        let startTime = moment(startDateTime).format("HH:mm")
            , roomResult = []
        if (roomSuggestion instanceof SuggestionModel) {
            let results = roomSuggestion.result
            if (results.length > 0) {
                roomResult = roomSuggestion.getRoomResultByKeyword(results[0].keyword)
            }
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewHome">
                        <div className="view-home-bg" style={{ backgroundImage: `url("${config.publicUrl}/assets/images/bg_booking.jpg")` }}>

                        </div>
                        {/* <div className="view-home-body"></div> */}
                        <div className="book-now" >
                            <h1>{t("event.book")}</h1>
                            {
                                isLoading ?
                                    null
                                    // <BoxLoadingComponent />
                                    :
                                    <React.Fragment>
                                        <h2 className="text-select-duration">{`${t("event.selectDurationFrom", { time: startTime })}`}</h2>
                                        <div className="book-duration">
                                            {
                                                slots.map((duration, i) => {
                                                    return <div className={`item-duration ${slotActive === duration ? "active" : ""}`}
                                                        key={i} onClick={() => this.onSelectSlot(duration)}>
                                                        <p>{duration} m</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </React.Fragment>
                            }
                        </div>

                        <div className="list-room">
                            <Container>
                                {
                                    (isLoading || isLoadingRoom) ?
                                        <BoxLoadingComponent color={colorCode.SECONDARY} />
                                        : <div className="list-room-data">
                                            {
                                                roomResult.map((room, i) => {
                                                    return <ItemRoom key={i}
                                                        model={room}
                                                        buttons={[
                                                            new ButtonModel({
                                                                color: "blue",
                                                                text: t("event.book"),
                                                                onClick: () => this.onClickBook(room)
                                                            })
                                                        ]}
                                                    />
                                                })
                                            }
                                        </div>
                                }

                                {/* {
                                    rooms.map((room, i) => {
                                        return <ItemRoom key={i}
                                            buttons={[
                                                new ButtonModel({
                                                    color: "blue",
                                                    text: "Book",
                                                    onClick: this.onClickBook
                                                })
                                            ]}
                                        />
                                    })
                                } */}
                            </Container>
                        </div>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewHome.defaultProps = {
    onGetSetting: () => {
        console.error("Please provide Get Setting action.")
        return null
    },
    onGetRoomSuggestion: () => {
        console.error("Please provide Get Room Suggestion action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewHome)