
import React from 'react'
import MyPage from '../../core/components/MyPage'
import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ViewButton from '../../core/view/common/ViewButton'
import EventModel from '../../models/EventModel'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import moment from 'moment'
import { dateTimeFormat } from '../../constant'

class ItemEvent extends MyPage {

    render() {
        try {
            const { t, model, buttons } = this.props
            if (!(model instanceof EventModel)) {
                return null
            }
            let textTime = moment(model.startDateTime).format(dateTimeFormat.displayTime) + " - " + moment(model.endDateTime).format(dateTimeFormat.displayTime)
                , dateTimeSplit = model.getTimeInComing(moment())

            if (model.isAllDay) {
                textTime = t("common.allDay")
            }
            let dateTimeStatus = null
            if (!!dateTimeSplit) {
                if (dateTimeSplit.isNow) {
                    dateTimeStatus = <h2>{t("event.statusNow")}</h2>
                } else if (dateTimeSplit.isEnd) {
                    dateTimeStatus = <h2>{t("event.statusEnd")}</h2>
                } else if (dateTimeSplit.day > 0) {
                    dateTimeStatus = <React.Fragment>
                        <h2>{dateTimeSplit.day}</h2>
                        <p>{t("common.day")}</p>
                    </React.Fragment>
                } else if (dateTimeSplit.hour > 0) {
                    dateTimeStatus = <React.Fragment>
                        <h2>{dateTimeSplit.hour}</h2>
                        <p>{t("common.hour")}</p>
                    </React.Fragment>
                } else if (dateTimeSplit.minute > 0) {
                    dateTimeStatus = <React.Fragment>
                        <h2>{dateTimeSplit.minute}</h2>
                        <p>{t("common.min")}</p>
                    </React.Fragment>
                }
            }
            return (
                <div className={`module itemEvent ${model.eventType}`}>
                    <div className="item-event-body">
                        <div className="ie-detail">
                            <div className="ie-d-meeting">
                                <div className="meeting-subject">
                                    {
                                        model.isRecurrence &&
                                        <img src={`${config.publicUrl}/assets/images/icon-recurrence-secondary.png`} alt="Recurrence" />
                                    }
                                    {model.subject}
                                    {/* <h2>{model.subject}</h2> */}
                                </div>
                                <div className="meeting-detail">
                                    <div className="item-meeting-detail">
                                        <BoxIconImageWithText
                                            imageUrl={`${config.publicUrl}/assets/images/icon-calendar-secondary.png`}
                                            text={moment(model.startDateTime).format(dateTimeFormat.displayDate)}
                                        />
                                    </div>
                                    <div className="item-meeting-detail">
                                        <BoxIconImageWithText
                                            imageUrl={`${config.publicUrl}/assets/images/icon-clock-secondary.png`}
                                            text={textTime}
                                        />
                                    </div>
                                    <div className="item-meeting-detail">
                                        <BoxIconImageWithText
                                            imageUrl={`${config.publicUrl}/assets/images/icon-mark-secondary.png`}
                                            text={model.location}
                                        />
                                    </div>
                                    <div className="item-meeting-detail">
                                        <BoxIconImageWithText
                                            imageUrl={`${config.publicUrl}/assets/images/icon-user-secondary.png`}
                                            text={model.organizerName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ie-d-time">
                                <div className="time-box">
                                    {dateTimeStatus}
                                </div>
                            </div>
                        </div>
                        {
                            Array.isArray(buttons) && buttons.length > 0 &&
                            <div className="ie-action">
                                {
                                    buttons.map((b, i) => {
                                        return <ViewButton key={i} model={b} />
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ItemEvent.defaultProps = {
    model: new EventModel(),
    buttons: [],
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ItemEvent)