import React from 'react'
import { Form, Popup } from 'semantic-ui-react'
import moment from 'moment'
import { translate } from 'react-switch-lang'
import InputSlider from 'react-input-slider'
// import TimePicker from 'tui-time-picker'
import utils from '../../utility/utils'
import ViewButton from './ViewButton'
import ButtonModel from '../../model/ButtonModel'


class InputTimePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            sessionId: "picker" + utils.makeid(40),
            value: moment(props.value)
        }
    }

    onChange = (time, timeString) => {
        const { name } = this.props
        this.setState({
            isOpen: false
        }, () => {
            this.props.onChange(null, { name: name, value: time })
        })
    }

    handleOpen = () => {
        const { value } = this.props
        this.setState({
            isOpen: true,
            value: moment(value)
        })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    // openTimePicker = () => {
    //     const { name, minuteStep } = this.props
    //     const { value, sessionId } = this.state
    //     let valueState = moment(value)
    //         , tempMinuteStep = 15
    //     if (!isNaN(minuteStep)) {
    //         tempMinuteStep = minuteStep
    //     }
    //     var tpSpinbox = new TimePicker(`#${sessionId}`, {
    //         initialHour: valueState.hours(),
    //         initialMinute: valueState.minutes(),
    //         minuteStep: tempMinuteStep,
    //         inputType: 'spinbox',
    //         showMeridiem: false
    //     });
    //     tpSpinbox.on("change", (e) => {
    //         let valueChange = moment(this.state.value)
    //         valueChange.set("hour", e.hour).set("minute", e.minute)
    //         this.props.onChange(null, { name: name, value: valueChange })
    //     })
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen === false && this.state.isOpen === true) {
            // this.openTimePicker()
            this.setState({
                value: moment(this.props.value)
            })
        }
    }

    changeHours = pos => {
        let m = moment(this.state.value)
        m.set("hour", pos.x)
        this.setState({
            value: m
        })
    }

    changeMinutes = pos => {
        let m = moment(this.state.value)
        m.set("minute", pos.x)
        this.setState({
            value: m
        })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onSave = () => {
        this.setState({
            isOpen: false
        }, () => this.props.onChange(null, { name: this.props.name, value: this.state.value }))
    }

    onClose = () => {
        this.setState({
            isOpen: false
        })
    }
    render() {
        const { t, format, disabled, minuteStep } = this.props
        const { value, isOpen } = this.state
        return (
            <React.Fragment>
                <div className="module inputTimePicker">
                    <Popup
                        className="popup-input-time-picker"
                        // flowing
                        on="click"
                        trigger={<div className={`input-display ${disabled === true ? "disabled" : ""}`}>
                            <Form.Input
                                fluid
                                icon={{ name: "clock outline", link: true }}
                                readOnly={true}
                                value={moment(this.props.value).format(format)}
                            />
                        </div>}
                        open={isOpen}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                    >

                        {/* <div className="timepicker-spinbox" id={`${sessionId}`}></div> */}
                        <div className="module inputTimePicker">
                            <Form onKeyDown={this.onKeyDownSubmitForm}>
                                <div className="input-time-picker-wrapper">
                                    <div className="box-time-display">
                                        <div className="time-item">{moment(value).format("HH")}</div>
                                        <div className="time-separator">:</div>
                                        <div className="time-item">{moment(value).format("mm")}</div>
                                    </div>
                                    <div className="box-control">
                                        <p>{t("date.hours")}:</p>
                                        <InputSlider
                                            className="slider-time"
                                            xmin={0}
                                            xmax={23}
                                            xstep={1}
                                            x={moment(value).hour()}
                                            onChange={this.changeHours}
                                        />
                                    </div>
                                    <div className="box-control">
                                        <p>{t("date.minutes")}:</p>
                                        <InputSlider
                                            className="slider-time"
                                            xmin={0}
                                            xmax={59}
                                            xstep={minuteStep}
                                            x={moment(value).minute()}
                                            onChange={this.changeMinutes}
                                        />
                                    </div>
                                    <div className="box-action">
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t("common.cancel"),
                                                color: "grey",
                                                onClick: this.onClose
                                            })}
                                        />
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t("common.save"),
                                                color: "blue",
                                                onClick: this.onSave
                                            })}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </Popup>
                </div>
            </React.Fragment>
        )
    }
}

InputTimePicker.defaultProps = {
    name: "",
    value: moment(),
    minuteStep: 5,
    onChange: () => console.error("Please provide On Change action."),
    format: "HH:mm",
    disabled: false
}

export default translate(InputTimePicker)