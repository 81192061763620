
import CookiesManager from "../core/business/CookiesManager"
import jwt from 'jsonwebtoken'
import moment from "moment"
import { cookiesKey, dateTimeFormat } from "../constant"
import RoleModel from "../models/RoleModel"
import UserModel from "../models/UserModel"
import { CORE_API_ENDPOINT, MIN_EXPIRE_TOKEN } from "../lib"
import axios from "axios"

export async function setVerifyCookies(verifyData, isRemember = false) {
    try {
        let bCookie = new CookiesManager()
        const { user, token, refreshToken, refreshTokenExpiresDate, expires_on, expires_date, system_key, role } = verifyData
        let userModel = new UserModel()
        userModel.setData(user)

        let datas = {}
        datas[cookiesKey.IS_REMEMBER] = isRemember
        datas[cookiesKey.USER] = userModel.serializeKookie()
        datas[cookiesKey.TOKEN] = token
        datas[cookiesKey.REFRESH_TOKEN] = refreshToken
        datas[cookiesKey.TOKEN_EXPIRES_DATE] = expires_date
        datas[cookiesKey.TOKEN_EXPIRES_ON] = expires_on
        if (!!refreshToken) {
            if (!!refreshTokenExpiresDate) {
                datas[cookiesKey.REFRESH_TOKEN_EXPIRES_DATE] = refreshTokenExpiresDate
            } else {
                let decode = await jwt.decode(refreshToken)
                if (!!decode) {
                    let exp = moment.unix(decode.exp)
                    datas[cookiesKey.REFRESH_TOKEN_EXPIRES_DATE] = exp.format(dateTimeFormat.apiFullDateTime)
                }
            }
        }
        if (typeof userModel.isIntegration === "boolean") {
            datas[cookiesKey.IS_INTEGRATION] = userModel.isIntegration
        } else {
            datas[cookiesKey.IS_INTEGRATION] = false
        }
        if (!!system_key) {
            datas[cookiesKey.SYSTEM_KEY] = system_key.toUpperCase()
        }

        let roleModel = new RoleModel()
        roleModel.setData(role)
        datas[cookiesKey.ROLE] = roleModel.serialize()
        bCookie.setDatas(datas)
    } catch (error) {
    }
}

export async function afterVerify(verifyData, isRemember = false) {
    try {
        if (!verifyData) {
            throw new Error("Invalid verify data")
        }
        await setVerifyCookies(verifyData, isRemember)
        redirectToFirstPage(verifyData)
    } catch (error) {

    }
}

export function redirectToFirstPage(verifyData) {
    try {
        let bCookie = new CookiesManager()
        const targetURL = bCookie.getData(cookiesKey.TARGET_URL)
        let isFirstLogin = false
            , toUrl = "/"

        if (!!verifyData.user && typeof verifyData.user.isFirstLogin === "boolean") {
            isFirstLogin = verifyData.user.isFirstLogin
        }
        if (targetURL) {
            toUrl = targetURL
            bCookie.removeData(cookiesKey.TARGET_URL)
        } else {
            if (isFirstLogin) {
                if (verifyData.user && typeof verifyData.user.username === "string") {
                    if (verifyData.user.username === "bainisysadmin") {
                        toUrl = "/"
                    } else {
                        toUrl = "/user/information"
                    }
                }
            }
        }

        redirectToUrl(toUrl)
    } catch (error) {

    }
}

function redirectToUrl(url) {
    window.location.replace(url)
}

export async function refreshToken() {
    let bCookie = new CookiesManager()
    try {
        let tokenExpireOn = bCookie.getData(cookiesKey.TOKEN_EXPIRES_ON)
            , refreshToken = bCookie.getData(cookiesKey.REFRESH_TOKEN)
            , user = bCookie.getData(cookiesKey.USER)
        if (!!tokenExpireOn) {
            const duration = moment.unix(tokenExpireOn).diff(moment(), "m")
            if (duration <= MIN_EXPIRE_TOKEN) {
                const options = {
                    method: "POST",
                    url: `${CORE_API_ENDPOINT}/user/refreshtoken`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        refreshToken: refreshToken,
                        userId: !!user ? user.id : ""
                    }
                }
                let res = await axios(options)
                if (!!res && !!res.data) {
                    let data = null
                    if ("value" in res.data) {
                        data = res.data.value
                    }
                    if (!!data) {
                        let tempIsRemember = bCookie.getData(cookiesKey.IS_REMEMBER)
                            , isRemember = typeof tempIsRemember === "boolean" ? tempIsRemember : false
                        await afterRefreshToken(data, isRemember)
                    } else {
                        throw new Error("Refresh token fail")
                    }
                } else {
                    throw new Error("Token is Expired")
                }
            }
        }
    } catch (error) {
        bCookie.removeAll()
        return window.location.replace('/')
        // return window.location.replace('/sessionExpire')
    }
}

export async function afterRefreshToken(verifyData, isRemember = false) {
    try {
        if (!verifyData) {
            throw new Error("Invalid verify data")
        }

        await setVerifyCookies(verifyData, isRemember)
    } catch (error) {

    }
}

export async function setUserCookies(userModel, isRemember = false) {
    try {
        if (!(userModel instanceof UserModel)) {

        }
        let bCookie = new CookiesManager()
        let datas = {}
        datas[cookiesKey.IS_REMEMBER] = isRemember
        datas[cookiesKey.USER] = userModel.serializeKookie()
        bCookie.setDatas(datas)
    } catch (error) {

    }
}

export async function afterUpdateUser(userModel, isRemember = false) {
    try {
        if (!userModel) {
            throw new Error("Invalid user data")
        }

        await setUserCookies(userModel, isRemember)
    } catch (error) {

    }
}

export async function afterIntegration(verifyData, isRemember = false, callback) {
    try {
        if (!verifyData) {
            throw new Error("Invalid verify data")
        }
        await setVerifyCookies(verifyData, isRemember)
        if (typeof callback === "function") {
            callback()
        }
    } catch (error) {

    }
}