import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { checkToken, api, setToken } from '../middlewares'
import rootReducer from '../reduxReducers'

const config = (initialState) => {
    const middlewares = [
        checkToken,
        setToken,
        api,
        thunk,
    ]

    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middlewares)
    )

    return store
}

export default config