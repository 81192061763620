
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import UserModel from './UserModel'

const Schema = mongoose.Schema

const UserGroupSchema = new Schema({
	id: { type: String, default: "" },
	name: { type: String, default: "" },
	description: { type: String, default: "" },
	isReserve: { type: Boolean, default: false },
	internalUser: [{ type: Object }],
	externalUser: [{ type: Object }],

	allUser: [{ type: Object }],
}, { _id: false })

setBaseSchema(UserGroupSchema, "user_group")

// UserGroupSchema.virtual('allUser').get(function () {
// 	let users = []
// 	if (this.internalUser.length > 0) {
// 		this.internalUser.forEach(user => {
// 			let userIndex = users.findIndex(u => u.email.toLowerCase() === user.email.toLowerCase())
// 			if (userIndex === -1) {
// 				users.push(user)
// 			}
// 		});
// 	}
// 	if (this.externalUser.length > 0) {
// 		this.externalUser.forEach(user => {
// 			let userIndex = users.findIndex(u => u.email.toLowerCase() === user.email.toLowerCase())
// 			if (userIndex === -1) {
// 				users.push(user)
// 			}
// 		});
// 	}
// 	return users
// })

UserGroupSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.name = data.name
			this.description = data.description
			if (Array.isArray(data.internalUser)) {
				this.internalUser = data.internalUser.map(user => {
					let model = new UserModel()
					model.setDataInUserGroup(user)
					return model
				})
			}
			if (Array.isArray(data.externalUser)) {
				this.externalUser = data.externalUser.map(user => {
					let model = new UserModel()
					model.setDataInUserGroup(user)
					return model
				})
			}
			let users = []
			if (this.internalUser.length > 0) {
				this.internalUser.forEach(user => {
					let userIndex = users.findIndex(u => u.email.toLowerCase() === user.email.toLowerCase())
					if (userIndex === -1) {
						users.push(user)
					}
				});
			}
			if (this.externalUser.length > 0) {
				this.externalUser.forEach(user => {
					let userIndex = users.findIndex(u => u.email.toLowerCase() === user.email.toLowerCase())
					if (userIndex === -1) {
						users.push(user)
					}
				});
			}
			this.allUser = users
		}
	} catch (error) {

	}
}

const UserGroupModel = mongoose.model("user_group", UserGroupSchema, "user_group")

export default UserGroupModel