
import PrivateRoute from '../policy/PrivateRoute'
// import PublicRoute from '../policy/PublicRoute'

import RoomInfoController from '../controllers/room/RoomInfoController'
import SearchRoomController from '../controllers/room/SearchRoomController'
import RoomListController from '../controllers/room/RoomListController'

let routeConfigs = [
    { route: PrivateRoute, path: "/view", component: RoomInfoController },
    { route: PrivateRoute, path: "/search", component: SearchRoomController },
    { route: PrivateRoute, path: "/list", component: RoomListController },
]

const RouteConfig = {
    module: "room",
    routeConfigs,
}

export default RouteConfig