
import moment from 'moment'
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import EventModel from './EventModel'

const Schema = mongoose.Schema

const CalendarSlotSchema = new Schema({
    startTime: { type: Date, default: moment() },
    endTime: { type: Object, default: null },
    event: { type: Object, default: null },
}, { _id: false })

setBaseSchema(CalendarSlotSchema, "calendar_slot")

CalendarSlotSchema.methods.setData = function (data) {
    try {
        if (!!data) {
            this.startTime = moment(data.startTime, "HH:mm")
            this.endTime = moment(data.endTime, "HH:mm")
            if (!!data.event) {
                let model = new EventModel()
                model.setData(data.event)
                this.event = model
            }
        }
    } catch (error) {
        console.error(error)
    }
}

const CalendarSlotModel = mongoose.model("calendar_slot", CalendarSlotSchema, "calendar_slot")

export default CalendarSlotModel