
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { t, translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'


import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

// import { version } from '../../../../package.json'
// import { Icon } from 'semantic-ui-react'
// import ModalModel from '../../core/model/ModalModel'
import ButtonModel from '../../core/model/ButtonModel'
// import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
// import config from '../../config'
import ItemEvent from './ItemEvent'
import ViewButton from '../../core/view/common/ViewButton'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import moment from 'moment'
import { cookiesKey } from '../../constant'

class ViewMyReservation extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.dateTime = moment()
        this.state.events = []
        this.state.user = this.bCookie.getData(cookiesKey.USER)
    }

    onClickChangeDateTime = (value) => {
        let dateTime = moment(this.state.dateTime).add(value, "d")
        this.setState({
            dateTime: dateTime
        }, this.onSearch)
    }

    handleChangeDateTime = (e, { name, value }) => {
        this.setState({
            dateTime: value
        }, this.onSearch)
    }

    componentDidMount() {
        this.onSearch()
    }

    onSearch = () => {
        const { user, dateTime } = this.state
        let userEmail = !!user ? user.email : ""
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                rooms: []
            },
                action = () => {

                }

            let res = await this.props.onGetEventCalendar(userEmail, moment(dateTime).format("YYYYMMDD"))
            if (!!res && res.result === true && Array.isArray(res.datas)) {
                tempState.events = res.datas
            }
            this.setState(tempState, action)
        })
    }

    onClickView = (model) => {
        this.props.history.push(`/event/view?eventId=${encodeURIComponent(model.eventId)}`)
    }

    render() {
        const { events, dateTime, isLoading } = this.state
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewMyReservation">
                        <Container text>
                            <div className="mr-header">
                                <h1>{t("event.myBookings")}</h1>
                                <div className="mr-control-date">
                                    <div className="arrow-control">
                                        <ViewButton
                                            model={new ButtonModel({
                                                iconClass: "angle left",
                                                className: "button-primary",
                                                externalProps: {
                                                    circular: true
                                                },
                                                disabled: isLoading,
                                                onClick: () => this.onClickChangeDateTime(-1)
                                            })}
                                        />
                                    </div>
                                    <div className="date-control">
                                        <InputDatePicker
                                            name="dateTime"
                                            value={dateTime}
                                            disabled={isLoading}
                                            onChange={this.handleChangeDateTime}
                                        />
                                    </div>
                                    <div className="arrow-control">
                                        <ViewButton
                                            model={new ButtonModel({
                                                iconClass: "angle right",
                                                className: "button-primary",
                                                externalProps: {
                                                    circular: true
                                                },
                                                disabled: isLoading,
                                                onClick: () => this.onClickChangeDateTime(1)
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="list-event">
                                {
                                    events.map((event, i) => {
                                        let buttons = [
                                            new ButtonModel({
                                                color: "blue",
                                                text: t("event.view"),
                                                className: "button-secondary",
                                                onClick: () => this.onClickView(event)
                                            })
                                        ]
                                        return <ItemEvent key={i} model={event} buttons={buttons} />
                                    })
                                }
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewMyReservation.defaultProps = {
    onGetEventCalendar: () => {
        console.error("Please provide Get Event Calendar action.")
        return null
    }
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewMyReservation)