
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import IntegrationExchangeForm from './IntegrationExchangeForm'
import LocalLoginModel from '../../models/LocalLoginModel'
import { cookiesKey } from '../../constant'

class ViewIntegrationExchange extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.model = new LocalLoginModel()
        this.state.modelErrors = []
    }

    onIntegrationExchange = () => {
        this.props.onIntegrationExchange(this.state.model)
    }

    onBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { t } = this.props
        const { model, modelErrors, isLoading } = this.state
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewIntegrationExchange">
                        <Container text>
                            <div className="integration-ex-header">
                                <h1>{t("user.changePassword")}</h1>
                            </div>
                            <div className="integration-ex-body">
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <IntegrationExchangeForm
                                                    model={model}
                                                    handleChange={this.handleChange}
                                                    errors={modelErrors}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Column width={16} textAlign="center">
                                            <div className="box-action">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.connect"),
                                                        color: "blue",
                                                        disabled: isLoading,
                                                        onClick: () => this.onValidate(this.onIntegrationExchange)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.cancel"),
                                                        color: "grey",
                                                        disabled: isLoading,
                                                        onClick: this.onBack
                                                    })}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewIntegrationExchange.defaultProps = {
    onIntegrationExchange: () => {
        console.error("Please provide Integration Exchange action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewIntegrationExchange)