
import React from 'react'
import { Container } from 'semantic-ui-react'
import { translate, getLanguage } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import ContactUsModel from '../../models/ContactUsModel'

class ViewContact extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.model = new ContactUsModel()
    }

    componentDidMount() {
        this.onGetData()
    }

    onGetData = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {

                }

            let res = await this.props.onGetContactUs()
            if (!!res && res.result === true && res.data instanceof ContactUsModel) {
                tempState.model = res.data
            }
            this.setState(tempState, action)
        })
    }
    render() {
        try {
            const { t } = this.props
            const { model } = this.state
            let content = model.getContentByLanguage(getLanguage())
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithHeaderFooter
                        header={<ViewHeader />}
                        footer={<ViewFooter />}
                    >
                        <div className="module viewContact">
                            <Container text>
                                <div className="contact-wrapper">
                                    <div className="contact-header">
                                        <h1>{t("common.contactUs")}</h1>
                                    </div>
                                    <div>
                                        <div dangerouslySetInnerHTML={{ __html: content }} />
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </LayoutWithHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ViewContact.defaultProps = {
    onGetContactUs: () => {
        console.error("Please provide Get Contact action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewContact)