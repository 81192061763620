
import base64 from 'base-64'
import Cookies from 'js-cookie'
import { cookiesKey } from '../../constant'

export default class CookiesManager {

	constructor() {
		this.key = cookiesKey.COOKIE_NAME ? cookiesKey.COOKIE_NAME : "rm_data"
		this.expires = 7
		this.timeZone = "Asia/Bangkok"
	}

	encrypt(data) {
		let step1 = base64.encode(encodeURIComponent(JSON.stringify(data)))
			, step2 = this.myEncode(step1)
		return step2
	}

	decrypt(data) {
		let step1 = this.myDecode(data)
		return JSON.parse(decodeURIComponent(base64.decode(step1)))
	}

	myEncode(str) {
		try {
			let b64 = base64.encode(str)
			if (b64.length <= 20) {
				return b64
			}

			let pre = b64.substr(0, 10)
				, sub = b64.substr(b64.length - 10, 10)
				, con = b64.substr(10, b64.length - 20)

			return con + pre + sub
		} catch (error) {
			throw error
		}
	}

	myDecode(b64) {
		try {
			if (b64.length <= 20) {
				return base64.decode(b64)
			}

			let pre = b64.substr(b64.length - 20, 10)
				, sub = b64.substr(b64.length - 10, 10)
				, con = b64.substr(0, b64.length - 20)

			return base64.decode(pre + con + sub)
		} catch (error) {
			throw error
		}
	}

	setDataCookies(data) {
		try {
			if (data) {
				let expires = null
				try {
					if (!!data[cookiesKey.IS_REMEMBER]) {
						expires = { expires: this.expires }
					}
				} catch (error) {

				}
				Cookies.set(this.key, this.encrypt(data), expires)
			}
		} catch (error) {
			console.error(error)
		}
	}

	getDataCookies() {
		try {
			let data = Cookies.get(this.key)
			if (data) {
				let dataDecrypt = this.decrypt(data)
				this.resetExpireDate(dataDecrypt)
				return dataDecrypt
			}
		} catch (error) {

		}

		return null
	}

	removeDataCookies() {
		try {
			Cookies.remove(this.key)
		} catch (error) {

		}

		return null
	}

	getData(key) {
		try {
			let cookiesData = this.getDataCookies()
			if (key in cookiesData) {
				return cookiesData[key]
			}

			return null
		} catch (error) {
			return null
		}
	}

	getDataAll() {
		try {
			let cookiesData = this.getDataCookies()
			return cookiesData
		} catch (error) {
			return null
		}
	}

	setData(key, value) {
		try {
			let cookiesData = this.getDataCookies()
			if (!cookiesData) {
				cookiesData = {}
			}

			cookiesData[key] = value
			this.setDataCookies(cookiesData)
		} catch (error) {

		}
	}

	setDatas(datas) {
		try {
			if (!datas) {
				throw new Error("Invalid datas")
			}

			let cookiesData = this.getDataCookies()
			if (!cookiesData) {
				cookiesData = {}
			}

			for (let key in datas) {
				cookiesData[key] = datas[key]
			}

			this.setDataCookies(cookiesData)
		} catch (error) {

		}
	}

	removeData(key) {
		try {
			let cookiesData = this.getDataCookies()
			if (key in cookiesData) {
				delete cookiesData[key]
			}

			this.setDataCookies(cookiesData)
		} catch (error) {

		}
	}

	removeAll() {
		try {
			this.removeDataCookies()
		} catch (error) {

		}
	}

	hasData(key) {
		try {
			let cookiesData = this.getDataCookies()
			return key in cookiesData
		} catch (error) {
			return false
		}
	}

	resetExpireDate(cookiesData) {
		try {
			if (!!cookiesData && cookiesData[cookiesKey.IS_REMEMBER] === true) {
				this.setDataCookies(cookiesData)
			}
		} catch (error) {

		}
	}
}