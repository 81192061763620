
import moment from 'moment'
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import EventModel from './EventModel'
import RoomModel from './RoomModel'
import FacilityModel from './FacilityModel'

const Schema = mongoose.Schema

const RoomStatusSchema = new Schema({
	currentTime: { type: Date, default: moment() },
	roomInfo: { type: Object, default: null },
	currentEvent: { type: Object, default: null },
	eventsNext: [{ type: Object }],
	roomSlot: [{ type: Object }],
}, { _id: false })

setBaseSchema(RoomStatusSchema, "room_statue")

RoomStatusSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.currentTime = moment(data.currentTime)
			if (!!data.roomInfo) {
				let model = new RoomModel()
				model.setData(data.roomInfo)
				this.roomInfo = model
			}
			if (!!data.roomStatus) {
				let model = new EventModel()
				model.setData(data.roomStatus)
				this.currentEvent = model
			}
			if (Array.isArray(data.eventsNext)) {
				this.eventsNext = data.eventsNext.map(e => {
					let model = new EventModel()
					model.setData(e)
					return model
				})
			}
			if (Array.isArray(data.roomSlot)) {
				this.roomSlot = data.roomSlot.map(f => {
					let model = new FacilityModel()
					model.setData(f)
					return model
				})
			}
		}
	} catch (error) {
		console.error(error)
	}
}

const RoomStatusModel = mongoose.model("room_statue", RoomStatusSchema, "room_statue")

export default RoomStatusModel