

import React from 'react'
import MyModal from '../view/modals/MyModal'
// import ModalCustom from '../../views/common/ModalCustom'
import ContentErrorModal from '../view/errors/ContentErrorModal'
import CookiesManager from '../business/CookiesManager'
import ViewLoading from '../view/common/ViewLoading'
import ButtonModel from '../model/ButtonModel'
import ModalModel from '../model/ModalModel'
import utils from '../utility/utils'
import ErrorModel from '../model/ErrorModel'
import moment from 'moment'
import errorMessage from '../../wording/errorMessage'


export default class MyComponent extends React.Component {

	constructor(props) {
		super(props)
		this.isRenderSystemModal = true
		this.modalThemes = ["mymodal"]
		this.state = {
			modal: null,
			error: [],
		}
		this.modalTheme = "mymodal"
		this.bCookie = new CookiesManager()
	}

	renderErrorComponent(errorObj) {
		try {
			console.error(errorObj)
			// return errorObj.message
			return null
		} catch (error) {
			console.error(error)
			// return error.message
			return null
		}
	}

	render() {
		const { modal } = this.state
		return (
			<React.Fragment>
				{this.isRenderSystemModal && this.getModalContent(modal)}
			</React.Fragment>
		)
	}

	getLoadingComponent(color, message = "") {
		let tempMessage = message
		if (!message && typeof this.props.t === "function") {
			tempMessage = this.props.t("common.loading") + "..."
		}
		return <ViewLoading isPage={false} message={tempMessage} color={color} />
	}

	getLoadingPage(color, message = "") {
		let tempMessage = message
		if (!message && typeof this.props.t === "function") {
			tempMessage = this.props.t("common.loading") + "..."
		}
		return <ViewLoading message={tempMessage} color={color} />
	}

	getModalView = () => {
		try {
			let ModalView = null
			if (this.modalTheme) {
				let getTheme = this.modalThemes.includes(this.modalTheme.toLowerCase()) ? this.modalTheme.toLowerCase() : ""
				if (getTheme === "mymodal") {
					ModalView = MyModal
				}
			}
			return ModalView
		} catch (error) {
			return null
		}
	}

	getModalContent(model) {
		if (!(model instanceof ModalModel)) {
			return null
		}
		let ModalView = this.getModalView()
		if (!ModalView) {
			return null
		}
		return <ModalView model={model} />
	}

	openModal(model) {
		this.setState({ modal: model })
	}

	closeModal = (callback) => {
		try {
			let modal = this.state.modal
			if (!!modal) {
				modal.open = false
				let action = () => { }
				if (typeof callback === "function") {
					action = callback
				}
				this.setState({ modal: modal }, action)
			}
		} catch (error) {
			
		}

	}

	setDefaultModalError = (defaultErrors) => {
		const { t } = this.props
		let dataErrorModal = null
		try {
			if (!Array.isArray(defaultErrors)) {
				defaultErrors = [defaultErrors]
			}

			dataErrorModal = { errors: [] }
			for (let error of defaultErrors) {
				let code = error.code ? error.code : "00000000"
					, message = error.message ? error.message : "Something went wrong. Please try again later"
				let tempMessage = this.getErrorMessage(code)
				if (typeof t === "function" && (!!tempMessage && tempMessage !== code)) {
					message = t(tempMessage)
				}
				dataErrorModal.errors.push(new ErrorModel({
					status: code,
					code: code,
					message: message
				}))
			}
		} catch (error) {
			dataErrorModal = {
				errors: [
					new ErrorModel({
						code: "00000000",
						status: "00000000",
						message: error.message,
					})
				]
			}

			console.error(error)
		}

		this.setModalErrors(dataErrorModal)
	}

	setModalErrors(dataError) {
		console.error(dataError)
		const { t } = this.props

		if (dataError) {
			this.openModal(new ModalModel({
				content: <ContentErrorModal errors={dataError.errors} />,
				headerText: t ? t("common.system") : "System",
				buttons: [
					new ButtonModel({
						color: "grey",
						text: t ? t("common.close") : "Close",
						title: t ? t("common.close") : "Close",
						onClick: this.closeModal,
					})
				]
			}))
		}
	}

	setModalCatch = (error) => {
		console.error(error)
		const { t } = this.props
		try {
			this.openModal(new ModalModel({
				content: t ? t("message.somethingWrong") : "Something went wrong. Please try again later.",
				headerText: t ? t("common.system") : "System",
				buttons: [
					new ButtonModel({
						color: "red",
						text: t ? t("common.close") : "Close",
						title: t ? t("common.close") : "Close",
						onClick: () => this.closeModal(),
					})
				]
			}))
		} catch (error) {
			console.error("Error :", error)
		}
	}

	getQueryFromProps(key = "") {
		return utils.getQueryFromProps(this.props, key)
	}

	getParamFromProps(key = "") {
		return utils.getParamFromProps(this.props, key)
	}

	getStateLocationFormProps(key = "") {
		return utils.getStateLocationFormProps(this.props, key)
	}

	changeDataModel = (names, model, value) => {
		if (!!model) {
			if (names.length === 1) {
				model[names[0]] = value
			} else {
				let tempModel = model[names[0]]
				names.splice(0, 1)
				this.changeDataModel(names, tempModel, value)
			}
		}
	}

	getPathCurrent = () => {
		try {
			const { location } = this.props
			let pathCurrent = "/"
			if (!!location && !!location.pathname && typeof location.pathname === 'string') {
				pathCurrent = location.pathname
			}
			return pathCurrent
		} catch (error) {
			return null
		}
	}

	convertDateTime = (dateTime) => {
		return moment(dateTime)
	}

	getErrorMessage = (code) => {
		let message = ""
		if (!!code) {
			message = code
			let tempMessage = errorMessage[code]
			if (!!tempMessage) {
				message = tempMessage
			}
		}
		return message
	}

	getErrorInput = (name, errors) => {
		try {
			const { t } = this.props
			let data = {
				isError: false,
				content: null
			}
			if (Array.isArray(errors) && errors.length > 0 && !!name) {
				let error = errors.find(e => e.path === name)
				if (error) {
					let errorMessage = this.getErrorMessage(error.message)
					data.isError = true
					data.content = typeof t === "function" ? t(errorMessage) : errorMessage
				}
			}
			return data
		} catch (error) {
			return {
				isError: true,
				content: error.message
			}
		}
	}
}