
import PrivateRoute from '../policy/PrivateRoute'
// import PublicRoute from '../policy/PublicRoute'

import UserQRCodeController from '../controllers/user/UserQRCodeController'
import ChangeUserInformationController from '../controllers/user/ChangeUserInformationController'
import ChangePasswordController from '../controllers/user/ChangePasswordController'
import UserIntegrationController from '../controllers/user/UserIntegrationController'
import IntegrationExchangeController from '../controllers/user/IntegrationExchangeController'
import ViewUserController from '../controllers/user/ViewUserController'

let routeConfigs = [
    { route: PrivateRoute, path: "/integration/exchange", component: IntegrationExchangeController },
    { route: PrivateRoute, path: "/integration", component: UserIntegrationController },

    { route: PrivateRoute, path: "/myqrcode", component: UserQRCodeController },
    { route: PrivateRoute, path: "/changeuserinformation", component: ChangeUserInformationController },
    { route: PrivateRoute, path: "/changepassword", component: ChangePasswordController },
    { route: PrivateRoute, path: "/information", component: ViewUserController },
]

const RouteConfig = {
    module: "user",
    routeConfigs,
}

export default RouteConfig