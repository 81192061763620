
import mongoose from 'mongoose'
import { setBaseSchema } from '../schema/Schema'

const Schema = mongoose.Schema

const ErrorSchema = new Schema({
    status: { type: String, default: "" },
    code: { type: String, default: "" },
    message: { type: String, default: "" },
}, { _id: false })

setBaseSchema(ErrorSchema, "error")

const ErrorModel = mongoose.model("error", ErrorSchema, "error")

export default ErrorModel