
import { requestStatus } from '../../constant'

class ActionResource {
    constructor() {
        this.status = requestStatus.NO_ACTION
        this.value = null
        this.error = null
    }

    requestFinished() {
        return (this.status !== requestStatus.REQUEST)
    }

    requestFinishedAfterRequest() {
        return (this.status !== requestStatus.REQUEST && this.status !== requestStatus.NO_ACTION)
    }

    setStatus(status) {
        this.status = status
    }

    setValue(value) {
        this.value = value
    }

    setResource(data) {
        return data
    }

    getData() {
        let result = null

        if (Array.isArray(this.value) && this.value.length > 0) {
            result = this.value[0]
        } else {
            result = this.value
        }

        return this.setResource(result)
    }

    getDatas() {
        let result = []
        if (Array.isArray(this.value)) {
            result = this.value
        } else if (this.value) {
            result = [this.value]
        }

        return result.map(r => this.setResource(r))
    }
}

export default ActionResource