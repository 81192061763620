
import { CORE_API_ENDPOINT, O365_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import UserModel from '../models/UserModel'
import UserMailSystemModel from '../models/UserMailSystemModel'

export default class UserManager extends MyManager {
	async getUserInfo(id) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/${id}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new UserModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getUserIntegrationInfo() {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/integration/info`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new UserModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async changePassword(userId, body) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/changepassword/${userId}`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async updateUser(userId, body) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/${userId}`,
				data: body
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data && !!res.data.user) {
				let model = new UserModel()
				model.setData(res.data.user)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getUserMailSystem(text) {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.GET,
				url: `${O365_API_ENDPOINT}/people/search/me?text=${text}`,
			}

			let res = await this.requestAction(options)
			if (!!res && !!res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(user => {
					let model = new UserMailSystemModel()
					model.setData(user)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}

	async unintegration(system) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/user/integration/${system}`,
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async integrationO365(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/integration/o365`,
				data: body
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async integrationGoogle(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/integration/google`,
				data: body
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async integrationExchange(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/integration/exchange`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

	async resetPassword(email, frontendUrl, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/resetpassword`,
				data: {
					email: email,
					url: frontendUrl
				}
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}

	async confirmResetPassword(key, callback) {
		try {
			const options = {
				useToken: false,
				isReToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/confirmresetpassword/${key}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			return null
		}
	}
}