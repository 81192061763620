
import React from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import RoutePermission from './RoutePermission'

class PrivateRoute extends RoutePermission {
    render() {
        let pathCurrent = this.getPathCurrent()
        if (!this.isAuthenticated() && pathCurrent !== "/login") {
            return <Redirect to={`/login`} />
        }
        if (!this.isIntegration() && (pathCurrent !== "/integration" && pathCurrent !== "/user/integration" && pathCurrent !== "/user/integration/exchange")) {
            return <Redirect to={`/user/integration`} />
        }
        let data = this.getComponentRoute()
        return <Route {...data.props} path={data.path} component={data.Component} />
    }
}

PrivateRoute.defaultProps = {
    component: null,
    path: "",
}

const enhance = compose(
    withRouter,
)

export default enhance(PrivateRoute)