// import moment from 'moment'
// import { cookiesKey } from '../../constant'
// import CookiesManager from '../core/business/CookiesManager'
// import AuthManager from "../business/AuthManager"
// import { MIN_EXPIRE_TOKEN } from '../lib'

const CheckToken = store => next => async action => {
    return next(action)
    // let bCookie = new CookiesManager()
    //     , bAuth = new AuthManager()
    //     , expire = bCookie.getData(cookiesKey.TOKEN_EXPIRES_DATE)
    //     , refreshToken = bCookie.getData(cookiesKey.REFRESH_TOKEN)
    // if (!expire || !refreshToken) {
    //     return next(action)
    // } else {
    //     let current = moment()
    //         , expireDate = moment(expire)
    //         , duration = moment.duration(expireDate.diff(current))
    //     if (duration.asMinutes() <= MIN_EXPIRE_TOKEN) { //min
    //         let body = {
    //             // refreshToken: refreshToken,
    //         }
    //         let res = await bAuth.refreshToken(body)
    //         if (!!res && !!res.result === true) {
    //             return next(action)
    //         } else {
    //             bCookie.removeAll()
    //             window.location.replace(`/`)
    //             return next(null)
    //         }
    //     } else {
    //         return next(action)
    //     }
    // }
}

export default CheckToken