
import React from 'react'
import MyPage from '../../core/components/MyPage'
// import { Container } from 'semantic-ui-react'
import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import MenuPopup from './MenuPopup'
import LanguagePopup from './LanguagePopup'

class ViewHeader extends MyPage {
    onLogout = () => {
        this.bCookie.removeAll()
        this.props.history.replace("/")
    }
    onClickMenu = (path) => {
        this.props.history.push(path)
    }
    render() {
        const { t, isShowMenu } = this.props
        let currentPath = this.getPathCurrent()
        return (
            <div className="module viewHeader">
                {/* <Container > */}
                <div className={`vh-body`}>
                    <div className={"logo"} onClick={() => this.onClickMenu("/")}>
                        <div className="logo-wrapper">
                            <img src={`${config.publicUrl}/assets/images/logo.png`} alt="Logo" />
                        </div>
                    </div>
                    <div className="menu">
                        {
                            isShowMenu === true &&
                            <div className="menu-wrapper">

                                <div className="menu-item">
                                    <div className={`menu-item-wrapper ${currentPath === "/" ? "active" : ""}`} onClick={() => this.onClickMenu("/")}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-booking.png`} alt="Booking" />
                                        </div>
                                        <div className="mi-text">
                                            <p>{t("event.book")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <div className={`menu-item-wrapper ${currentPath === "/room/search" ? "active" : ""}`} onClick={() => this.onClickMenu("/room/search")}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-search.png`} alt="Search" />
                                        </div>
                                        <div className="mi-text">
                                            <p>{t("common.search")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <div className={`menu-item-wrapper ${currentPath === "/room/list" ? "active" : ""}`} onClick={() => this.onClickMenu("/room/list")}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-roomlist.png`} alt="Room list" />
                                        </div>
                                        <div className="mi-text">
                                            <p>{t("room.roomList")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <div className={`menu-item-wrapper ${currentPath === "/event/booking" ? "active" : ""}`} onClick={() => this.onClickMenu("/event/booking")}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-myreservation.png`} alt="My reservation" />
                                        </div>
                                        <div className="mi-text">
                                            <p>{t("event.myBookings")}</p>
                                        </div>
                                    </div>
                                </div>
                                <MenuPopup
                                    trigger={
                                        <div className="menu-item">
                                            <div className="menu-item-wrapper">
                                                <div className="mi-img">
                                                    <img src={`${config.publicUrl}/assets/images/icon-menu.png`} alt="Menu" />
                                                </div>
                                                <div className="mi-text">
                                                    <p>{t("common.menu")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                                <div className="menu-item mobile-only">
                                    <div className="menu-item-wrapper" onClick={() => this.onClickMenu("/menu")}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-menu.png`} alt="Menu" />
                                        </div>
                                        <div className="mi-text">
                                            <p>{t("common.menu")}</p>
                                        </div>
                                    </div>
                                </div>
                                <LanguagePopup
                                    trigger={
                                        <div className="menu-item mobile">
                                            <div className="menu-item-wrapper">
                                                <div className="mi-img">
                                                    <img src={`${config.publicUrl}/assets/images/icon-language.png`} alt="language" />
                                                </div>
                                                <div className="mi-text">
                                                    <p>{t("common.language")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />

                                {/* <div className="menu-item">
                                    <div className="menu-item" onClick={this.onLogout}>
                                        <div className="mi-img">
                                            <img src={`${config.publicUrl}/assets/images/icon-logout.png`} alt="Log out" />
                                        </div>
                                        <p>{t("menu.logout")}</p>
                                    </div>
                                </div> */}
                            </div>
                        }
                    </div>
                </div>
                {/* </Container> */}
            </div>
        )
    }
}


ViewHeader.defaultProps = {
    isShowMenu: true
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewHeader)