
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ButtonModel from '../../core/model/ButtonModel'
import { Grid } from 'semantic-ui-react'
import ModalModel from '../../core/model/ModalModel'
import ViewButton from '../../core/view/common/ViewButton'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import config from '../../config'

class ModalRoomSelected extends MyPage {

    render() {
        try {
            const { t, rooms, onClose, onRemove } = this.props

            return (
                <React.Fragment>
                    {this.getModalContent(new ModalModel({
                        headerText: t("room.yourSelectedRooms"),
                        size: "tiny",
                        content: <div className="module modalRoomSelected">
                            <div className="modal-room-selected">
                                <Grid>
                                    {/* <Grid.Row>
                                        <Grid.Column width={16} textAlign="right">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t("common.removeAll"),
                                                    color: "red",
                                                    onClick: onRemoveAll
                                                })}
                                            />
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className="box-room-list">
                                                {
                                                    rooms.map((room, i) => {
                                                        return <div key={i} className="item-room-select">
                                                            <div className="room-text">
                                                                <p>{room.name}</p>
                                                                <BoxIconImageWithText
                                                                    imageUrl={`${config.publicUrl}/assets/images/icon-mark.png`}
                                                                    text={room.location}
                                                                />
                                                            </div>
                                                            <div className="room-action">
                                                                <ViewButton
                                                                    model={new ButtonModel({
                                                                        text: t("common.remove"),
                                                                        size: "mini",
                                                                        color: "red",
                                                                        onClick: () => onRemove(room)
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>,
                        buttons: [
                            new ButtonModel({
                                text: t("common.cancel"),
                                color: "grey",
                                onClick: onClose
                            })
                        ]
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ModalRoomSelected.defaultProps = {
    rooms: [],
    onRemove: () => console.error("Please provide Remove All action."),
    onRemoveAll: () => console.error("Please provide Remove All action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ModalRoomSelected)