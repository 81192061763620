import React from 'react'
import { Modal } from 'semantic-ui-react'
import ModalModel from '../../model/ModalModel';
import ViewButton from '../common/ViewButton'

class MyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const { model } = this.props
        if (!(model instanceof ModalModel)) {
            return null
        }
        let scrolling = true
        if (!!model.externalProps) {
            let externalProps = model.externalProps
            if (typeof externalProps.scrolling === "boolean") {
                scrolling = externalProps.scrolling
            }
        }

        return (
            <React.Fragment>
                <Modal
                    open={model.open}
                    onClose={model.onClose}
                    size={model.size}
                    className="module semanticModal"
                >
                    {
                        !!model.headerText &&
                        < Modal.Header className="modal-header">
                            <div className="m-h-wrapper">
                                <h2>{model.headerText}</h2>
                            </div>
                        </Modal.Header>
                    }
                    <Modal.Content className="modal-content" scrolling={scrolling}>
                        <Modal.Description>
                            <div className="m-c-wrapper">
                                {
                                    typeof model.content === "string" ?
                                        <p>{model.content}</p>
                                        : model.content
                                }
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    {
                        Array.isArray(model.buttons) && model.buttons.length > 0 &&
                        <Modal.Actions className="modal-action">
                            <div className={`m-a-wrapper ${model.buttonAlign}`}>
                                {
                                    model.buttons.map((action, i) => {
                                        return <ViewButton model={action} key={i} />
                                    })
                                }
                            </div>
                        </Modal.Actions>
                    }
                </Modal>
            </React.Fragment >
        )
    }
}

MyModal.defaultProps = {
    model: new ModalModel()
}


export default MyModal