
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewViewEvent from '../../views/event/ViewViewEvent'
import EventManager from '../../business/EventManager'

class ViewEventController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bEvent = new EventManager(this)
	}

	onGetEventInfo = (userEmail, eventId) => {
		return this.bEvent.getEventInfo(userEmail, eventId)
	}

	onCheckIn = (body) => {
		return this.bEvent.checkInAll(body)
	}

	onCheckOut = (userEmail, roomEmail, iCalUId) => {
		return this.bEvent.checkOut(userEmail, roomEmail, iCalUId)
	}

	onResendEventPin = (userEmail, iCalUId) => {
		return this.bEvent.resendEventPin(userEmail, iCalUId)
	}

	onDeleteEvent = (userEmail, eventId) => {
		return this.bEvent.deleteEvent(userEmail, eventId)
	}

	onAcceptEvent = (userEmail, eventId) => {
		return this.bEvent.acceptEvent(userEmail, eventId)
	}
	onTentativeEvent = (userEmail, eventId) => {
		return this.bEvent.tentativeEvent(userEmail, eventId)
	}
	onDeclineEvent = (userEmail, eventId) => {
		return this.bEvent.declineEvent(userEmail, eventId)
	}
	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewViewEvent
					onGetEventInfo={this.onGetEventInfo}

					onCheckIn={this.onCheckIn}
					onCheckOut={this.onCheckOut}
					onResendEventPin={this.onResendEventPin}
					onDeleteEvent={this.onDeleteEvent}

					onAcceptEvent={this.onAcceptEvent}
					onTentativeEvent={this.onTentativeEvent}
					onDeclineEvent={this.onDeclineEvent}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(ViewEventController)