
// import PrivateRoute from '../policy/PrivateRoute'
import PublicRoute from '../policy/PublicRoute'

import LoginController from '../controllers/auth/LoginController'
import ResetPasswordController from '../controllers/user/ResetPasswordController'

let routeConfigs = [
    { route: PublicRoute, path: "/google/authencallback", component: LoginController },

    { route: PublicRoute, path: "/resetpassword/:key", component: ResetPasswordController },
    { route: PublicRoute, path: "/resetpassword", component: ResetPasswordController },

    { route: PublicRoute, path: "/authorize", component: LoginController },
    { route: PublicRoute, path: "/login", component: LoginController },
]

const RouteConfig = {
    module: "",
    routeConfigs,
}

export default RouteConfig