
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../../core/view/common/LabelInput'
import UserModel from '../../models/UserModel'
import InputFile from '../../core/view/common/InputFile'

class ChangeUserInformationForm extends MyForm {

    render() {
        const { t, model } = this.props
        return (
            <React.Fragment>
                {super.render()}
                <div className="module changeUserInformationForm">
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.employeeCode")} isRequire={true} />
                                    <Form.Input
                                        name={"employeeCode"}
                                        value={model.employeeCode}
                                        fluid
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.firstName")} isRequire={true} />
                                    <Form.Input
                                        name={"firstName"}
                                        value={model.firstName}
                                        fluid
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.lastName")} isRequire={true} />
                                    <Form.Input
                                        name={"lastName"}
                                        value={model.lastName}
                                        fluid
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.image")} isRequire={false} />
                                    <InputFile
                                        name={"imageFile"}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}

ChangeUserInformationForm.defaultProps = {
    model: new UserModel(),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ChangeUserInformationForm)