
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import utils from '../core/utility/utils'

const Schema = mongoose.Schema

const ChangePasswordSchema = new Schema({
	newPassword: { type: String, required: [true, "validate.require"], default: "" },
	confirmNewPassword: { type: String, required: [true, "validate.require"], default: "" },
	oldPassword: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(ChangePasswordSchema, "change_password")

ChangePasswordSchema.path("newPassword").validate(async function (newPassword) {
	if (!!newPassword && !!this.oldPassword) {
		return newPassword !== this.oldPassword
	}
	return true
}, "validate.oldNewPasswordMatchError")

ChangePasswordSchema.path("confirmNewPassword").validate(async function (confirmNewPassword) {
	if (!!confirmNewPassword && !!this.newPassword) {
		return confirmNewPassword === this.newPassword
	}
	return true
}, "validate.confirmNewPasswordNotMatchError")

ChangePasswordSchema.methods.serializeAPI = function () {
	try {
		let result = {
			password: utils.encode(this.newPassword),
			confirmpassword: utils.encode(this.confirmNewPassword),
			oldpassword: utils.encode(this.oldPassword),
		}
		return result
	} catch (error) {
		return null
	}
}

const ChangePasswordModel = mongoose.model("change_password", ChangePasswordSchema, "change_password")

export default ChangePasswordModel