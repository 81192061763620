
import mongoose from 'mongoose'
import { defaultValue } from '../constant'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const SearchLocationSchema = new Schema({
	buildingId: { type: String, default: defaultValue.NONE_SELECT },
	building: { type: Object, default: null },

	floorId: { type: String, default: defaultValue.NONE_SELECT },
	floor: { type: Object, default: null },

	zoneId: { type: String, default: defaultValue.NONE_SELECT },
	zone: { type: Object, default: null },

	capacity: { type: Number, default: null },

	facilityIds: [{ type: String }],
	facilities: [{ type: Object }],
}, { _id: false })

setBaseSchema(SearchLocationSchema, "search_location")

SearchLocationSchema.virtual('locationName').get(function () {
	let name = ""
	if (this.building) {
		name += this.building.name
	}
	if (this.floor) {
		if (!name) {
			name += ", "
		}
		name += this.floor.name
	}
	if (this.zone) {
		if (!name) {
			name += ", "
		}
		name += this.zone.name
	}
	if (!name) {
		name = "-"
	}
	return name
})

SearchLocationSchema.methods.serializeAPI = function (key) {
	try {
		let result = {
			keyword: key,
		}
		if (!!this.buildingId && this.buildingId !== defaultValue.NONE_SELECT) {
			result.buildingId = this.buildingId
		}
		if (!!this.floorId && this.floorId !== defaultValue.NONE_SELECT) {
			result.floorId = this.floorId
		}
		if (!!this.zoneId && this.zoneId !== defaultValue.NONE_SELECT) {
			result.zoneId = this.zoneId
		}
		if (!!this.capacity) {
			result.capacity = this.capacity
		}
		if (this.facilityIds.length > 0) {
			result.facilities = this.facilityIds
		}
		return result
	} catch (error) {
		return null
	}
}

const SearchLocationModel = mongoose.model("search_location", SearchLocationSchema, "search_location")

export default SearchLocationModel