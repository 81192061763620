
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const FrontendSettingSchema = new Schema({
	service: { type: String, default: "" },

	// workingStartTime: { type: Date, default: moment() },
	// workingEndTime: { type: Date, default: moment() },

	slotDuration: { type: Number, default: 15 },
	modMinute: { type: Number, default: 30 },
	advanceOrderTime: 0,
	advanceBookingDate: { type: Number, default: 30 },
	maximumBookingMinutes: { type: Number, default: 30 },

	default_conference: { type: String, default: "" },
	isEnableConferenceType: { type: Boolean, default: false },
	isEnableEventType: { type: Boolean, default: false },

	administratorId: { type: String, default: "" },
	administratorEmail: { type: String, default: "" },
	administratorTel: { type: String, default: "" },
	housekeeperName: { type: String, default: "" },
	housekeeperEmail: { type: String, default: "" },
	housekeeperTel: { type: String, default: "" },

	//BOT
	// bot_is_sync: { type: Boolean, default: false },
	// bot_web_hook_url: { type: String, default: "" },

	//CMR
	// cmr_is_sync: { type: Boolean, default: false },
	// cmr_server_url: { type: String, default: "" },
	// cmr_site_name: { type: String, default: "" },
	// cmr_user: { type: String, default: "" },
	// cmr_password: { type: String, default: "" },

	//EWS
	// ewsUsername: { type: String, default: "" },
	// ewsPassword: { type: String, default: "" },
	// ewsHost: { type: String, default: "" },
	// ewsAuthType: "basic",

	//AD
	// isUseActiveDirectory: { type: Boolean, default: false },
	// activeDirectoryIP: "10.0.30.20",
	// activeDirectoryPort: "389",
	// activeDirectoryAdminUser: "serviceaccount@bainisys2013.com",
	// activeDirectoryAdminPassword: "QXBwbGUxMjM=",
	// activeDirectoryDomain: "bainisys2013",
	// activeDirectoryBaseDN: "dc=bainisys2013,dc=com",

	//TMS
	// isUseTMS: { type: Boolean, default: false },
	// tms_server: { type: String, default: "" },
	// tms_user: { type: String, default: "" },
	// tms_password: { type: String, default: "" },
	// tms_user_prefix: { type: String, default: "" },
	// tms_conference_type: { type: String, default: "" },

	//MQTT
	// mqttOpenBefore: { type: Number, default: 30 },
	// mqttCloseAfter: { type: Number, default: 30 },

	//Light
	// isEnableDimLight: { type: Boolean, default: false },
	// fullBrightStart: { type: Date, default: moment() },
	// fullBrightEnd: { type: Date, default: moment() },

	//Backend
	// isSigninAndUpsertUser: { type: Boolean, default: true },
	// isImportUserFromO365: { type: Boolean, default: true },
	// isImportUserFromExcel: { type: Boolean, default: false },
	// isImportUserFromAD: { type: Boolean, default: true },
	// allowSigninDomain: { type: String, default: "" },
	// isSyncRoleAndPolicy: { type: Boolean, default: false },
	// defaultRoleId: { type: String, default: "" },
	// prevDayForCheckLockUser: { type: Number, default: 7 },
	// lockDay: { type: Number, default: 1 },
	// lockTime: { type: Number, default: 3 },
	// imageLogoId: { type: String, default: "" },
	// isUseEventPin: { type: Boolean, default: true },
	// isUseConCurrent: { type: Boolean, default: false },
	// tokenExpireDate: { type: Number, default: 30 },
	// releaseTime: { type: Number, default: 15 },
	// checkinTime: { type: Number, default: 15 },
	// isUseCheckinNotification: { type: Boolean, default: true },
	// isSendReminder: { type: Boolean, default: true },
	// reminderTime: { type: Number, default: 2 },
}, { _id: false })

setBaseSchema(FrontendSettingSchema, "frontend_setting")

FrontendSettingSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			if (!!data.service) {
				this.service = data.service.toUpperCase()
			}
			if (!isNaN(data.slotDuration)) {
				this.slotDuration = data.slotDuration
			}
			if (!isNaN(data.modMinute)) {
				this.modMinute = data.modMinute
			}
			if (!isNaN(data.advanceOrderTime)) {
				this.advanceOrderTime = data.advanceOrderTime
			}
			if (!isNaN(data.advanceBookingDate)) {
				this.advanceBookingDate = data.advanceBookingDate
			}
			if (!isNaN(data.maximumBookingMinutes)) {
				this.maximumBookingMinutes = data.maximumBookingMinutes
			}
			this.default_conference = data.default_conference
			if (typeof data.isEnableConferenceType === "boolean") {
				this.isEnableConferenceType = data.isEnableConferenceType
			}
			if (typeof data.isEnableEventType === "boolean") {
				this.isEnableEventType = data.isEnableEventType
			}
			this.administratorId = data.administratorId
			this.administratorEmail = data.administratorEmail
			this.administratorTel = data.administratorTel
			this.housekeeperName = data.housekeeperName
			this.housekeeperEmail = data.housekeeperEmail
			this.housekeeperTel = data.housekeeperTel
		}
	} catch (error) {

	}
}

const FrontendSettingModel = mongoose.model("frontend_setting", FrontendSettingSchema, "frontend_setting")

export default FrontendSettingModel