
import axios from 'axios'
import CookiesManager from './CookiesManager'
import { cookiesKey } from '../../constant'
import { refreshToken } from '../../business/AuthVerifyManager'

export default class MyManager {

	constructor(controller) {
		this.controller = controller

		this.requestMethod = {
			GET: "GET",
			POST: "POST",
			PATCH: "PATCH",
			DELETE: "DELETE",
		}

		this.statusForceSuccess = [204]

		this.bCookie = new CookiesManager()
	}

	async requestAction(options, callback) {
		let isHandleError = true
			, isReToken = true
			, defaultResponse = {
				result: false,
				error: null
			}
			, defaultError = {
				code: "",
				message: "",
			}
		try {
			if (!options) {
				throw new Error("Invalid parameters")
			}

			if ("isHandleError" in options && typeof options.isHandleError === "boolean") {
				isHandleError = options.isHandleError
				delete options.isHandleError
			}

			if ("isReToken" in options && typeof options.isReToken === "boolean") {
				isReToken = options.isReToken
				delete options.isReToken
			}

			if (isReToken === true) {
				await refreshToken()
			}

			let token = this.bCookie.getData(cookiesKey.TOKEN)
			if (token && (options.useToken || !("useToken" in options))) {
				if (!options.headers) {
					options.headers = {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					}
				} else {
					options.headers.Authorization = `Bearer ${token}`
				}
			}

			let res = await axios(options)

			if (!res.data.result && Array.isArray(res.data.errors) && res.data.errors.length > 0) {
				if (isHandleError) {
					this.setDefaultModalError(res.data.errors)
				}
			}
			let response = {
				...defaultResponse
			}
			if (!!res.status && this.statusForceSuccess.includes(res.status)) {
				response.result = true
				return response
			}

			if (!res.data) {
				throw new Error("Something went wrong. Please try again later.")
			}

			if (!!res.data) {
				if ("value" in res.data) {
					response.result = true
					let value = res.data.value
					if (Array.isArray(value)) {
						response.datas = value
					} else {
						response.data = value
					}
				} else if ("code" in res.data || "message" in res.data) {
					response.result = false
					let error = {
						...defaultError
					}
					error.code = res.data.code
					error.message = res.data.message
					response.error = error
				} else {
					response.result = true
					if (Array.isArray(res.data)) {
						response.datas = res.data
					} else {
						response.data = res.data
					}
				}
			}

			if (callback && typeof callback === "function") {
				callback(response)
			}

			return response
		} catch (error) {
			let response = {
				...defaultResponse
			},
				resError = {
					...defaultError
				}

			response.result = false
			if (!!error.response) {
				let apiResponse = error.response
				if ((typeof apiResponse.data === "object" && !Array.isArray(apiResponse.data)) && ("code" in apiResponse.data || "message" in apiResponse.data)) {
					resError.code = apiResponse.data.code
					resError.message = apiResponse.data.message
				} else if ("status" in apiResponse) {
					resError.code = apiResponse.status
					resError.message = "Something went wrong. Please try again later."
				}
			} else {
				resError.code = `10000000`
				resError.message = "Something went wrong. Please try again later."
			}

			if (isHandleError) {
				this.setDefaultModalError(resError)
			}
			response.result = false
			response.error = resError
			if (callback && typeof callback === "function") {
				callback(response)
			}
			return response
		}
	}

	setDefaultModalError(error) {
		if (this.controller && typeof this.controller.setDefaultModalError === "function") {
			this.controller.setDefaultModalError(error)
		} else {
			throw error
		}
	}
}