
import React from 'react'
// import { Container } from 'semantic-ui-react'
// import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import MyPage from '../../core/components/MyPage'

class BoxLoadingComponent extends MyPage {
    render() {
        try {
            const { color, message, inverted } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <div className={`module boxLoadingComponent ${inverted ? "inverted" : ""}`}>
                        {this.getLoadingComponent(color, message)}
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


BoxLoadingComponent.defaultProps = {
    color: "",
    message: "",
    inverted: false
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(BoxLoadingComponent)