
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Input } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import config from '../../config'
import ItemRoom from './ItemRoom'

class ViewRoomList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.searchText = ""
        this.state.rooms = []
    }

    componentDidMount() {
        this.onSearch()
    }

    onSearch = () => {
        const { searchText } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                rooms: []
            },
                action = () => {

                }

            let res = await this.props.onGetRoomList(searchText)
            if (!!res && res.result === true && Array.isArray(res.datas)) {
                tempState.rooms = res.datas
            }
            this.setState(tempState, action)
        })
    }

    onClickView = (room) => {
        this.props.history.push(`/room/view?roomEmail=${encodeURIComponent(room.email)}`)
    }

    render() {
        const { t } = this.props
        const { isLoading, rooms, searchText } = this.state
        // var startTime = "21:00"
        // var durations = [30, 60, 90, 120]
        // var rooms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewRoomList">
                        <div className="view-room-list-bg" style={{ backgroundImage: `url("${config.publicUrl}/assets/images/bg_booking.jpg")` }}></div>
                        {/* <div className="view-room-list-body"></div> */}
                        <Container text>
                            <div className="search-room" >

                                <div className="room-header">
                                    <h1>{t("room.roomList")}</h1>
                                    <Form>
                                        <Input
                                            icon="search"
                                            fluid
                                            name="searchText"
                                            value={searchText}
                                            onChange={this.handleChange}
                                        />
                                    </Form>
                                    <ViewButton
                                        model={new ButtonModel({
                                            color: "blue",
                                            text: t("common.search"),
                                            onClick: this.onSearch
                                        })}
                                    />
                                </div>
                            </div>
                        </Container>
                        <Container>
                            <div className="list-room">
                                {
                                    rooms.map((room, i) => {
                                        return <ItemRoom key={i}
                                            model={room}
                                            buttons={[
                                                new ButtonModel({
                                                    color: "blue",
                                                    text: t("common.view"),
                                                    onClick: () => this.onClickView(room)
                                                })
                                            ]}
                                        />
                                    })
                                }
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}

ViewRoomList.defaultProps = {
    onGetRoomList: () => {
        console.error("Please provide Get Room List action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewRoomList)