
import React from 'react'
import MyPage from '../../core/components/MyPage'
// import { Menu } from 'semantic-ui-react'
// import Footer from './Footer'
import LayoutBlank from './LayoutBlank'
// import Header from './Header'

class LayoutWithHeaderFooter extends MyPage {
    render() {
        try {
            const { header, footer } = this.props
            return (
                <LayoutBlank>
                    <div className="module layoutWithHeaderFooter">
                        <div className={`lwhf-body`}>
                            {
                                header &&
                                <div className="lwfr-header">
                                    {header}
                                </div>
                            }
                            {this.props.children}
                            {
                                footer &&
                                <div className="lwfr-footer">
                                    {footer}
                                </div>
                            }
                        </div>
                    </div>
                </LayoutBlank>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


LayoutWithHeaderFooter.defaultProps = {
    header: null,
    footer: null,
}



export default LayoutWithHeaderFooter