import React from 'react'
import { translate } from 'react-switch-lang'
import { Form } from 'semantic-ui-react'
import MyComponent from '../../components/MyComponent'
import FileModel from '../../model/FileModel'

let fileTypeImage = ["image/png", "image/jpeg", "image/jpg"]
const TIME_GAP = 1000

class InputFile extends MyComponent {

    constructor(props) {
        super(props)
        this.state.isLoadingSelectFile = false
        this.state.fileSelect = null
    }

    inputFileRef = React.createRef()

    onOpenBrowseFile = (e) => {
        e.preventDefault();
        this.inputFileRef.current.click();
        // e.stopPropagation();
    }

    handleChangeFile = (e) => {
        const { fileTypes } = this.props
        e.persist();

        this.setState({
            isLoadingSelectFile: true
        }, async () => {
            let datas = null

            try {
                let files = e.target.files;
                datas = await new Promise((reslove, reject) => {
                    try {
                        var allFiles = []
                        for (var i = 0; i < files.length; i++) {

                            let file = files[i]
                                , type = file.type
                                , extension = type.split('/')[1]

                            // Make new FileReader
                            let reader = new FileReader();

                            // Convert the file to base64 text
                            reader.readAsDataURL(file);

                            // on reader load somthing...
                            reader.onload = () => {
                                try {
                                    // Make a fileInfo Object
                                    let contentBase64 = reader.result

                                    let fileInfo = {
                                        fileName: file.name,
                                        size: file.size,
                                        extension: extension,
                                        mimeType: type,
                                        content: contentBase64,
                                    };

                                    let model = new FileModel(fileInfo)

                                    // Push it to the state
                                    allFiles.push(model);
                                    if (allFiles.length === files.length) {
                                        setTimeout(() => {
                                            return reslove(allFiles)
                                        }, TIME_GAP)
                                    }
                                } catch (error) {
                                    return reject(error)
                                }

                            } // reader.onload

                        } // for

                    } catch (error) {
                        return reject(error)
                    }
                })
            } catch (error) {

            }


            let body = {
                isLoadingSelectFile: false
            },
                action = () => { }

            if (Array.isArray(datas) && datas.length > 0) {
                let fileInfo = datas[0]
                    , fileType = fileInfo.mimeType
                let tempFileType = Array.isArray(fileTypes) ? fileTypes : []
                if (tempFileType.length === 0 || tempFileType.includes(fileType)) {
                    body.fileSelect = fileInfo
                    action = () => this.props.onChange(null, { name: this.props.name, value: fileInfo })
                }
            }

            this.setState(body, action)
        })
    }

    render() {
        const { t, fileTypes, isPreview } = this.props
        const { isLoadingSelectFile, fileSelect } = this.state

        let action = {
            color: "teal",
            content: t("common.browseFile"),
            onClick: this.onOpenBrowseFile
        }
        if (isLoadingSelectFile) {
            action.loading = true
            action.disabled = true
        }

        let tempIsPreview = (isPreview && (!!fileSelect && fileTypeImage.includes(fileSelect.mimeType)))
        return (
            <React.Fragment>
                <div className="module inputFile">
                    <div className="input-file-wrapper">
                        <div className="box-input-display">
                            <Form>
                                <Form.Input
                                    fluid
                                    disabled
                                    action={action}
                                    actionPosition="right"
                                    value={!!fileSelect ? fileSelect.fileName : ""}
                                />
                            </Form>
                        </div>
                        <div className="box-input-file">
                            <input name="file" ref={this.inputFileRef} type="file" accept={fileTypes.join(", ")} onChange={this.handleChangeFile} />
                        </div>
                        {
                            tempIsPreview &&
                            <div className="box-show-image">
                                <div className="image-render">
                                    <img src={fileSelect.content} alt="Select" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

InputFile.defaultProps = {
    name: "",
    isPreview: false,
    fileTypes: [],
    onChange: () => console.error("Please provide On Change Image action.")
}


export default translate(InputFile)