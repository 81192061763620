
import React from 'react'
import { Container } from 'semantic-ui-react'
// import config from '../../config'
import { translate, getLanguage } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
// import config from '../../config'
import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import TermsModel from '../../models/TermsModel'

class ViewTerms extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.model = new TermsModel()
    }

    componentDidMount() {
        this.onGetData()
    }

    onGetData = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {

                }

            let res = await this.props.onGetTerms()
            if (!!res && res.result === true && res.data instanceof TermsModel) {
                tempState.model = res.data
            }
            this.setState(tempState, action)
        })
    }

    render() {
        const { t } = this.props
        const { model } = this.state
        let content = model.getContentByLanguage(getLanguage())
        return (
            <React.Fragment>
                {super.render()}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewTerms">
                        <Container text>
                            <div className="terms-wrapper">
                                <div className="terms-header">
                                    <h1>{t("common.termsAndConditions")}</h1>
                                </div>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment>
        )
    }
}


ViewTerms.defaultProps = {
    onGetTerms: () => {
        console.error("Please provide Get Terms action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ViewTerms)