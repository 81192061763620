
import mongoose from 'mongoose'
import { setBaseSchema } from '../schema/Schema'

const Schema = mongoose.Schema

const ModalSchema = new Schema({
    open: { type: Boolean, default: true },
    type: { type: String, default: "INFO", enum: ["INFO", "WARNING", "ERROR"] },
    size: { type: String, default: "tiny", enum: ["mini", "tiny", "small", "large", "fullscreen"] },
    onClose: { type: Object, default: null },
    headerText: { type: String, default: "" },
    content: { type: Object, default: null },
    buttonAlign: { type: Object, default: "right", enum: ["left", "center", "right"] },
    buttons: { type: Object, default: null },
    externalProps: { type: Object, default: null },
}, { _id: false })

setBaseSchema(ModalSchema, "modal")

const ModalModel = mongoose.model("modal", ModalSchema, "modal")

export default ModalModel