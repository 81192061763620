
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import utils from '../core/utility/utils'

const Schema = mongoose.Schema

const LocalLoginSchema = new Schema({
	isRemember: { type: String, default: false },
	username: { type: String, required: [true, "validate.require"], default: "" },
	password: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(LocalLoginSchema, "login")

LocalLoginSchema.methods.serializeAPI = function () {
	try {
		let result = {
			email: this.username,
			password: utils.encode(this.password),
		}
		return result
	} catch (error) {
		return null
	}
}

const LocalLoginModel = mongoose.model("login", LocalLoginSchema, "login")

export default LocalLoginModel