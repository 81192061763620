
import { CORE_API_ENDPOINT, O365_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import EventModel from '../models/EventModel'
import EventTypeModel from '../models/EventTypeModel'
import RoomFormatModel from '../models/RoomFormatModel'
import CalendarSlotModel from '../models/CalendarSlotModel'

export default class EventManager extends MyManager {

	async getEventCalendar(userEmail, dateStr) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${O365_API_ENDPOINT}/calendar/events/day/${encodeURIComponent(userEmail)}/${dateStr}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(event => {
					let model = new EventModel()
					model.setData(event)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getEventInfo(userEmail, eventId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${O365_API_ENDPOINT}/calendar/event/${encodeURIComponent(userEmail)}/${encodeURIComponent(eventId)}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new EventModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getEventType() {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/eventType`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(type => {
					let model = new EventTypeModel()
					model.setData(type)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getEventRoomFormat() {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/eventRoomFormat`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(type => {
					let model = new RoomFormatModel()
					model.setData(type)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}

	async createEvent(userEmail, body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/calendar/event/unique/${encodeURIComponent(userEmail)}`,
				data: body
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new EventModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async updateEvent(eventId, organizerAddress, body) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${O365_API_ENDPOINT}/calendar/event/${encodeURIComponent(organizerAddress)}/${encodeURIComponent(eventId)}`,
				data: body
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new EventModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getCalendarSlot(roomEmail, dateStr) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.GET,
				url: `${O365_API_ENDPOINT}/calendar/slot/${encodeURIComponent(roomEmail)}/${dateStr}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(event => {
					let model = new CalendarSlotModel()
					model.setData(event)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}

	async checkIn(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/checkin/unique`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async checkInAll(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/checkin/unique/all`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async checkOut(userEmail, roomEmail, iCalUId) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${O365_API_ENDPOINT}/calendar/event/checkout/${userEmail}/${roomEmail}/${encodeURIComponent(iCalUId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async resendEventPin(organizerAddress, iCalUId) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/calendar/resend/pin/${encodeURIComponent(organizerAddress)}/${encodeURIComponent(iCalUId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async deleteEvent(organizerEmail, eventId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.DELETE,
				url: `${O365_API_ENDPOINT}/calendar/event/unique/${encodeURIComponent(organizerEmail)}/${encodeURIComponent(eventId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}

	async acceptEvent(userEmail, eventId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/calendar/accept/${encodeURIComponent(userEmail)}/${encodeURIComponent(eventId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}
	async declineEvent(userEmail, eventId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/calendar/decline/${encodeURIComponent(userEmail)}/${encodeURIComponent(eventId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}
	async tentativeEvent(userEmail, eventId) {
		try {
			const options = {
				// isHandleError: false,
				method: this.requestMethod.POST,
				url: `${O365_API_ENDPOINT}/calendar/tentatively/${encodeURIComponent(userEmail)}/${encodeURIComponent(eventId)}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return null
		}
	}
}