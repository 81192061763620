
import React from 'react'
import MyPage from '../../core/components/MyPage'
// import { Container } from 'semantic-ui-react'
// import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ViewButton from '../../core/view/common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import EventModel from '../../models/EventModel'
import { Icon } from 'semantic-ui-react'
import ModalModel from '../../core/model/ModalModel'

class ModalCreateUpdateEventSuccess extends MyPage {
    render() {
        try {
            const { t, model, onClickView, onClickMyReservation } = this.props
            if (!(model instanceof EventModel)) {
                return null
            }

            return (
                <React.Fragment>
                    {this.getModalContent(new ModalModel({
                        headerText: t("event.SuccessfullyReserved"),
                        size: "tiny",
                        content: <div className="module modalCreateUpdateEventSuccess">
                            <div className="modal-create-update-event-success">
                                <div className="event-detail">
                                    <Icon name="check" color="green" circular inverted />
                                </div>
                                <div className="event-action">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.view"),
                                            color: "blue",
                                            onClick: onClickView
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("event.myReservations"),
                                            color: "blue",
                                            onClick: onClickMyReservation
                                        })}
                                    />
                                </div>
                            </div>
                        </div>,
                        buttonAlign: "center",
                        // buttons: [
                        // new ButtonModel({
                        //     text: t("common.view"),
                        //     color: "blue",
                        //     onClick: onClickView
                        // }),
                        // new ButtonModel({
                        //     text: t("event.myReservations"),
                        //     color: "blue",
                        //     onClick: onClickMyReservation
                        // })
                        // ]
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ModalCreateUpdateEventSuccess.defaultProps = {
    model: new EventModel(),
    onClickView: () => console.error("Please provide View action."),
    onClickMyReservation: () => console.error("Please provide My Reservations action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ModalCreateUpdateEventSuccess)