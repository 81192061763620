
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewLogin from '../../views/auth/ViewLogin'
import SettingManager from '../../business/SettingManager'
import AuthManager from '../../business/AuthManager'
import { afterVerify } from '../../business/AuthVerifyManager'
import { cookiesKey } from '../../constant'

class LoginController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
		this.bAuth = new AuthManager(this)
	}

	onGetSetting = () => {
		return this.bSetting.getO365Setting()
	}

	onVerifyLocal = (model) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bAuth.verifyLocal(model.serializeAPI())
			if (!!res && res.result === true) {
				action = () => {
					afterVerify(res.data, model.isRemember)
				}

			}
			this.setState(tempState, action)
		})
	}

	onVerifyO365 = (code, callback) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bAuth.verifyO365({ code }, callback)
			if (!!res && res.result === true) {
				tempState.isLoading = true
				action = () => {
					let isRemember = false
					if (typeof this.bCookie.getData(cookiesKey.IS_REMEMBER) === "boolean") {
						isRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
					}
					afterVerify(res.data, isRemember)
				}

			}
			this.setState(tempState, action)
		})
	}

	onVerifyGoogle = (code, callback) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bAuth.verifyGoogle({ code }, callback)
			if (!!res && res.result === true) {
				tempState.isLoading = true
				action = () => {
					let isRemember = false
					if (typeof this.bCookie.getData(cookiesKey.IS_REMEMBER) === "boolean") {
						isRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
					}
					afterVerify(res.data, isRemember)
				}

			}
			this.setState(tempState, action)
		})
	}

	onVerifyExchange2010 = (model) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bAuth.verifyExchange2010(model.serializeAPI())
			if (!!res && res.result === true) {
				tempState.isLoading = true
				action = () => {
					afterVerify(res.data, model.isRemember)
				}
			}
			this.setState(tempState, action)
		})
	}

	onVerifyExchange2013 = (model) => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false,
			},
				action = () => {

				}
			let res = await this.bAuth.verifyExchange2013(model.serializeAPI())
			if (!!res && res.result === true) {
				tempState.isLoading = true
				action = () => {
					afterVerify(res.data, model.isRemember)
				}

			}
			this.setState(tempState, action)
		})
	}

	render() {
		const { isLoading } = this.state
		return (
			<React.Fragment>
				{super.render()}
				{isLoading && this.getLoadingPage()}
				<ViewLogin
					isLoadingController={isLoading}
					onGetSetting={this.onGetSetting}
					onVerifyLocal={this.onVerifyLocal}
					onVerifyO365={this.onVerifyO365}
					onVerifyGoogle={this.onVerifyGoogle}
					onVerifyExchange2010={this.onVerifyExchange2010}
					onVerifyExchange2013={this.onVerifyExchange2013}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(LoginController)