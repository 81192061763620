
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyPage from '../../core/components/MyPage'
import LayoutBlankHeightMax from '../layout/LayoutBlank'
import config from '../../config'
import { Container, Icon } from 'semantic-ui-react'
import { colorCode } from '../../constant'

var timeOutPage = null
class ViewResetPassword extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.resConfirmResetPassword = null
        this.state.key = this.getParamFromProps("key")
    }

    componentDidMount() {
        if (!!this.state.key) {
            this.bCookie.removeAll()
            this.onConfirmResetPassword()
        } else {
            this.onGoHome()
        }
    }

    componentWillUnmount() {
        clearTimeout(timeOutPage)
    }

    onConfirmResetPassword = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false
            },
                action = () => { }
            let res = await this.props.onConfirmResetPassword(this.state.key)
            tempState.resConfirmResetPassword = res
            action = () => {
                timeOutPage = setTimeout(() => {
                    this.onGoHome()
                }, 10000);
            }
            this.setState(tempState, action)
        })
    }

    onGoHome = () => {
        this.props.history.replace("/")
    }

    render() {
        const { t } = this.props
        const { isLoading, resConfirmResetPassword } = this.state

        return (
            <React.Fragment>
                {super.render()}
                <LayoutBlankHeightMax>
                    <div className="module viewResetPassword">
                        <div className="view-reset-password-wrapper" style={{ backgroundImage: `url(${config.publicUrl}/assets/images/bg_login.jpg)` }}>
                            <div className="view-reset-password-bg-layer" >
                                <Container text>
                                    <div className="box-content">
                                        <div className="box-content-wrapper">
                                            <div className="content-logo">
                                                <img src={`${config.publicUrl}/assets/images/logo.png`} alt="System" />
                                            </div>
                                            {
                                                isLoading &&
                                                <React.Fragment>
                                                    <div className="content-loading">
                                                        {this.getLoadingComponent(colorCode.SECONDARY)}
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {
                                                isLoading === false &&
                                                <React.Fragment>
                                                    {
                                                        !!resConfirmResetPassword && resConfirmResetPassword.result === true ?
                                                            <div className="content-success">
                                                                <Icon circular inverted name="check" color="green" size="big" />
                                                                <p>{t("message.resetPasswordSuccess")}</p>
                                                                <p>{t("message.pleaseCheckYourEmail")}</p>
                                                                <p>{t("message.redirectToLoginPage")}</p>
                                                                <p>{t("message.ifBrowserNotRedirect")} <b onClick={this.onGoHome}>{t("common.clickHere")}</b></p>
                                                            </div>
                                                            :
                                                            <div className="content-fail">
                                                                <Icon circular inverted name="close" color="red" size="big" />
                                                                <p>{t("message.resetPasswordFail")}</p>
                                                                <p>{t("message.redirectToLoginPage")}</p>
                                                                <p>{t("message.ifBrowserNotRedirect")} <b onClick={this.onGoHome}>{t("common.clickHere")}</b></p>
                                                            </div>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </LayoutBlankHeightMax >
            </React.Fragment >
        )
    }
}

ViewResetPassword.defaultProps = {
    onConfirmResetPassword: () => {
        console.error("Please provide Confirm Reset Password action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewResetPassword)