
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container } from 'semantic-ui-react'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'
import ViewButton from '../../core/view/common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ModalModel from '../../core/model/ModalModel'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'
import moment from 'moment'
import utils from '../../core/utility/utils'
import FrontendSettingModel from '../../models/FrontendSettingModel'
import ItemRoom from './ItemRoom'
import { cookiesKey, dateTimeFormat, eventType } from '../../constant'
import UserModel from '../../models/UserModel'
import RoomStatusModel from '../../models/RoomStatusModel'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import { CORE_API_ENDPOINT } from '../../lib'
import ItemEvent from '../event/ItemEvent'
import LabelInput from '../../core/view/common/LabelInput'
import InputTimePicker from '../../core/view/common/InputTimePicker'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import EventModel from '../../models/EventModel'

const eventCalendarType = {
    YOUR_SELECT: "YOUR_SELECT",
    EVENT: "EVENT",
    ROOM_DISABLE: "ROOM_DISABLE",
}

class ViewRoomInfo extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isLoadingSlot = false
        this.state.isLoadingCanBooking = false
        this.state.roomEmail = this.getQueryFromProps("roomEmail")
        this.state.userCookie = this.bCookie.getData(cookiesKey.USER)

        this.state.controlBookingDateTime = {
            date: moment(),
            startTime: moment(),
            endTime: moment()
        }

        this.state.setting = null
        this.state.roomStatus = null
        this.state.user = null

        this.state.roomInfo = null
        this.state.currentEvent = null
        this.state.nextEvent = null

        this.state.calendarSlots = []

        this.state.canBooking = false

        this.state.modalCustom = null
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { roomEmail, userCookie, controlBookingDateTime } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                setting: null,
                controlBookingDateTime: controlBookingDateTime
            },
                action = () => {

                },
                actionSetting = () => {

                },
                actionRoomInfo = () => {

                }

            let [resSetting, resRoomStatus, resUser] = await Promise.all([
                this.props.onGetSetting(),
                this.props.onGetRoomStatus(roomEmail),
                this.props.onGetUserInfo(userCookie.id)
            ])
            if (!!resSetting && resSetting.data instanceof FrontendSettingModel) {
                tempState.setting = resSetting.data
                tempState.controlBookingDateTime.startTime = utils.getStartDateTimeByStep(moment(), resSetting.data.slotDuration)
                tempState.controlBookingDateTime.endTime = moment(tempState.controlBookingDateTime.startTime).add(resSetting.data.slotDuration, "m")
                actionSetting = () => {
                    this.calendarScrollToTime(moment(tempState.controlBookingDateTime.startTime).subtract(resSetting.data.slotDuration, "m"))
                    this.onGetCalendarSlot()
                }
            }
            if (!!resRoomStatus && resRoomStatus.data instanceof RoomStatusModel) {
                let roomStatus = resRoomStatus.data
                tempState.roomStatus = roomStatus
                tempState.roomInfo = roomStatus.roomInfo
                tempState.currentEvent = roomStatus.currentEvent
                if (Array.isArray(roomStatus.eventsNext) && roomStatus.eventsNext.length > 0) {
                    tempState.nextEvent = roomStatus.eventsNext[0]
                }
                if (!!roomStatus.roomInfo) {
                    actionRoomInfo = () => {
                        this.onGetCanBooking(roomStatus.roomInfo.id)
                    }
                }
            }
            if (!!resUser && resUser.data instanceof UserModel) {
                tempState.user = resUser.data
            }
            action = () => {
                actionSetting()
                actionRoomInfo()
            }
            this.setState(tempState, action)
        })
    }

    onRefreshEvent = () => {
        const { roomEmail } = this.state
        this.setState({
            isLoading: false
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {

                }

            let resRoomStatus = await this.props.onGetRoomStatus(roomEmail)
            if (!!resRoomStatus && resRoomStatus.data instanceof RoomStatusModel) {
                let roomStatus = resRoomStatus.data
                tempState.roomStatus = roomStatus
                // tempState.roomInfo = roomStatus.roomInfo
                tempState.currentEvent = roomStatus.currentEvent
                if (Array.isArray(roomStatus.eventsNext) && roomStatus.eventsNext.length > 0) {
                    tempState.nextEvent = roomStatus.eventsNext[0]
                }
            }
            this.setState(tempState, action)
        })
    }

    handleChangeControl = (e, { name, value }) => {
        let controlBookingDateTime = this.state.controlBookingDateTime
            , action = () => {

            }
        controlBookingDateTime[name] = value
        if (name === "date") {
            action = () => {
                this.calendarChangeDate(value)
                this.onGetCalendarSlot(value)
            }
        }
        // else if (name === "startTime") {
        //     let startTime = moment(value)
        //         , endTime = moment(controlBookingDateTime.endTime)
        //     if (startTime.isSameOrAfter(endTime)) {

        //     }
        // }
        this.setState({
            controlBookingDateTime
        }, action)
    }

    onGetCalendarSlot = (date) => {
        const { roomEmail } = this.state
        this.setState({
            isLoadingSlot: true,
            calendarSlots: []
        }, async () => {
            let tempState = {
                isLoadingSlot: false,
                calendarSlots: []
            },
                action = () => {

                }

            let res = await this.props.onGetCalendarSlot(roomEmail, moment(date).format("YYYYMMDD"))
            if (!!res && res.result === true && Array.isArray(res.datas)) {
                tempState.calendarSlots = res.datas
            }
            this.setState(tempState, action)
        })
    }

    onGetCanBooking = (roomId) => {
        this.setState({
            isLoadingCanBooking: true
        }, async () => {
            let tempState = {
                isLoadingCanBooking: false,
                canBooking: false
            },
                action = () => {

                }

            let res = await this.props.onGetCanBooking(roomId)
            if (!!res && res.result === true && typeof res.data === "boolean") {
                tempState.canBooking = res.data
            }
            this.setState(tempState, action)
        })
    }

    onClickBook = () => {
        try {
            const { roomEmail, controlBookingDateTime } = this.state
            let date = moment(controlBookingDateTime.date)
            let selectStart = moment(date.format("YYYY-MM-DD") + moment(controlBookingDateTime.startTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
                , selectEnd = moment(date.format("YYYY-MM-DD") + moment(controlBookingDateTime.endTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
            let url = `/event/create?roomEmails=${encodeURIComponent(roomEmail)}&start=${selectStart.format(dateTimeFormat.apiFullDateTime)}&end=${selectEnd.format(dateTimeFormat.apiFullDateTime)}`
            this.props.history.push(url)
        } catch (error) {

        }
    }

    //Full calendar
    calendarRef = React.createRef()

    slotLabelContent = (slot) => {
        return moment(slot.date).format("HH:mm")
    }
    dayHeaderContent = (day) => {
        return moment(day.date).format(dateTimeFormat.displayDate)
    }
    calendarScrollToTime = (time) => {
        let calendarApi = this.calendarRef.current.getApi()
        calendarApi.scrollToTime(moment(time).format("HH:mm"))
    }
    calendarChangeDate = (date) => {
        let calendarApi = this.calendarRef.current.getApi()
        calendarApi.gotoDate(moment(date).format("YYYY-MM-DD"))
    }
    eventContent = (eventCalendar) => {
        const { event } = eventCalendar
        let start = moment(event.start)
            , end = moment(event.end)
            , duration = moment.duration(end.diff(start))
            , minEvent = duration.asMinutes()
            , sizeText = "small"
        if (minEvent > 15) {
            sizeText = "big"
        }
        return <div className="module itemEventCalendar">
            <div className={`item-event-wrapper ${event.extendedProps.eventType}`}>
                <div className={`event-text ${sizeText}`}>
                    <p>{start.format("HH:mm")} - {end.format("HH:mm")}</p>
                    <p>{event.title}</p>
                </div>
            </div>
        </div>
    }
    generateEventCalendar = (isRenderBooking, control, calendarSlots) => {
        try {
            const { t } = this.props
            let events = []
            if (isRenderBooking && !!control) {
                let date = moment(control.date)
                let selectStart = moment(date.format("YYYY-MM-DD") + moment(control.startTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
                    , selectEnd = moment(date.format("YYYY-MM-DD") + moment(control.endTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
                if (selectEnd.isAfter(selectStart)) {
                    events.push({
                        title: t("event.yourSelectedTime"),
                        start: selectStart.format("YYYY-MM-DD HH:mm"),
                        end: selectEnd.format("YYYY-MM-DD HH:mm"),
                        extendedProps: {
                            eventType: eventCalendarType.YOUR_SELECT
                        }
                    })
                }
            }
            if (Array.isArray(calendarSlots) && calendarSlots.length > 0) {
                let slotsWithEvent = calendarSlots.filter(c => c.event instanceof EventModel)
                if (slotsWithEvent.length > 0) {
                    slotsWithEvent.forEach(slot => {
                        const { event } = slot
                        let objEvent = {
                            title: event.subject,
                            start: moment(event.startDateTime).format("YYYY-MM-DD HH:mm"),
                            end: moment(event.endDateTime).format("YYYY-MM-DD HH:mm"),
                            extendedProps: {
                                eventType: eventCalendarType.EVENT
                            }
                        }
                        if (event.eventType === eventType.ROOMDISABLE) {
                            objEvent.extendedProps.eventType = eventCalendarType.ROOM_DISABLE
                        }
                        events.push(objEvent)
                    });
                }
            }
            return events
        } catch (error) {
            console.error(error)
            return []
        }
    }
    validateYourSelect = (isRoomDisable, control, calendarSlots) => {
        const { t } = this.props
        try {
            if (isRoomDisable) {
                return t("room.roomDisabled")
            }
            if (!control) {
                return t("message.pleaseSelectTime")
            }
            let date = moment(control.date)
            let selectStart = moment(date.format("YYYY-MM-DD") + moment(control.startTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
                , selectEnd = moment(date.format("YYYY-MM-DD") + moment(control.endTime).format("HH:mm"), "YYYY-MM-DDHH:mm")
            if (selectEnd.isSameOrBefore(selectStart)) {
                return t("message.endTimeGreaterThanStartTime")
            }
            let eventsBooking = []
                , eventsRoomDisable = []
            if (Array.isArray(calendarSlots) && calendarSlots.length > 0) {
                let allEvent = calendarSlots.filter(c => c.event instanceof EventModel).map(c => c.event)
                eventsBooking = allEvent.filter(e => e.eventType === eventType.EVENT)
                eventsRoomDisable = allEvent.filter(e => e.eventType === eventType.ROOMDISABLE)
            }
            let eventOverlap = eventsBooking.find(event =>
                (moment(event.startDateTime).isSameOrBefore(selectStart) && moment(event.endDateTime).isAfter(selectStart))
                || (moment(event.startDateTime).isSameOrAfter(selectStart) && moment(event.startDateTime).isBefore(selectEnd))
            )
            if (!!eventOverlap) {
                return t("message.selectTimeOverlap")
            }
            let eventRoomDisableOverlap = eventsRoomDisable.find(event =>
                (moment(event.startDateTime).isSameOrBefore(selectStart) && moment(event.endDateTime).isAfter(selectStart))
                || (moment(event.startDateTime).isSameOrAfter(selectStart) && moment(event.startDateTime).isBefore(selectEnd))
            )
            if (!!eventRoomDisableOverlap) {
                return t("message.selectTimeRoomDisable")
            }
            return ""
        } catch (error) {
            return t("message.somethingWentWrong")
        }
    }
    ///////////////////////////////////

    //Event
    onConfirmCheckOut = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmCheckOut"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onCheckOut(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onCheckOut = (model) => {
        const { user, roomEmail } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.onRefreshEvent()
                }

            // let res = 
            await this.props.onCheckOut(user.email, roomEmail, model.iCalUId)
            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    onConfirmCheckIn = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmCheckIn"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onCheckIn(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onCheckIn = (model) => {
        const { user, roomInfo } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.onRefreshEvent()
                }

            // let res = 
            await this.props.onCheckIn(model.serializeCheckIn(user.email, roomInfo))
            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    render() {
        const { t } = this.props
        const {
            isLoading,
            controlBookingDateTime,
            calendarSlots,
            canBooking,
            setting,

            roomInfo,
            currentEvent,
            nextEvent,

            modalCustom,
        } = this.state
        let buttonsCurrentEvent = []
            , buttonsNextEvent = []
        if (!!currentEvent) {
            if (currentEvent.isShowCheckoutButton) {
                buttonsCurrentEvent.push(
                    new ButtonModel({
                        text: t("event.checkOut"),
                        color: "red",
                        onClick: (e) => this.onConfirmCheckOut(e, currentEvent)
                    })
                )
            }
            if (currentEvent.isShowCheckinButton) {
                buttonsCurrentEvent.push(
                    new ButtonModel({
                        text: t("event.checkIn"),
                        color: "green",
                        onClick: (e) => this.onConfirmCheckIn(e, currentEvent)
                    })
                )
            }
        }
        if (!!nextEvent) {
            if (nextEvent.isShowCheckinButton) {
                buttonsNextEvent.push(
                    new ButtonModel({
                        text: t("event.checkIn"),
                        color: "green",
                        onClick: (e) => this.onConfirmCheckIn(e, currentEvent)
                    })
                )
            }
        }
        let isRenderBooking = false
            , isRoomDisable = false
        if (!!setting && !!roomInfo) {
            isRoomDisable = roomInfo.isDisable
            isRenderBooking = !roomInfo.isDisable
        }
        let events = this.generateEventCalendar(isRenderBooking, controlBookingDateTime, calendarSlots)
            , errorSelectMessage = this.validateYourSelect(isRoomDisable, controlBookingDateTime, calendarSlots)

        let minuteStep = null
        if (!!setting) {
            minuteStep = setting.slotDuration
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {modalCustom && this.getModalContent(modalCustom)}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewRoomInfo">
                        <Container text>
                            <div className="view-room-info-wrapper">
                                {

                                    !!roomInfo &&
                                    <div className="box-room">
                                        < div className="box-item-room">
                                            <ItemRoom model={roomInfo} type={"horizontal"} isShowFacility={false} />
                                        </div>
                                        <div className="box-room-facility">
                                            <div className="room-facility-wrapper">
                                                <div className="facility-header">
                                                    <p>{t("common.facilities")}</p>
                                                </div>
                                                {
                                                    roomInfo.facilities.length > 0 ?
                                                        <div className="facility-list">
                                                            {
                                                                roomInfo.facilities.map((f, i) => {
                                                                    return <BoxIconImageWithText
                                                                        key={i}
                                                                        imageUrl={`${CORE_API_ENDPOINT}/image/amenity/${f.id}`}
                                                                        text={f.name}
                                                                    />
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        <div className="facility-not-found">
                                                            <p>{t("common.notFound")}</p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !!roomInfo &&
                                    <React.Fragment>
                                        <div className="box-event">
                                            <div className="event-wrapper">
                                                <div className="event-header">
                                                    <p>{t("event.currentMeeting")}</p>
                                                </div>
                                                {
                                                    !!currentEvent ?
                                                        <div className="event-item">
                                                            <ItemEvent model={currentEvent} buttons={buttonsCurrentEvent} />
                                                        </div>
                                                        : <div className="event-not-found">
                                                            <p>{t("common.notFound")}</p>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="box-event">
                                            <div className="event-wrapper">
                                                <div className="event-header">
                                                    <p>{t("event.nextMeeting")}</p>
                                                </div>
                                                {
                                                    !!nextEvent ?
                                                        <div className="event-item">
                                                            <ItemEvent model={nextEvent} buttons={buttonsNextEvent} />
                                                        </div>
                                                        : <div className="event-not-found">
                                                            <p>{t("common.notFound")}</p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="box-booking">
                                            <div className="booking-wrapper">
                                                <div className="booking-header">
                                                    <p>{t("event.booking")}</p>
                                                </div>
                                                {
                                                    (isRenderBooking && canBooking === true) &&
                                                    <div className="booking-control">
                                                        <div className="control-date">
                                                            <LabelInput text={t("common.date")} />
                                                            <InputDatePicker
                                                                name="date"
                                                                value={controlBookingDateTime.date}
                                                                onChange={this.handleChangeControl}
                                                            />
                                                        </div>
                                                        <div className="control-time">
                                                            <div className="time-item">
                                                                <LabelInput text={t("common.startTime")} />
                                                                <InputTimePicker
                                                                    name="startTime"
                                                                    value={controlBookingDateTime.startTime}
                                                                    minuteStep={minuteStep}
                                                                    onChange={this.handleChangeControl}
                                                                />
                                                            </div>
                                                            <div className="time-item">
                                                                <LabelInput text={t("common.endTime")} />
                                                                <InputTimePicker
                                                                    name="endTime"
                                                                    value={controlBookingDateTime.endTime}
                                                                    minuteStep={minuteStep}
                                                                    onChange={this.handleChangeControl}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="booking-calendar">
                                                    <FullCalendar
                                                        ref={this.calendarRef}

                                                        plugins={[timeGridPlugin]}
                                                        initialView="timeGrid"

                                                        allDaySlot={false}
                                                        headerToolbar={false}
                                                        height={"100%"}

                                                        slotLabelClassNames="calendar-slot-label"
                                                        slotLabelContent={this.slotLabelContent}

                                                        dayHeaderClassNames="calendar-day-label"
                                                        dayHeaderContent={this.dayHeaderContent}

                                                        eventClassNames="calendar-event-item"
                                                        eventContent={this.eventContent}
                                                        events={events}

                                                    />
                                                </div>
                                                {!!errorSelectMessage && <p className="error-your-select">{errorSelectMessage}</p>}
                                                {
                                                    (isRenderBooking && canBooking === true) &&
                                                    <div className="booking-action">

                                                        <ViewButton
                                                            model={new ButtonModel({
                                                                color: "blue",
                                                                text: t("event.book"),
                                                                disabled: !!errorSelectMessage,
                                                                onClick: this.onClickBook
                                                            })}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter>
            </React.Fragment >
        )
    }
}

ViewRoomInfo.defaultProps = {
    onGetSetting: () => {
        console.error("Please provide Get Setting action")
        return null
    },
    onGetRoomStatus: () => {
        console.error("Please provide Get Room Status action")
        return null
    },
    onGetUserInfo: () => {
        console.error("Please provide Get User action")
        return null
    },
    onGetCalendarSlot: () => {
        console.error("Please provide Get Event Slot action")
        return null
    },
    onGetCanBooking: () => {
        console.error("Please provide Can Booking action")
        return false
    },

    onCheckIn: () => {
        console.error("Please provide Check in action")
        return null
    },
    onCheckOut: () => {
        console.error("Please provide Check out action")
        return false
    },
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewRoomInfo)