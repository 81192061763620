
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import FacilityModel from './FacilityModel'

const Schema = mongoose.Schema

const RoomSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	email: { type: String, default: "" },
	name: { type: String, default: "" },
	capacity: { type: Number, default: 0 },
	timeZone: { type: String, default: "Asia/Bangkok" },
	location: { type: String, default: "" },
	roomType: { type: String, default: "" },
	image: { type: String, default: "" },
	isDisable: { type: Boolean, default: false },
	isAutoCheckin: { type: Boolean, default: false },
	facilities: [{ type: Object }],
	availableAmenity: [{ type: Object }],
	availableFood: [{ type: Object }],
	buildingId: { type: String, default: "" },
	floorId: { type: String, default: "" },
	zoneId: { type: String, default: "" },
}, { _id: false })

setBaseSchema(RoomSchema, "room")

RoomSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.email = data.email
			this.name = data.name
			this.code = data.code
			if (!isNaN(data.capacity)) {
				this.capacity = data.capacity
			}
			this.timeZone = data.timeZone
			this.location = data.location
			this.roomType = data.roomType
			this.roomEmail = data.roomEmail
			this.image = data.image
			if (typeof data.isDisable === "boolean") {
				this.isDisable = data.isDisable
			}
			if (typeof data.isAutoCheckin === "boolean") {
				this.isAutoCheckin = data.isAutoCheckin
			}
			if (Array.isArray(data.facilities)) {
				this.facilities = data.facilities.map(f => {
					let model = new FacilityModel()
					if (typeof f === "string") {
						model.setData({
							_id: f,
							name: f,
						})
					} else {
						model.setData(f)
					}
					return model
				})
			}
		}
	} catch (error) {

	}
}

RoomSchema.methods.setDataSuggest = function (data) {
	try {
		if (!!data) {
			this.id = data._id
			this.email = data.roomEmail
			this.name = data.roomName
			if (!isNaN(data.capacity)) {
				this.capacity = data.capacity
			}
			this.timeZone = data.timeZone
			this.location = data.location
			this.roomType = data.roomType
			this.roomEmail = data.roomEmail
			this.image = data.image
			if (typeof data.isDisable === "boolean") {
				this.isDisable = data.isDisable
			}
			if (Array.isArray(data.facilities)) {
				this.facilities = data.facilities.map(f => {
					let model = new FacilityModel()
					model.setData(f)
					return model
				})
			}
		}
	} catch (error) {

	}
}

const RoomModel = mongoose.model("room", RoomSchema, "room")

export default RoomModel