
import mongoose from 'mongoose'
import { languageKey } from '../constant'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ContactUsSchema = new Schema({
	rm_name: { type: String, default: "" },
	text_th: { type: String, default: "" },
	text_en: { type: String, default: "" },
}, { _id: false })

setBaseSchema(ContactUsSchema, "contact_us")

ContactUsSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.rm_name = data.rm_name
			this.text_th = data.text_th
			this.text_en = data.text_en
		}
	} catch (error) {

	}
}

ContactUsSchema.methods.getContentByLanguage = function (lang) {
	try {
		let text = ""
		if (lang === languageKey.th) {
			text = this.text_th
		} else if (lang === languageKey.en) {
			text = this.text_en
		}
		return text
	} catch (error) {
		return null
	}
}

const ContactUsModel = mongoose.model("contact_us", ContactUsSchema, "contact_us")

export default ContactUsModel