
import React from 'react'
import MyPage from '../../core/components/MyPage'
// import { Container } from 'semantic-ui-react'
import config from '../../config'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import ViewButton from '../../core/view/common/ViewButton'
import RoomModel from '../../models/RoomModel'
import { CORE_API_ENDPOINT } from '../../lib'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import { attendeeResponseStatus, roomTypeCode } from '../../constant'
import { Label } from 'semantic-ui-react'
// import ButtonModel from '../../core/model/ButtonModel'
// import Footer from './Footer'
// import Header from './Header'

const typeItem = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical"
}

class ItemRoom extends MyPage {
    render() {
        try {
            const { t, model, isShowFacility, type, buttons, roomResponseStatus } = this.props
            if (!(model instanceof RoomModel)) {
                return null
            }
            let classType = typeItem.VERTICAL
            if (!!type && type === typeItem.HORIZONTAL) {
                classType = typeItem.HORIZONTAL
            }
            let isRenderRoomResponseStatus = false
                , roomResponseStatusText = ""
            if (!!roomResponseStatus && !!attendeeResponseStatus[roomResponseStatus]) {
                if (roomResponseStatus === attendeeResponseStatus.ACCEPTED) {
                    isRenderRoomResponseStatus = true
                    roomResponseStatusText = t("event.accept")
                } else if (roomResponseStatus === attendeeResponseStatus.DECLINED) {
                    isRenderRoomResponseStatus = true
                    roomResponseStatusText = t("event.decline")
                }
            }

            return (
                <div className="module itemRoom">
                    <div className={`item-room-body ${classType}`}>
                        <div className="ir-image">
                            {/* <img src={`${config.publicUrl}/assets/images/room_1.jpg`} alt="Room" /> */}
                            <img src={`${CORE_API_ENDPOINT}/image/room/${model.id}`} alt="Room" />
                            {
                                model.type === roomTypeCode.VC &&
                                <div className="room-type">
                                    <img src={`${config.publicUrl}/assets/images/icon-video-call.png`} alt="Video" />
                                </div>
                            }
                            {
                                isRenderRoomResponseStatus &&
                                <div className={`room-status-response ${roomResponseStatus}`}>
                                    <p>{roomResponseStatusText}</p>
                                </div>
                            }
                        </div>
                        <div className="ir-body">
                            <div className="ir-detail">
                                <h3>{model.name ? model.name : "-"}</h3>
                                <BoxIconImageWithText
                                    imageUrl={`${config.publicUrl}/assets/images/icon-mark.png`}
                                    text={model.location ? model.location : "-"}
                                />
                                <BoxIconImageWithText
                                    imageUrl={`${config.publicUrl}/assets/images/icon-user.png`}
                                    text={`${model.capacity > 0 ? model.capacity : "-"} ${t("room.pax")}`}
                                />

                                {
                                    model.facilities.length > 0 &&
                                    <div className="detail-facility">
                                        {
                                            isShowFacility && model.facilities.map((f, i) => {
                                                return < div key={i} className="item-facility">
                                                    <div className="item-facility-wrapper">
                                                        <img src={`${CORE_API_ENDPOINT}/image/amenity/${f.id}`} alt={f.name} />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            {
                                ((model.isDisable === true) || (Array.isArray(buttons) && buttons.length > 0)) &&
                                <div className="ir-footer">
                                    <div className="ir-footer-wrapper">
                                        {
                                            model.isDisable === true &&
                                            <div className="ir-disable">
                                                <Label color="red">{t("room.roomDisabled")}</Label>
                                            </div>
                                        }
                                        {
                                            Array.isArray(buttons) && buttons.length > 0 &&
                                            <div className="ir-action">
                                                {
                                                    buttons.map((button, i) => {
                                                        return <ViewButton key={i} model={button} />
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {/* {
                            Array.isArray(buttons) && buttons.length > 0 &&
                            <div className="ir-action">
                                {
                                    buttons.map((button, i) => {
                                        return <ViewButton key={i} model={button} />
                                    })
                                }
                            </div>
                        } */}
                    </div>
                </div >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ItemRoom.defaultProps = {
    model: new RoomModel(),
    buttons: [],
    type: typeItem.VERTICAL,
    roomResponseStatus: "",
    isShowFacility: true,
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ItemRoom)