
import mongoose from 'mongoose'
import { languageKey } from '../constant'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const FAQSchema = new Schema({
	rm_name: { type: String, default: "" },
	text_th: { type: String, default: "" },
	text_en: { type: String, default: "" },
}, { _id: false })

setBaseSchema(FAQSchema, "faq")

FAQSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.rm_name = data.rm_name
			this.text_th = data.text_th
			this.text_en = data.text_en
		}
	} catch (error) {

	}
}

FAQSchema.methods.getContentByLanguage = function (lang) {
	try {
		let text = ""
		if (lang === languageKey.th) {
			text = this.text_th
		} else if (lang === languageKey.en) {
			text = this.text_en
		}
		return text
	} catch (error) {
		return null
	}
}

const FAQModel = mongoose.model("faq", FAQSchema, "faq")

export default FAQModel