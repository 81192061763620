import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './views/App'
import { configureStore } from './reduxStore'
import ErrorBoundary from './core/view/errors/ErrorBoundary'
import ScrollToTop from './views/ScrollToTop'
import { setTranslations, setDefaultLanguage, setLanguageCookie, getLanguage, } from 'react-switch-lang'
import { cookiesKey, languageKey } from './constant'
import '../scss/index.scss'
import '../scss/indexLibrary.scss'

import en from './wording/en'
import th from './wording/th'

setTranslations({ en, th });
setLanguageCookie(cookiesKey.LANGUAGE, { maxAge: 86400 }, undefined)
const language = getLanguage()
if (!language && !(language === languageKey.th || language === languageKey.en)) {
    setDefaultLanguage(languageKey.en);
}
const store = configureStore()

const Root = () => {

    return <Provider store={store}>
        <ErrorBoundary>
            <Router>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </ErrorBoundary>
    </Provider>
}

export default Root