
import moment from 'moment'
import mongoose from 'mongoose'
import { attendeeType, conferenceTypeCode, dateTimeFormat, defaultValue, recurrenceText } from '../constant'
import { setBaseSchema } from '../core/schema/Schema'
import errorMessage from '../wording/errorMessage'
import EventRecurrenceModel from './EventRecurrenceModel'
// import RoomModel from './RoomModel'

const Schema = mongoose.Schema

const CreateUpdateSchema = new Schema({
	subject: { type: String, required: [true, errorMessage.FF000001], default: "" },
	startDateTime: { type: Date, default: moment() },
	endDateTime: { type: Date, default: moment() },
	rooms: [{ type: Object }],
	organizerName: { type: String, default: "" },
	organizerAddress: { type: String, default: "" },
	location: { type: String, default: "" },

	contentType: { type: String, default: "html" },
	content: { type: String, default: "" },

	isAllDay: { type: Boolean, default: false },
	// sensitivity: { type: String, default: "" },
	// type: { type: String, default: "" },
	// user: { type: String, default: "" },
	isEnableEventType: { type: Boolean, default: false },
	eventTypeId: { type: String, required: false, default: "" },
	eventRoomFormatId: { type: String, default: "" },

	isPrivate: { type: Boolean, default: false },

	attendees: [{ type: Object }],
	optionals: [{ type: Object }],

	recurrence: { type: Object, default: null },

	conferenceType: { type: String, default: "" },
	tmsPrivacy: { type: Boolean, default: false },
	tmsPassword: { type: String, default: "" },
}, { _id: false })

setBaseSchema(CreateUpdateSchema, "create_update_event")

CreateUpdateSchema.virtual('recurrenceTextValue').get(function () {
	let text = ""
	if (this.recurrence instanceof EventRecurrenceModel) {
		let type = this.recurrence.type
			, textObj = recurrenceText[type]
		if (textObj) {
			text = textObj.text
		}
	}
	return text
})

CreateUpdateSchema.path("eventTypeId").validate(async function (eventTypeId) {
	if (this.isEnableEventType === true) {
		return !!eventTypeId && eventTypeId !== defaultValue.PLEASE_SELECT
	} else {
		return true
	}
}, "validate.require")

CreateUpdateSchema.path("tmsPassword").validate(async function (tmsPassword) {
	if (this.conferenceType === conferenceTypeCode.CISCO_TMS) {
		return this.tmsPrivacy === true && !!tmsPassword
	}
	return true
}, "validate.require")

CreateUpdateSchema.path("endDateTime").validate(async function (endDateTime) {
	let start = moment(this.startDateTime)
		, end = moment(endDateTime)
	if (this.isAllDay) {
		return start.isSameOrBefore(end, "day")
	} else {
		return start.isSameOrBefore(end)
	}
}, "message.endTimeGreaterThanStartTime")

CreateUpdateSchema.methods.setDataUpdate = function (data) {
	try {
		if (!!data) {
			this.subject = data.subject
			if (data.isAllDay === true) {
				this.startDateTime = moment(data.startDateTime).startOf("day")
				this.endDateTime = moment(data.endDateTime).subtract(1, "day").endOf("day")
			} else {
				this.startDateTime = moment(data.startDateTime)
				this.endDateTime = moment(data.endDateTime)
			}

			this.rooms = data.rooms.map(roomEvent => roomEvent.room)
			this.organizerName = data.organizerName
			this.organizerAddress = data.organizerAddress

			this.contentType = data.body.contentType
			this.content = data.body.content

			this.eventTypeId = data.eventTypeId
			this.eventRoomFormatId = data.eventRoomFormatId
			if (Array.isArray(data.attendees) && data.attendees.length > 0) {
				this.attendees = data.attendees
					.filter(att => att.type === attendeeType.REQUIRE)
					.map(att => {
						return {
							fullName: att.emailAddressName,
							email: att.emailAddressAddress
						}
					})
				this.optionals = data.attendees
					.filter(att => att.type === attendeeType.OPTIONAL)
					.map(att => {
						return {
							fullName: att.emailAddressName,
							email: att.emailAddressAddress
						}
					})
			}

			if (!!data.recurrence) {
				let model = new EventRecurrenceModel(data.recurrence)
				this.recurrence = model
			}

			if (!!data.conferenceType) {
				this.conferenceType = data.conferenceType
			}
			if (typeof data.tmsPrivacy === "boolean") {
				this.tmsPrivacy = data.tmsPrivacy
			}
			if (!!data.tmsPassword) {
				this.tmsPassword = data.tmsPassword
			}
			if (!!data.sensitivity && data.sensitivity === "private") {
				this.isPrivate = true
			}
			if (typeof data.isAllDay === "boolean") {
				this.isAllDay = data.isAllDay
			}
		}
	} catch (error) {

	}
}

CreateUpdateSchema.methods.serializeAPI = function (user, setting) {
	try {
		let result = {
			subject: this.subject,
			startDateTime: moment(this.startDateTime).format(dateTimeFormat.apiFullDateTime),
			endDateTime: moment(this.endDateTime).format(dateTimeFormat.apiFullDateTime),
			organizerName: this.organizerName,
			organizerAddress: this.organizerAddress,
			attendees: [],
			location: "",
			isAllDay: this.isAllDay,
		}
		let attendees = []
			, roomNames = []
		if (this.rooms.length > 0) {
			this.rooms.forEach(room => {
				let att = {
					type: attendeeType.RESOURCE,
					emailAddress: {
						address: room.email
					}
				}
				attendees.push(att)
				roomNames.push(room.name)
			});
		}
		if (this.attendees.length > 0) {
			this.attendees.forEach(att => {
				let obj = {
					type: attendeeType.REQUIRE,
					emailAddress: {
						address: att.email
					}
				}
				attendees.push(obj)
			});
		}
		if (this.optionals.length > 0) {
			this.optionals.forEach(att => {
				let obj = {
					type: attendeeType.OPTIONAL,
					emailAddress: {
						address: att.email
					}
				}
				attendees.push(obj)
			});
		}
		result.attendees = attendees
		result.location = roomNames.join(", ")
		result.bodyContentType = this.contentType
		result.bodyContent = this.content
		if (setting.isEnableEventType && !!this.eventTypeId && this.eventTypeId !== defaultValue.PLEASE_SELECT) {
			result.eventTypeId = this.eventTypeId
		}
		if (!!this.eventRoomFormatId && this.eventRoomFormatId !== defaultValue.NONE_SELECT) {
			result.eventRoomFormatId = this.eventRoomFormatId
		}
		if (this.isPrivate) {
			result.sensitivity = "private"
		} else {
			result.sensitivity = "normal"
		}
		if (this.recurrence instanceof EventRecurrenceModel) {
			result.recurrence = this.recurrence.serializeAPI(user)
		}
		if (setting.isEnableConferenceType && !!this.conferenceType && !this.recurrence) {
			result.conferenceType = this.conferenceType
		}
		return result
	} catch (error) {
		console.error(error)
		return null
	}
}

const CreateUpdateEventModel = mongoose.model("create_update_event", CreateUpdateSchema, "create_update_event")

export default CreateUpdateEventModel