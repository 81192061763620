
import mongoose from 'mongoose'
import { setBaseSchema } from '../schema/Schema'

const Schema = mongoose.Schema

const ButtonSchema = new Schema({
	text: { type: String, default: "" },
	title: { type: String, default: "" },
	onClick: { type: Object, default: null },
	color: { type: String, default: "" },
	iconClass: { type: String, default: "" },
	iconColor: { type: String, default: "" },
	className: { type: String, default: "" },
	visible: { type: Boolean, default: true },
	disabled: { type: Object, default: false },
	externalProps: { type: Object, default: null },
	size: { type: String, default: "medium", enum: ["mini", "tiny", "small", "medium", "large", "big"] },
}, { _id: false })

setBaseSchema(ButtonSchema, "button")

const ButtonModel = mongoose.model("button", ButtonSchema, "button")

export default ButtonModel