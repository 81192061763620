
import React from 'react'
import { Switch } from 'react-router-dom'

import commonRoute from './commonRoute'
import authRoute from './authRoute'
import globalRoute from './globalRoute'
import roomRoute from './roomRoute'
import eventRoute from './eventRoute'
import userRoute from './userRoute'

let routes = [
    userRoute,
    eventRoute,
    roomRoute,
    globalRoute,
    authRoute,
    commonRoute
]

let key = 1

const generateRoutesModule = (configs) => {
    let routes = []
    for (let r of configs) {
        for (let config of r.routeConfigs) {
            let path = ""
            if (!!r.module) {
                path += "/" + r.module.toLowerCase()
            }
            if (!!config.path) {
                path += config.path
            }
            routes.push(generateRoute(path, config))
        }
    }

    return routes
}

const generateRoute = (path, config) => {
    let result = null
    if (path) {
        if (config.exact) {
            result = <config.route path={path} component={config.component} key={key++} exact />
        } else {
            result = <config.route path={path} component={config.component} key={key++} />
        }
    } else {
        if (config.exact) {
            result = <config.route component={config.component} key={key++} exact />
        } else {
            result = <config.route component={config.component} key={key++} />
        }
    }

    return result
}
let routesGenerate = generateRoutesModule(routes)

const ComponentSwitch = () => {
    return <Switch>
        {routesGenerate}
    </Switch>
}

export default ComponentSwitch