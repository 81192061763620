
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import O365DataModel from './integrationData/O365DataModel'
import GoogleDataModel from './integrationData/GoogleDataModel'
import { systemKey } from '../constant'

const Schema = mongoose.Schema

const FrontendIntegrationSettingSchema = new Schema({
	system: { type: String, default: "" },

	googleData: { type: Object, default: null },
	exchangeData: { type: Object, default: null },
	adData: { type: Object, default: null },
	o365Data: { type: Object, default: null },

	isLoginLocal: { type: Boolean, default: false },
	isLoginSystem: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(FrontendIntegrationSettingSchema, "frontend_integration_setting")


FrontendIntegrationSettingSchema.virtual('isIntegration').get(function () {
	if (this.system === systemKey.EXCHANGE2010 || this.system === systemKey.EXCHANGE2013) {
		return true
	}
	if (this.system === systemKey.O365 && !!this.o365Data) {
		return true
	}
	if (this.system === systemKey.GOOGLE && !!this.googleData) {
		return true
	}
	return false
})

FrontendIntegrationSettingSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			if (!!data.system) {
				this.system = data.system.toUpperCase()
			}

			if (!!data.o365Data) {
				let model = new O365DataModel(data.o365Data)
				this.o365Data = model
			}
			if (!!data.googleData) {
				let model = new GoogleDataModel(data.googleData)
				this.googleData = model
			}
			if (typeof data.isLoginLocal === "boolean") {
				this.isLoginLocal = data.isLoginLocal
			}
			if (typeof data.isLoginSystem === "boolean") {
				this.isLoginSystem = data.isLoginSystem
			}
		}
	} catch (error) {

	}
}

const FrontendIntegrationSettingModel = mongoose.model("frontend_integration_setting", FrontendIntegrationSettingSchema, "frontend_integration_setting")

export default FrontendIntegrationSettingModel