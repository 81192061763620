
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const PhoneSchema = new Schema({
	type: { type: String, default: "" },
	number: { type: String, default: "" },
}, { _id: false })

const UserMailSystemSchema = new Schema({
	id: { type: String, default: "" },
	fullName: { type: String, default: "" },
	email: { type: String, default: "" },

	//O365
	phones: [{ type: PhoneSchema }]
}, { _id: false })

setBaseSchema(UserMailSystemSchema, "user_mail_system")

UserMailSystemSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data.id
			this.fullName = data.displayName
			this.email = data.userPrincipalName
			if (Array.isArray(data.phones)) {
				this.phones = data.phones.map(f => f)
			}
		}
	} catch (error) {

	}
}

const UserMailSystemModel = mongoose.model("user_mail_system", UserMailSystemSchema, "user_mail_system")

export default UserMailSystemModel