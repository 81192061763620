
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const WebSchema = new Schema({
	integration: { type: String, default: "" },
	authen: { type: String, default: "" },
}, { _id: false })

const Audience = new Schema({
	type: { type: String, default: "" },
	tenant_id: { type: String, default: "" },
}, { _id: false })

const AuthoritiesSchema = new Schema({
	_id: { type: String, default: "" },
	type: { type: String, default: "" },
	audience: { type: Audience, default: {} },
}, { _id: false })

const AndroidSchema = new Schema({
	client_id: { type: String, default: "" },
	authorization_user_agent: { type: String, default: "" },
	redirect_uri: { type: String, default: "" },
	signatureHash: { type: String, default: "" },
	packageName: { type: String, default: "" },
	authorities: [{ type: AuthoritiesSchema }],
}, { _id: false })

const IOSSchema = new Schema({
	kClientID: { type: String, default: "" },
	kRedirectUri: { type: String, default: "" },
	kAuthority: { type: String, default: "" },
	bundleID: { type: String, default: "" },
}, { _id: false })

const O365DataSchema = new Schema({
	client_id: { type: String, default: "" },
	client_secret: { type: String, default: "" },
	mobile_redirect_url: { type: String, default: "" },

	web: { type: WebSchema, default: {} },
	android: { type: AndroidSchema, default: {} },
	ios: { type: IOSSchema, default: {} },
}, { _id: false })

setBaseSchema(O365DataSchema, "o365_data")

const O365DataModel = mongoose.model("o365_data", O365DataSchema, "o365_data")

export default O365DataModel