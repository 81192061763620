
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../../core/view/common/LabelInput'
import LocalLoginModel from '../../models/LocalLoginModel'
import ViewErrorInput from '../common/ViewErrorInput'

class ChangePasswordForm extends MyForm {


    render() {
        const { t, model, errors } = this.props
        let usernameError = this.getErrorInput("username", errors)
            , passwordError = this.getErrorInput("password", errors)
        return (
            <React.Fragment>
                {super.render()}
                <div className="module changePasswordForm">
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.username")} isRequire={true} />
                                    <Form.Input
                                        name={"username"}
                                        value={model.username}
                                        fluid
                                        onChange={this.handleChange}
                                        error={usernameError.isError}
                                    />
                                    {
                                        usernameError.isError &&
                                        <ViewErrorInput error={usernameError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("user.password")} isRequire={true} />
                                    <Form.Input
                                        type="password"
                                        name={"password"}
                                        value={model.password}
                                        fluid
                                        onChange={this.handleChange}
                                        error={passwordError.isError}
                                    />
                                    {
                                        passwordError.isError &&
                                        <ViewErrorInput error={passwordError.content} />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}

ChangePasswordForm.defaultProps = {
    model: new LocalLoginModel(),
    errors: [],
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ChangePasswordForm)