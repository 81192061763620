import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import ViewButton from './ViewButton';
import ButtonModel from '../../model/ButtonModel';
import { Form } from 'semantic-ui-react';

class InputHtml extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editorState: this.convertContentToEditorState(props.value),
            content: props.value,
        }
    }

    convertContentToEditorState(data) {
        let htmlData = EditorState.createEmpty()
        if (data && typeof data === "string") {
            const contentBlock = htmlToDraft(data)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            htmlData = editorState
        }

        return htmlData
    }

    sendTextToEditor(text) {
        let editorState = this.insertText(text, this.state.editorState)

        this.onEditorStateChange(editorState)
    }

    insertText(text, editorState) {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text,
        )

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    }

    onEditorStateChange = (editorState) => {
        let hasText = editorState.getCurrentContent().hasText()
            , content = hasText ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : ""

        this.setState({ editorState: editorState, content: content });

        this.handleChange(content)
    }

    handleChange = (content) => {
        this.props.onChange(null, { name: this.props.name, value: !!content && content.toString().trim() !== "" && content.toString().trim() !== "<p></p>" ? content.toString().trim() : "" })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    render() {
        const { buttonKeyWords } = this.props
        const { editorState } = this.state
        return <React.Fragment>
            <div className="module inputHtml">
                <Form onKeyDown={this.onKeyDownSubmitForm}>
                    <Editor
                        editorState={editorState}
                        // editorStyle={{ height: "300px", "overflow-y": "auto" }}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
                            inline: {
                                bold: {},
                                italic: {},
                                underline: {},
                                strikethrough: {},
                                monospace: {},
                                superscript: {},
                                subscript: {}
                            },
                            blockType: {},
                            fontSize: {},
                            list: {
                                unordered: {},
                                ordered: {},
                                indent: {},
                                outdent: {},
                            },
                            textAlign: {
                                left: {},
                                center: {},
                                right: {},
                                justify: {},
                            },
                            fontFamily: {},
                            colorPicker: {},
                        }}
                    />
                </Form>
                {
                    Array.isArray(buttonKeyWords) && buttonKeyWords.length > 0 &&
                    <div style={{ marginTop: "1rem" }}>
                        {
                            buttonKeyWords.map((m, i) => {
                                if (!m || !m.text || !m.value) {
                                    return null
                                }

                                return <ViewButton
                                    model={new ButtonModel({
                                        className: "spaceBetweenButtons",
                                        text: m.text,
                                        onClick: () => this.sendTextToEditor(m.value),
                                        color: "teal",
                                        size: "tiny"
                                    })}
                                />
                            })
                        }
                    </div>
                }
            </div>
        </React.Fragment >
    }
}

InputHtml.defaultProps = {
    name: "",
    value: "",
    disabled: false,
    buttonKeyWords: [],
    onChange: () => console.error("Please provide On Change action.")
}

export default compose(
    withRouter,
    translate
)(InputHtml)