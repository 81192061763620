
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewSearchRoom from '../../views/room/ViewSearchRoom'
import SettingManager from '../../business/SettingManager'
import RoomManager from '../../business/RoomManager'

class SearchRoomController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bSetting = new SettingManager(this)
		this.bRoom = new RoomManager(this)
	}

	onGetSetting = () => {
		return this.bSetting.getFrontendSetting()
	}


	onGetSuggestInformation = () => {
		return this.bSetting.getSuggestInformation()
	}

	onGetRoomSuggestion = (body) => {
		return this.bRoom.getRoomSuggestion(body)
	}

	render() {
		return (
			<React.Fragment>
				{super.render()}
				<ViewSearchRoom
					onGetSetting={this.onGetSetting}
					onGetSuggestInformation={this.onGetSuggestInformation}
					onGetRoomSuggestion={this.onGetRoomSuggestion}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	})
)


export default enhance(SearchRoomController)