
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewUserIntegration from '../../views/user/ViewUserIntegration'
import UserManager from '../../business/UserManager'
import SettingManager from '../../business/SettingManager'

class UserIntegrationController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
		this.bSetting = new SettingManager(this)
	}

	onGetUserIntegrationInfo = () => {
		return this.bUser.getUserIntegrationInfo()
	}

	onGetSetting = () => {
		return this.bSetting.getO365Setting()
	}

	onUnintegration = (system) => {
		return this.bUser.unintegration(system)
	}

	onIntegrationO365 = (body) => {
		return this.bUser.integrationO365(body)
	}

	onIntegrationGoogle = (body) => {
		return this.bUser.integrationGoogle(body)
	}
	render() {
		const { isLoading } = this.state
		return (
			<React.Fragment>
				{super.render()}
				{isLoading && this.getLoadingPage()}
				<ViewUserIntegration
					onGetUserIntegrationInfo={this.onGetUserIntegrationInfo}
					onGetSetting={this.onGetSetting}
					onUnintegration={this.onUnintegration}
					onIntegrationO365={this.onIntegrationO365}
					onIntegrationGoogle={this.onIntegrationGoogle}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(UserIntegrationController)