
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import UserGroupModel from '../models/UserGroupModel'

export default class UserGroupManager extends MyManager {
	async getUserGroupList() {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/userGroup/list`,
			}

			let res = await this.requestAction(options)
			if (!!res && !!res.result === true && Array.isArray(res.datas)) {
				res.datas = res.datas.map(user => {
					let model = new UserGroupModel()
					model.setData(user)
					return model
				})
			}
			return res
		} catch (error) {
			return null
		}
	}
}