
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Container, Form, Grid } from 'semantic-ui-react'
// import Slider from 'react-slick'

import MyPage from '../../core/components/MyPage'
import LayoutWithHeaderFooter from '../layout/LayoutWithHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../../core/view/common/ViewButton'
import ViewHeader from '../layout/ViewHeader'
import ViewFooter from '../layout/ViewFooter'

import ItemRoom from '../room/ItemRoom'
import moment from 'moment'
import ModalModel from '../../core/model/ModalModel'
import { attendeeResponseStatus, cookiesKey, dateTimeFormat, editEventType } from '../../constant'
import EventModel from '../../models/EventModel'
import BoxIconImageWithText from '../common/BoxIconImageWithText'
import config from '../../config'

class ViewViewEvent extends MyPage {
    constructor(props) {
        super(props)
        this.state.user = this.bCookie.getData(cookiesKey.USER)
        this.state.isLoading = false
        this.state.eventId = this.getQueryFromProps("eventId")
        this.state.model = null
        this.state.attendeeTabActive = ""

        this.state.modalCustom = null

        this.state.isOpenModalSelectEditType = false
        this.state.editType = editEventType.SINGLE
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        const { user, eventId } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
                model: null,
            },
                action = () => {

                }
            let res = await this.props.onGetEventInfo(user.email, eventId)
            if (!!res && res.result === true && res.data instanceof EventModel) {
                tempState.model = res.data
            }
            this.setState(tempState, action)
        })
    }

    onClickBack = () => {
        this.props.history.goBack()
    }

    onClickEdit = () => {
        const { model } = this.state
        if (!!model) {
            if (!!model.seriesMasterId) {
                this.setState({
                    isOpenModalSelectEditType: true
                })
            } else {
                this.onPushEditEvent(model.eventId, false)
            }
        }
        // this.props.history.push(`/event/update?eventId=${encodeURIComponent(this.state.eventId)}`)
    }

    onPushEditEvent = (id, isRecurrence = false) => {
        let query = `?eventId=${encodeURIComponent(id)}`
        if (isRecurrence === true) {
            query += `&editType=${editEventType.RECURRENCE}`
        } else {
            query += `&editType=${editEventType.SINGLE}`
        }
        this.props.history.push(`/event/update${query}`)
    }

    onChangeTabAttendee = (e, attendeeTabActive) => {
        this.setState({
            attendeeTabActive
        })
    }

    onConfirmCheckIn = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmCheckIn"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onCheckIn(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onCheckIn = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.getData()
                }

            await this.props.onCheckIn(model.serializeCheckInAll(user.email))
            // let res =
            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    onConfirmResendEventPin = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmResendPIN"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onResendEventPin(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onResendEventPin = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    // this.getData()
                }

            // let res = 
            await this.props.onResendEventPin(user.email, model.iCalUId)
            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    //Delete
    onConfirmDeleteEvent = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmCancelMeeting"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onDeleteEvent(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onDeleteEvent = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {

                }

            let res = await this.props.onDeleteEvent(user.email, model.eventId)
            if (!!res && res.result === true) {
                tempState.isLoading = true
                action = () => {
                    this.props.history.goBack()
                }
            }
            this.setState(tempState, action)
        })
    }

    //Accept
    onConfirmAcceptEvent = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmAccept"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onAcceptEvent(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onAcceptEvent = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.getData()
                }

            // let res = 
            await this.props.onAcceptEvent(user.email, model.eventId)

            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    //Tentative
    onConfirmTentativeEvent = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmTentative"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onTentativeEvent(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onTentativeEvent = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.getData()
                }

            // let res = 
            await this.props.onTentativeEvent(user.email, model.eventId)

            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }

    //Decline
    onConfirmDeclineEvent = (e, model) => {
        const { t } = this.props
        this.setState({
            modalCustom: new ModalModel({
                headerText: t("common.confirm"),
                content: t("message.confirmDecline"),
                buttons: [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.onDeclineEvent(model)
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.cancel"),
                        color: "grey",
                        onClick: (e) => {
                            this.setState({
                                modalCustom: null
                            })
                        }
                    })
                ]
            })
        })
    }

    onDeclineEvent = (model) => {
        const { user } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let tempState = {
                isLoading: false,
            },
                action = () => {
                    this.getData()
                }

            // let res = 
            await this.props.onDeclineEvent(user.email, model.eventId)

            // if (!!res && res.result === true && typeof res.data === "boolean") {
            //     tempState.canBooking = res.data
            // }
            this.setState(tempState, action)
        })
    }
    render() {
        const { t } = this.props
        const { user, model, isLoading, attendeeTabActive, modalCustom,
            isOpenModalSelectEditType, editType } = this.state
        // const settings = {
        //     dots: false,
        //     infinite: false,
        //     speed: 500,
        //     slidesToShow: 3,
        //     slidesToScroll: 3,
        //     initialSlide: 0,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {
        //                 slidesToShow: 3,
        //                 slidesToScroll: 3,
        //                 // infinite: false,
        //                 // dots: true
        //             }
        //         },
        //         {
        //             breakpoint: 600,
        //             settings: {
        //                 slidesToShow: 2,
        //                 slidesToScroll: 2,
        //                 // infinite: false,
        //                 // dots: true
        //                 initialSlide: 2
        //             }
        //         },
        //         {
        //             breakpoint: 480,
        //             settings: {
        //                 slidesToShow: 1.2,
        //                 // infinite: true,
        //                 // dots: true,
        //                 slidesToScroll: 1
        //             }
        //         }
        //     ]
        // };

        let isOrganizer = false
            , isRenderAction = false
            , dateTimeSplit = null
        let attendees = []
            , attendeesAccept = []
            , attendeesTentative = []
            , attendeesDecline = []
            , attendeesDisplay = []
        if (!!model) {
            dateTimeSplit = model.getTimeInComing(moment())
            if (user) {
                isRenderAction = true
                isOrganizer = model.isOrganizer(user.email)
            }
            attendees = model.getAttendeesByStatus("")
            attendeesAccept = model.getAttendeesByStatus(attendeeResponseStatus.ACCEPTED)
            attendeesTentative = model.getAttendeesByStatus(attendeeResponseStatus.TENTATIVE)
            attendeesDecline = model.getAttendeesByStatus(attendeeResponseStatus.DECLINED)
        }
        if (attendeeTabActive === "") {
            attendeesDisplay = attendees
        } else if (attendeeTabActive === attendeeResponseStatus.ACCEPTED) {
            attendeesDisplay = attendeesAccept
        } else if (attendeeTabActive === attendeeResponseStatus.TENTATIVE) {
            attendeesDisplay = attendeesTentative
        } else if (attendeeTabActive === attendeeResponseStatus.DECLINED) {
            attendeesDisplay = attendeesDecline
        }
        return (
            <React.Fragment>
                {super.render()}
                {isLoading && this.getLoadingPage()}
                {modalCustom && this.getModalContent(modalCustom)}
                {isOpenModalSelectEditType && this.getModalContent(new ModalModel({
                    headerText: t("common.pleaseSelect"),
                    size: "mini",
                    content: <div>
                        <Form>
                            <Grid>
                                <Grid.Column width={16}>
                                    <Form.Checkbox
                                        label={t("event.editMeeting")}
                                        name="editType"
                                        value={editEventType.SINGLE}
                                        checked={editType === editEventType.SINGLE}
                                        onChange={(e, { name, value, checked }) => {
                                            if (checked === true) {
                                                this.setState({
                                                    editType: value
                                                })
                                            }
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Checkbox
                                        label={t("event.editRecurringMeeting")}
                                        name="editType"
                                        value={editEventType.RECURRENCE}
                                        checked={editType === editEventType.RECURRENCE}
                                        onChange={(e, { name, value, checked }) => {
                                            if (checked === true) {
                                                this.setState({
                                                    editType: value
                                                })
                                            }
                                        }}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </div>,
                    buttons: [
                        new ButtonModel({
                            text: t("common.ok"),
                            color: "blue",
                            onClick: (e) => {
                                this.setState({
                                    isOpenModalSelectEditType: false
                                }, () => {
                                    if (editType === editEventType.RECURRENCE) {
                                        this.onPushEditEvent(model.seriesMasterId, true)
                                    } else {
                                        this.onPushEditEvent(model.eventId, false)
                                    }
                                })
                            }
                        }),
                        new ButtonModel({
                            text: t("common.cancel"),
                            color: "grey",
                            onClick: (e) => {
                                this.setState({
                                    isOpenModalSelectEditType: null
                                })
                            }
                        })
                    ]
                }))}
                <LayoutWithHeaderFooter
                    header={<ViewHeader />}
                    footer={<ViewFooter />}
                >
                    <div className="module viewViewEvent">
                        <Container text>
                            <div className="view-event-header">
                                <h1>{t("event.bookingDetails")}</h1>
                            </div>
                            <div className="view-event-body">
                                {
                                    !!model &&
                                    <React.Fragment>
                                        <div className="box-location">
                                            {
                                                model.rooms.length > 0 ?
                                                    <div className="location-box">
                                                        <p><b>{t("common.location")}</b></p>
                                                        <div className="room-list">
                                                            {/* <Slider {...settings}> */}
                                                            {
                                                                model.rooms.map((roomResponse, i) => {
                                                                    return <div className="item-room" key={i}>
                                                                        <ItemRoom
                                                                            model={roomResponse.room}
                                                                            roomResponseStatus={roomResponse.statusResponse}
                                                                            type="horizontal"
                                                                        />
                                                                    </div>
                                                                })
                                                            }
                                                            {/* </Slider> */}
                                                        </div>
                                                    </div>
                                                    : <div className="box-detail">
                                                        <div className="detail-header">
                                                            <p>{t("common.location")}</p>
                                                            <p>:</p>
                                                        </div>
                                                        <div className="detail-content">
                                                            <p>{model.location}</p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className="box-event-detail">
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="box-detail">
                                                            <div className="detail-header">
                                                                <p>{t("event.subject")}</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div className="detail-content">
                                                                {/* <p>{model.subject}</p> */}
                                                                <div className="detail-content-subject">
                                                                    {
                                                                        model.isRecurrence &&
                                                                        <img src={`${config.publicUrl}/assets/images/icon-recurrence.png`} alt="Recurrence" />
                                                                    }
                                                                    {model.subject}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="box-detail">
                                                            <div className="detail-header">
                                                                <p>{t("common.date")}</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div className="detail-content">
                                                                <p>{moment(model.startDateTime).format(dateTimeFormat.displayDate)}</p>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="box-detail">
                                                            <div className="detail-header">
                                                                <p>{t("common.time")}</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div className="detail-content">
                                                                <p>{`${moment(model.startDateTime).format(dateTimeFormat.displayTime)} - ${moment(model.endDateTime).format(dateTimeFormat.displayTime)}`}</p>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="box-detail">
                                                            <div className="detail-header">
                                                                <p>{t("event.organizer")}</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div className="detail-content">
                                                                <p>{model.organizerName}</p>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <div className="box-detail">
                                                            <div className="detail-header">
                                                                <p>{t("common.description")}</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div className="detail-content">
                                                                <div className="detail-content-description">
                                                                    <div dangerouslySetInnerHTML={{ __html: model.body.content }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                        {
                                            isOrganizer &&
                                            <div className="box-attendee">
                                                <div className="box-header">
                                                    <div className={`item-header ${attendeeTabActive === "" ? "active" : ""}`}
                                                        onClick={(e) => this.onChangeTabAttendee(e, "")}
                                                    >
                                                        <p>{t("event.allInvites")} ({attendees.length})</p>
                                                    </div>
                                                    <div className={`item-header ${attendeeTabActive === attendeeResponseStatus.ACCEPTED ? "active" : ""}`}
                                                        onClick={(e) => this.onChangeTabAttendee(e, attendeeResponseStatus.ACCEPTED)}
                                                    >
                                                        <p>{t("event.accept")} ({attendeesAccept.length})</p>
                                                    </div>
                                                    <div className={`item-header ${attendeeTabActive === attendeeResponseStatus.TENTATIVE ? "active" : ""}`}
                                                        onClick={(e) => this.onChangeTabAttendee(e, attendeeResponseStatus.TENTATIVE)}
                                                    >
                                                        <p>{t("event.tentative")} ({attendeesTentative.length})</p>
                                                    </div>
                                                    <div className={`item-header ${attendeeTabActive === attendeeResponseStatus.DECLINED ? "active" : ""}`}
                                                        onClick={(e) => this.onChangeTabAttendee(e, attendeeResponseStatus.DECLINED)}
                                                    >
                                                        <p>{t("event.decline")} ({attendeesDecline.length})</p>
                                                    </div>
                                                </div>
                                                {
                                                    attendeesDisplay.length > 0 ?
                                                        <div className="box-attendee-list">
                                                            {
                                                                attendeesDisplay.map((att, i) => {
                                                                    return <div key={i} className="item-attendee">
                                                                        <div className={`attendee-type ${att.type}`}>

                                                                        </div>
                                                                        <div className="attendee-data">
                                                                            <p>{att.emailAddressName}</p>
                                                                            <BoxIconImageWithText imageUrl={`${config.publicUrl}/assets/images/icon-email-detail.png`} text={att.emailAddressAddress} />
                                                                        </div>
                                                                        <div className="attendee-status">

                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                        : <div className="box-no-attendee">
                                                            <p>{t("event.noAttendees")}</p>
                                                        </div>
                                                }
                                            </div>
                                        }
                                        {
                                            (isRenderAction && isOrganizer) &&
                                            <div className="box-action-organizer">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("event.resendPIN"),
                                                        color: "teal",
                                                        onClick: (e) => this.onConfirmResendEventPin(e, model)
                                                    })}
                                                />
                                                {
                                                    model.isShowCheckinButton &&
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("event.checkIn"),
                                                            color: "green",
                                                            onClick: (e) => this.onConfirmCheckIn(e, model)
                                                        })}
                                                    />
                                                }
                                                {
                                                    (model.isCheckin === false && model.isShowCancelButton) &&
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("common.delete"),
                                                            color: "red",
                                                            onClick: (e) => this.onConfirmDeleteEvent(e, model)
                                                        })}
                                                    />
                                                }
                                                {
                                                    model.isCheckin === false &&
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("common.edit"),
                                                            color: "blue",
                                                            onClick: this.onClickEdit
                                                        })}
                                                    />
                                                }
                                            </div>
                                        }
                                        {
                                            (isRenderAction && !isOrganizer) && (!!dateTimeSplit && !dateTimeSplit.isEnd) &&
                                            < div className="box-action-attendee">
                                                {
                                                    model.isShowCheckinButton &&
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("event.checkIn"),
                                                            color: "green",
                                                            onClick: (e) => this.onConfirmCheckIn(e, model)
                                                        })}
                                                    />
                                                }
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("event.accept"),
                                                        color: "green",
                                                        onClick: (e) => this.onConfirmAcceptEvent(e, model)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("event.tentative"),
                                                        color: "purple",
                                                        onClick: (e) => this.onConfirmTentativeEvent(e, model)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("event.decline"),
                                                        color: "red",
                                                        onClick: (e) => this.onConfirmDeclineEvent(e, model)
                                                    })}
                                                />
                                            </div>
                                        }
                                    </React.Fragment>
                                }

                            </div>
                        </Container>
                    </div>
                </LayoutWithHeaderFooter >
            </React.Fragment >
        )
    }
}

ViewViewEvent.defaultProps = {
    onGetEventInfo: () => {
        console.error("Please provide Get Event action.")
        return null
    },
    onCheckIn: () => {
        console.error("Please provide Check in action")
        return null
    },
    onCheckOut: () => {
        console.error("Please provide Check out action")
        return false
    },
    onResendEventPin: () => {
        console.error("Please provide Resend Pin action")
        return false
    },
    onDeleteEvent: () => {
        console.error("Please provide Delete action")
        return false
    },
    onAcceptEvent: () => {
        console.error("Please provide Accept Event action")
        return false
    },
    onTentativeEvent: () => {
        console.error("Please provide Tentative Event action")
        return false
    },
    onDeclineEvent: () => {
        console.error("Please provide Decline Event action")
        return false
    },
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    })
)


export default enhance(ViewViewEvent)