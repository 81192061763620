
import React from 'react'
import MyPage from '../../core/components/MyPage'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { Accordion, Form, Icon, Tab } from 'semantic-ui-react'
import ButtonModel from '../../core/model/ButtonModel'
import ModalModel from '../../core/model/ModalModel'
import ViewButton from '../../core/view/common/ViewButton'
import LabelInput from '../../core/view/common/LabelInput'
import moment from 'moment'
import BoxLoadingComponent from '../common/BoxLoadingComponent'
import UserGroupModel from '../../models/UserGroupModel'

// const tabType = {
//     ALL_USER: "ALL_USER",
//     GROUP_BY_DEPARTMENT: "GROUP_BY_DEPARTMENT"
// }

const rendersType = {
    LIST: "LIST",
    SELECT: "SELECT",
}

class ModalAttendee extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.renderType = rendersType.LIST
        this.state.attendees = Array.isArray(props.attendees) ? props.attendees.map(a => a) : []
        this.state.tabActive = 0
        this.state.isOpenAddNewEmail = false

        this.state.usersMailSystem = Array.isArray(props.usersMailSystem) ? props.usersMailSystem.map(u => u) : []
        this.state.userGroups = Array.isArray(props.userGroups) ? props.userGroups.map(u => u) : []
        this.state.userGroupsFilter = []

        this.state.searchText = ""
        this.state.searchTime = ""

        this.state.emails = ""

        this.state.activeGroup = -1
    }

    handleTabChange = (e, { activeIndex }) => {
        const { searchText, userGroups } = this.state
        this.setState({ tabActive: activeIndex }, () => {
            if (activeIndex === 0) {
                this.handleChangeSearch(null, { name: "", value: searchText })
            } else if (activeIndex === 1) { //1 = Group
                this.onFilterUserGroup(searchText, userGroups)
            }
        })
    }

    handleTabGroupChange = (e, titleProps) => {
        const { index } = titleProps
        const { activeGroup } = this.state
        const newIndex = activeGroup === index ? -1 : index

        this.setState({ activeGroup: newIndex })
    }

    onFilterUserGroup = (searchText, userGroups) => {
        if (Array.isArray(userGroups)) {
            let tempUserGroups = []
            userGroups.forEach(group => {
                let userFilter = []
                if (Array.isArray(group.allUser)) {
                    userFilter = group.allUser.filter(u => {
                        return u.email.toLowerCase().includes(searchText.toLowerCase())
                    })
                }
                let obj = {
                    ...group,
                    allUser: userFilter
                }
                tempUserGroups.push(new UserGroupModel(obj))
            })
            this.setState({
                userGroupsFilter: tempUserGroups
            })
        }
    }

    handleChangeAllUser = (e, { name, value, checked, fullName }, datas) => {
        let attendees = this.state.attendees
            , indexSelect = attendees.findIndex(u => u.email === value)
        if (name === "selectAll") {
            if (checked === true) {
                if (Array.isArray(datas)) {
                    attendees = datas.map(user => {
                        return {
                            fullName: user.fullName,
                            email: user.email
                        }
                    })
                }
            } else {
                attendees = []
            }
        } else if (name === "select") {
            if (checked === true) {
                if (indexSelect === -1) {
                    attendees.push({
                        fullName: fullName,
                        email: value
                    })
                }
            } else {
                if (indexSelect > -1) {
                    attendees.splice(indexSelect, 1)
                }
            }
        }
        this.setState({
            attendees: attendees
        })
    }

    handleChangeSearch = (e, { name, value }) => {
        const { tabActive, userGroups } = this.state
        let searchTime = moment().format("HH:mm:ss:SSS")
        this.setState({
            searchText: value,
            searchTime: searchTime
        }, () => {
            if (tabActive === 0) {
                this.onSearchUserMailSystem(value, searchTime)
            } else if (tabActive === 1) {
                this.onFilterUserGroup(value, userGroups)
            }
        })
    }

    onSearchUserMailSystem = (searchText, searchTime) => {
        this.setState({
            isLoading: true,
            usersMailSystem: [],
        }, async () => {
            let tempState = {
                isLoading: false,
                usersMailSystem: [],
            }
            let res = await this.props.onGetUserMailSystem(searchText)
            if ((searchTime === this.state.searchTime) && (!!res && res.result === true && Array.isArray(res.datas))) {
                tempState.usersMailSystem = res.datas
            }
            this.setState(tempState)
        })
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.attendees)
    }
    onClickSelectedAttendee = () => {

    }

    onClickAddNewEmail = () => {
        this.setState({
            isOpenAddNewEmail: true
        })
    }
    onSubmitAddNewEmail = () => {
        const { emails } = this.state
        let attendees = this.state.attendees
        if (!!emails) {
            let emailsSp = emails.split(",")
            emailsSp.forEach(mail => {
                let index = attendees.findIndex(a => a.email === mail)
                if (index === -1) {
                    let emailSp = mail.split("@")
                    attendees.push({
                        fullName: emailSp[0],
                        email: mail
                    })
                }
            });
        }
        this.setState({
            attendees,
            emails: "",
            isOpenAddNewEmail: false
        })
    }
    onCloseAddNewEmail = () => {
        this.setState({
            emails: "",
            isOpenAddNewEmail: false
        })
    }

    onClickViewSelect = () => {
        this.setState({
            renderType: rendersType.SELECT
        })
    }
    onBackToList = () => {
        this.setState({
            renderType: rendersType.LIST
        })
    }
    onRemoveAttendee = (index) => {
        let attendees = this.state.attendees
        if (index > -1) {
            attendees.splice(index, 1)
        }
        this.setState({
            attendees: attendees
        })
    }
    render() {
        try {
            const { t, textHeader, onClose } = this.props
            const { renderType, attendees, tabActive, isOpenAddNewEmail, usersMailSystem, searchText, isLoading,
                emails, userGroupsFilter, activeGroup } = this.state
            let panes = [
                {
                    menuItem: t("event.allUsers"),
                    render: null,
                },
                {
                    menuItem: t("event.groupByDepartment"),
                    render: null,
                }
            ]
            let tempTextHeader = textHeader
                , buttons = []
            if (renderType === rendersType.LIST) {
                buttons = [
                    new ButtonModel({
                        text: t("common.ok"),
                        color: "blue",
                        // disabled: attendees.length === 0,
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            } else if (renderType === rendersType.SELECT) {
                tempTextHeader = t("event.selectedAttendees")
                buttons = [
                    new ButtonModel({
                        text: t("common.back"),
                        color: "grey",
                        onClick: this.onBackToList
                    })
                ]
            }

            return (
                <React.Fragment>

                    {this.getModalContent(new ModalModel({
                        size: "tiny",
                        content: <div className="module modalAttendee">
                            <div className="modal-attendee-wrapper">
                                <div className="box-header">
                                    <div className="header-text">
                                        <h2>{tempTextHeader}</h2>
                                    </div>
                                </div>
                                <div className="box-select-attendee">
                                    {
                                        renderType === rendersType.LIST &&
                                        <div className="select-search">
                                            <div className="search-input">
                                                <Form autoComplete="off">
                                                    <Form.Input
                                                        fluid
                                                        placeholder={t("common.search")}
                                                        onChange={this.handleChangeSearch}
                                                        name="searchText"
                                                        value={searchText}
                                                    />
                                                </Form>
                                            </div>
                                            <div className="search-action">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t("event.addNewEmail")}`,
                                                        iconClass: "plus",
                                                        color: "green",
                                                        onClick: this.onClickAddNewEmail,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        panes.length > 1 &&
                                        <div className="select-header">
                                            <div className="header-tab">
                                                <Tab
                                                    panes={panes}
                                                    menu={{ secondary: true }}
                                                    activeIndex={tabActive}
                                                    onTabChange={this.handleTabChange}
                                                />
                                            </div>
                                            <div className="header-action">
                                            </div>
                                        </div>
                                    }
                                    <div className="select-list">
                                        <div className="list-wrapper">
                                            {
                                                isLoading ?
                                                    <BoxLoadingComponent inverted={true} />
                                                    : <React.Fragment>
                                                        <Form>
                                                            {
                                                                renderType === rendersType.LIST &&
                                                                <React.Fragment>
                                                                    {
                                                                        tabActive === 0 &&
                                                                        usersMailSystem.map((user, i) => {
                                                                            let indexSelect = attendees.findIndex(u => u.email === user.email)
                                                                                , isChecked = indexSelect > -1
                                                                            return <div key={i} className="item-select-attendee"
                                                                                onClick={(e => this.handleChangeAllUser(e, { name: "select", value: user.email, checked: !isChecked, fullName: user.fullName }))}
                                                                            >
                                                                                <div className="isa-checkbox">
                                                                                    <Form.Checkbox
                                                                                        checked={isChecked}
                                                                                    />
                                                                                </div>
                                                                                <div className="isa-text">
                                                                                    <p className="text-name">{user.fullName}</p>
                                                                                    <p className="text-email">{user.email}</p>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                    {
                                                                        tabActive === 1 &&
                                                                        <div className="box-group">
                                                                            <Accordion>
                                                                                {
                                                                                    userGroupsFilter.map((group, i) => {
                                                                                        if (group.allUser.length === 0) {
                                                                                            return null
                                                                                        }
                                                                                        return <div className="item-group" key={i}>
                                                                                            <Accordion.Title
                                                                                                active={activeGroup === i}
                                                                                                index={i}
                                                                                                onClick={this.handleTabGroupChange}
                                                                                            >
                                                                                                <Icon name='dropdown' />
                                                                                                {`${group.name} (${group.allUser.length})`}
                                                                                            </Accordion.Title>
                                                                                            <Accordion.Content active={activeGroup === i}>
                                                                                                {
                                                                                                    group.allUser.map((user, j) => {
                                                                                                        let indexSelect = attendees.findIndex(u => u.email === user.email)
                                                                                                            , isChecked = indexSelect > -1
                                                                                                        return <div key={j} className="item-select-attendee"
                                                                                                            onClick={(e => this.handleChangeAllUser(e, { name: "select", value: user.email, checked: !isChecked, fullName: user.fullName }))}
                                                                                                        >
                                                                                                            <div className="isa-checkbox">
                                                                                                                <Form.Checkbox
                                                                                                                    checked={isChecked}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="isa-text">
                                                                                                                <p className="text-name">{user.fullName}</p>
                                                                                                                <p className="text-email">{user.email}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    })
                                                                                                }
                                                                                            </Accordion.Content>
                                                                                        </div>
                                                                                    })
                                                                                }
                                                                            </Accordion>
                                                                        </div>
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                            {/* {
                                                                tabActive === 0 && renderType === rendersType.LIST &&
                                                                usersMailSystem.map((user, i) => {
                                                                    let indexSelect = attendees.findIndex(u => u.email === user.email)
                                                                        , isChecked = indexSelect > -1
                                                                    return <div key={i} className="item-select-attendee"
                                                                        onClick={(e => this.handleChangeAllUser(e, { name: "select", value: user.email, checked: !isChecked, fullName: user.fullName }))}
                                                                    >
                                                                        <div className="isa-checkbox">
                                                                            <Form.Checkbox
                                                                                checked={isChecked}
                                                                            />
                                                                        </div>
                                                                        <div className="isa-text">
                                                                            <p className="text-name">{user.fullName}</p>
                                                                            <p className="text-email">{user.email}</p>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            } */}
                                                            {
                                                                renderType === rendersType.SELECT &&
                                                                attendees.map((user, i) => {
                                                                    return <div key={i} className="item-select-attendee">
                                                                        <div className="isa-text">
                                                                            <p className="text-name">{user.fullName}</p>
                                                                            <p className="text-email">{user.email}</p>
                                                                        </div>
                                                                        <div className="isa-action">
                                                                            <ViewButton
                                                                                model={new ButtonModel({
                                                                                    text: t("common.remove"),
                                                                                    size: "mini",
                                                                                    color: "red",
                                                                                    onClick: () => this.onRemoveAttendee(i)
                                                                                })}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </Form>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    (buttons.length > 0 || renderType === rendersType.LIST) &&
                                    <div className="box-action">
                                        <div className="action-left">
                                            {
                                                renderType === rendersType.LIST &&
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t("event.selectedAttendees")}`,
                                                        iconClass: "angle down",
                                                        color: "teal",
                                                        disabled: attendees.length === 0,
                                                        // size: "small",
                                                        externalProps: {
                                                            labelPosition: "right"
                                                        },
                                                        onClick: this.onClickViewSelect,
                                                    })}
                                                />
                                            }
                                        </div>
                                        <div className="action-right">
                                            {
                                                buttons.map((model, i) => {
                                                    return <ViewButton model={model} key={i} />
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>,
                        // buttonAlign: "center",
                        externalProps: {
                            scrolling: false
                        },
                        // buttons: buttons
                    }))}
                    {
                        isOpenAddNewEmail &&
                        this.getModalContent(new ModalModel({
                            headerText: t("event.addNewEmail"),
                            size: "tiny",
                            content: <div className="module modalAttendee">
                                <div className="modal-attendee-add-new-email">
                                    <Form>
                                        <LabelInput text={t("common.email")} isRequire={true} />
                                        <Form.TextArea
                                            fluid
                                            name="emails"
                                            value={emails}
                                            onChange={this.handleChange}
                                        />
                                    </Form>
                                    <p>ex. mail_one@mail.com,mail_two@mail.com</p>
                                </div>
                            </div>,
                            // buttonAlign: "center",
                            externalProps: {
                                scrolling: false
                            },
                            buttons: [
                                new ButtonModel({
                                    text: t("common.add"),
                                    color: "green",
                                    disabled: !emails,
                                    onClick: this.onSubmitAddNewEmail
                                }),
                                new ButtonModel({
                                    text: t("common.close"),
                                    color: "grey",
                                    onClick: this.onCloseAddNewEmail
                                })
                            ]
                        }))
                    }
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


ModalAttendee.defaultProps = {
    textHeader: "",
    usersMailSystem: [],
    userGroups: [],
    attendees: [],
    onGetUserMailSystem: () => {
        console.error("Please provide Get User Mail System action.")
        return null
    },
    onGetUserGroupList: () => {
        console.error("Please provide Get User Group action.")
        return null
    },
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)


export default enhance(ModalAttendee)