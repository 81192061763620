
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import ViewResetPassword from '../../views/user/ViewResetPassword'
import UserManager from '../../business/UserManager'

class ResetPasswordController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.bUser = new UserManager(this)
	}

	onConfirmResetPassword = (key) => {
		return this.bUser.confirmResetPassword(key)
	}
	render() {
		const { isLoading } = this.state
		return (
			<React.Fragment>
				{super.render()}
				{isLoading && this.getLoadingPage()}
				<ViewResetPassword
					onConfirmResetPassword={this.onConfirmResetPassword}
				/>
			</React.Fragment>
		)
	}
}

const enhance = compose(
	translate,
	withRouter,
)


export default enhance(ResetPasswordController)